import React, { Component } from 'react';
import { Tag, Button } from 'antd';
import AddLinkModal from './AddLinkModal';
import _ from 'lodash';
import {
  FilePptOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  FileMarkdownOutlined,
  FileTextOutlined
} from '@ant-design/icons';

class LinkPannel extends Component {
  constructor(props) {
    super(props);

    this._initAttachList();
    this.state = {
      attachList: props.data.attachList,
      linkModal: {
        show: false
      },
      columnDefs: [
        {
          field: 'attachs',
          headerName: 'Attach',
          width: 100,
          cellRenderer: 'attachCellRender'
        }
      ],
      colorMap: {
        xlsx: 'green',
        xls: 'green',
        ppt: '#cd201f',
        pptx: '#cd201f',
        docx: '#3b5999',
        doc: '#3b5999',
        oneNotes: 'purple'
      },
      iconMap: {
        xlsx: <FileExcelOutlined />,
        xls: <FileExcelOutlined />,
        ppt: <FilePptOutlined />,
        pptx: <FilePptOutlined />,
        docx: <FileWordOutlined />,
        doc: <FileWordOutlined />,
        oneNotes: <FileMarkdownOutlined />
      }
    };
  }

  _initAttachList = () => {
    const props = this.props;
    if (!props.data.attachList) {
      props.data.attachList = [];
    }
  };

  _openTemplate = () => {
    window.open(
      'https://pamlhk-my.sharepoint.com/:p:/g/personal/chase_ding_pinpointfund_com/EQ_-Bbd2BJlOkcTFEKJaA0gBT_ZRnhFZdlE8oNNM9G85tw'
    );
  };

  _removeTags = item => {
    const { attachList } = this.state;
    const index = _.findIndex(attachList, r => r.url === item.url);
    attachList.splice(index, 1);
    this.setState({
      attachList
    });
  };

  _getColor = type => {
    const { colorMap } = this.state;
    return colorMap[type] ? colorMap[type] : 'gray';
  };

  _getIcon = type => {
    const { iconMap } = this.state;
    return iconMap[type] ? iconMap[type] : <FileTextOutlined />;
  };

  _onLinkClick = r => {
    window.open(r.url);
  };

  _createUploadPannel = () => {
    const { attachList } = this.state;
    return this._createUploadList(attachList);
  };

  _createUploadList = attachList => {
    const { readOnly } = this.props;
    return (
      <div
        style={{
          maxHeight: '200px',
          minHeight: '40px',
          overflow: 'auto',
          float: 'left',
          width: '100%',
          marginTop: '8px',
          border: '1px dashed #0000FF'
        }}
      >
        {this._createAddLinkBtn()}
        {attachList.map(r => {
          return (
            <Tag
              key={r.url}
              icon={this._getIcon(r.type)}
              closable={readOnly ? false : true}
              style={{ marginTop: '5px', marginLeft: '5px' }}
              onClose={() => this._removeTags(r)}
              color={this._getColor(r.type)}
              onClick={() => this._onLinkClick(r)}
            >
              <a href="#">
                {r.name.length > 120 ? `${r.name.substr(0, 120)}...` : r.name}
                {_.isEmpty(r.type) ? '' : `.${r.type}`}
              </a>
            </Tag>
          );
        })}
      </div>
    );
  };

  _addLink = () => {
    this._openLinkModal();
  };

  _openLinkModal = () => {
    this.setState({
      linkModal: {
        show: true
      }
    });
  };

  _submitLink = data => {
    const { attachList } = this.state;
    attachList.push(data);
    this.setState(
      {
        attachList
      },
      this._closeLinkModal
    );
  };

  _closeLinkModal = () => {
    this.setState({
      linkModal: {
        show: false
      }
    });
  };

  _createAddLinkModal = () => {
    const { linkModal } = this.state;
    return (
      linkModal.show && (
        <AddLinkModal
          closeModal={this._closeLinkModal}
          submitData={this._submitLink}
        />
      )
    );
  };

  _createAddLinkBtn = () => {
    const { readOnly } = this.props;
    return (
      !readOnly && (
        <div
          style={{
            textAlign: 'right',
            float: 'right',
            width: '220px'
          }}
        >
          <Button
            size="small"
            type="primary"
            style={{ marginRight: '3px' }}
            onClick={this._addLink}
          >
            Add
          </Button>
          <Button size="small" type="primary" onClick={this._openTemplate}>
            Stock Pitch Template
          </Button>
        </div>
      )
    );
  };

  attachCellRender = data => {
    return <>{this._createUploadList(data)}</>;
  };

  render() {
    return (
      <div width={'100%'} style={{ border: '1px soild white' }}>
        {this._createUploadPannel()}
        {this._createAddLinkModal()}
      </div>
    );
  }
}

export default LinkPannel;
