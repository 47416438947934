import React, { Component } from 'react';
import { Tabs } from 'antd';
import OperationtMode from 'common/utils/OperationtMode';
import 'moment/locale/zh-cn';
import moment from 'moment';
import { DatePicker, Row, Col, Button } from 'antd';
import BrokerDialogGrid from './BrokerDialogGrid';
import { Header, Modal, Loader, Message } from 'semantic-ui-react';
import _ from 'lodash';
import client from './../api/client';
import BrokerDialogMeetingListGrid from './BrokerDialogMeetingListGrid';
const { MonthPicker } = DatePicker;
const TabPane = Tabs.TabPane;
class BrokerSaveDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDayOffset: 7,
      endDayOffset: 7,
      selectedData: {}
    };
  }

  votingMonthChange = (date, dataString) => {
    this.props.changeBrokerScoreControlModalInput({
      name: 'votingMonth',
      value: date
    });
    this.props.loadBrokerScoreListByMonth(
      this.props.pmName,
      date.format('YYYY-MM')
    );
  };

  componentDidMount() {
    this._initData();
  }

  _initData = () => {
    client
      .loadBrokerCanScoreDate()
      .then(resp => {
        const data = resp.data;
        if (data) {
          this.setState({
            startDayOffset: data.start,
            endDayOffset: data.end
          });
        }
      })
      .catch(err => {});
  };

  saveBrokerDetail = () => {
    const {
      brokerScoreListForDetail,
      brokerScoreControlModal,
      brokerQueryParams
    } = this.props;
    const detailArr = [];
    for (var detail in brokerScoreListForDetail) {
      this.setState({
        errorMsgMap: {}
      });
      let flag = false;
      if (detail && detail === 'CUSTOM') {
        //check brokerName not null
        flag = true;
      } else {
        flag = this.checkSaveBrokerList(brokerScoreListForDetail[detail]);
      }
      if (!flag) {
        return;
      }
      detailArr.push(...brokerScoreListForDetail[detail]);
    }
    detailArr.forEach(item => {
      const detailList = item.detailList;
      detailList.forEach(ele => {
        ele.score = item[ele.code] ? item[ele.code] : 0;
      });
    });
    const params = {
      pmName: this.props.pmName,
      votingMonth: brokerScoreControlModal['votingMonth'].format('YYYY-MM'),
      correspondenceVOList: brokerScoreControlModal['weightMap'],
      scoreList: detailArr
    };
    this.props.saveBrokerScore(params, brokerQueryParams);
  };

  checkSaveBrokerList = list => {
    var checkFlag = true;
    if (list && list.length > 2) {
      const { weightMap } = this.props.brokerScoreControlModal;
      // eslint-disable-next-line array-callback-return
      for (var i = 0; i < weightMap.length; i++) {
        checkFlag = this.checkFiledByFiledName(
          weightMap[i]['code'],
          list,
          weightMap[i]['name']
        );
        if (!checkFlag) {
          return checkFlag;
        }
      }
    }
    return checkFlag;
  };

  checkFiledByFiledName = (name, data, weightName) => {
    const msgList = [];
    var flag = true;
    var numberValue = true;
    var currentTab;
    const sortList = _.orderBy(
      data,
      ['market', 'brokerType', name],
      ['desc', 'desc', 'desc']
    );
    for (var i = 0; i < sortList.length; ) {
      var current = sortList[i];
      if (current.market === 'CN' || current.market === 'HK') {
        currentTab = 'HK/CN';
      } else {
        currentTab = current.market;
      }
      ++i;
      if (current[name] === 0) {
        continue;
      }

      if (!this.checkNumberValue(current[name])) {
        numberValue = false;
      }

      var count = 1;
      while (
        i < sortList.length &&
        sortList[i][name] === current[name] &&
        sortList[i].market === current.market &&
        sortList[i].brokerType === current.brokerType
      ) {
        ++i;
        ++count;
      }
      var key = current.market + current.brokerType + name + current[name];
      if (count > 4) {
        var msg =
          'Section (' +
          current.market +
          ', ' +
          current.brokerType +
          ', ' +
          weightName +
          '),more than four brokers score=' +
          current[name];
        msgList.push({ key: key, value: msg, op: 'Add' });
        flag = false;
      } else {
        msgList.push({ key: key, value: null, op: 'Remove' });
      }
    }
    var tabKey = currentTab + name;
    if (!numberValue) {
      msgList.push({
        key: tabKey,
        value: '[Tab ' + currentTab + ']:score must be a number 0-5',
        op: 'Add'
      });
      flag = false;
    } else {
      msgList.push({ key: tabKey, value: null, op: 'Remove' });
    }
    this.addOrRemoveErrorMsg(msgList);
    return flag;
  };

  checkNumberValue = value => {
    const reg = new RegExp('^[0-5]$');
    var flag = true;
    if (!reg.test(value)) {
      flag = false;
    }
    return flag;
  };

  addOrRemoveErrorMsg = msgList => {
    const { errorMsgMap } = this.props.brokerScoreControlModal;
    var refreshFlag = false;
    msgList.forEach(element => {
      const op = element['op'];
      const key = element['key'];
      const msg = element['value'];
      if (op === 'Add') {
        errorMsgMap[key] = msg;
        refreshFlag = true;
      } else if (op === 'Remove') {
        if (errorMsgMap[key]) {
          delete errorMsgMap[key];
          refreshFlag = true;
        }
      }
    });

    if (refreshFlag) {
      this.props.changeBrokerScoreControlModalInput({
        name: 'errorMsgMap',
        value: errorMsgMap
      });
    }
  };

  showSubmitButton = () => {
    const { startDayOffset, endDayOffset } = this.state;
    const { votingMonth } = this.props.brokerScoreControlModal;
    if (!votingMonth) {
      return false;
    }
    const currentTime = moment().format('YYYY-MM-DD');
    const startMonthEndDay = votingMonth.clone().endOf('month');
    const currentMonthLast7Day = startMonthEndDay
      .clone()
      .subtract(startDayOffset, 'days')
      .format('YYYY-MM-DD');
    const nextMonthFirst7Day = startMonthEndDay
      .clone()
      .add(endDayOffset, 'days')
      .format('YYYY-MM-DD');
    const currentMonth = startMonthEndDay.format('YYYY-MM');
    const votingMonthStr = votingMonth.format('YYYY-MM');
    this.votingMonthStr = votingMonthStr;
    this.currentMonthLast7Day = currentMonthLast7Day;
    this.nextMonthFirst7Day = nextMonthFirst7Day;
    if (
      currentTime >= currentMonthLast7Day &&
      currentTime <= nextMonthFirst7Day &&
      currentMonth === votingMonthStr
    ) {
      return true;
    }
    return false;
  };

  dataIsEmpty = dataArr => {
    if (dataArr && dataArr.length > 0) {
      return false;
    }
    return true;
  };

  _initDetailData = brokerScoreListForDetail => {
    _.map(brokerScoreListForDetail, (value, key) => {
      if (value) {
        value.forEach(item => {
          const detailList = item.detailList;
          detailList.forEach(ele => {
            const code = ele['code'];
            item[code] = ele['score'];
          });
        });
      }
    });
  };
  selectedDataChange = data => {
    this.setState({
      selectedData: data
    });
  };
  render() {
    const {
      isOpened,
      mode,
      weightMapLoaded,
      votingMonth,
      errorMsgMap
    } = this.props.brokerScoreControlModal;
    let errorMsgs = [];
    if (!_.isEmpty(errorMsgMap)) {
      errorMsgs = Object.values(errorMsgMap);
    }

    const { isLoaded, brokerScoreListForDetail } = this.props;
    this.data = brokerScoreListForDetail;
    const isNewMode = mode === OperationtMode.NEW;
    const isUpdateMode = mode === OperationtMode.UPDATE;

    let modalHeaderContent = 'Broker';
    if (isNewMode) {
      modalHeaderContent = 'Broker Score';
    } else if (isUpdateMode) {
      modalHeaderContent = 'Edit Broker';
    }

    return (
      <div>
        <style>
          {`
            .ui.modals {
              display: flex !important;
            }
            .ui.modal {
              margin-top: 0px !important;
            }

            #brokerDialogTab .ant-calendar-picker {
              width: 100%;
            }

            #brokerDialogTab .ant-input {
              height: 100%;
            }

            #brokerDialogTab .ant-picker {
              margin-left:10px;
            }

            #brokerDialogTab .buttonDiv {
              display: flex;
              justify-content: flex-end;
            }

            #brokerDialogTab .ant-tabs-bar {
              margin: 0 0 8px 0;
              color: black;
            }

            #brokerDialogTab .ant-tabs-nav .ant-tabs-tab-active {
              /* background-color: #FF9E28; */
              color: black;
            }
            
            #brokerDetailDialog {
              width : 1100px;
            }
          `}
        </style>
        <Modal id="brokerDetailDialog" size="large" open={isOpened}>
          <Header content={modalHeaderContent} />
          <Modal.Content>
            <Row>
              <Col span={18}>
                <label>Voting Month:</label>
                <MonthPicker
                  value={votingMonth}
                  format={'YYYY-MM'}
                  allowClear={false}
                  onChange={this.votingMonthChange}
                />
              </Col>
              <Col span={6}>
                <label>PM Name : </label>
                <span>{this.props.pmName}</span>
              </Col>
            </Row>
            {!isLoaded || !weightMapLoaded ? (
              <Loader active inverted content="Loading..." />
            ) : (
              <div id="brokerDialogTab" className="researchDashboardWrapper">
                <Tabs defaultActiveKey="1" tabPosition={'top'}>
                  {this.dataIsEmpty(this.data['HK/CN']) ? (
                    ''
                  ) : (
                    <TabPane tab="HK/CN" key="1">
                      <BrokerDialogGrid
                        {...this.props}
                        data={this.data['HK/CN']}
                        checkFiledByFiledName={this.checkFiledByFiledName}
                        selectedDataChange={this.selectedDataChange}
                      />
                    </TabPane>
                  )}
                  {this.dataIsEmpty(this.data['IN']) ? (
                    ''
                  ) : (
                    <TabPane tab="India" key="2">
                      <BrokerDialogGrid
                        {...this.props}
                        data={this.data['IN']}
                        checkFiledByFiledName={this.checkFiledByFiledName}
                        selectedDataChange={this.selectedDataChange}
                      />
                    </TabPane>
                  )}
                  {this.dataIsEmpty(this.data['JP']) ? (
                    ''
                  ) : (
                    <TabPane tab="Japan" key="3">
                      <BrokerDialogGrid
                        {...this.props}
                        data={this.data['JP']}
                        checkFiledByFiledName={this.checkFiledByFiledName}
                        selectedDataChange={this.selectedDataChange}
                      />
                    </TabPane>
                  )}
                  {this.dataIsEmpty(this.data['AS']) ? (
                    ''
                  ) : (
                    <TabPane tab="Asean" key="5">
                      <BrokerDialogGrid
                        {...this.props}
                        data={this.data['AS']}
                        checkFiledByFiledName={this.checkFiledByFiledName}
                        selectedDataChange={this.selectedDataChange}
                      />
                    </TabPane>
                  )}
                  <TabPane tab="Candidate Brokers" key="4">
                    <BrokerDialogGrid
                      {...this.props}
                      data={this.data['CUSTOM']}
                      selectedDataChange={this.selectedDataChange}
                    />
                  </TabPane>
                </Tabs>
                {errorMsgs.length > 0 ? (
                  <Message
                    error
                    header="Errors"
                    visible={errorMsgs.length > 0}
                    list={errorMsgs}
                  />
                ) : (
                  <div />
                )}
                <div>
                  <p style={{ marginBottom: '5px' }}>
                    <b>Note: </b>
                  </p>
                  <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                    <b>
                      {'1. Within same section (Market+BrokerType),' +
                        ' at most four brokers have same score.'}{' '}
                    </b>
                  </p>
                  <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                    <b>{'2. 5 is the highest score, 1 is the lowest.'}</b>
                  </p>
                  <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                    <b>
                      {
                        '3. Broker Type: P is primary broker, E is execution broker, C is candidate broker.'
                      }
                    </b>
                  </p>
                  <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                    <b>
                      {'4. ' +
                        this.votingMonthStr +
                        ' Submit date range: Open date: ' +
                        this.currentMonthLast7Day +
                        ', Close date: ' +
                        this.nextMonthFirst7Day +
                        '.'}
                    </b>
                  </p>
                  <p style={{ marginTop: '5px' }}>
                    <b>
                      {
                        '5.Candidate Broker: If you need add a new candidate broker,pls contact mo.li'
                      }
                    </b>
                  </p>
                </div>
                <Tabs tabPosition="top" key="LeftTabs">
                  <Tabs.TabPane tab="MeetingRecord" key="MeetingRecord">
                    {
                      <div style={{ height: '300px' }}>
                        <style>
                          {`
                    .ant-tabs-nav .ant-tabs-tab {
                      color: black;
                      font-weight: 700;
                      font-style: italic;
                      padding: 2px 10px;
                    }

                    .ant-tabs-nav .ant-tabs-tab-active {
                      color: #1890ff;
                      font-weight: 700;
                      font-style: italic;
                      padding: 2px 10px;
                    }
                  `}
                        </style>
                        <BrokerDialogMeetingListGrid
                          {...this.props}
                          // analystNameList={analystNameList}
                          // analystSectorList={analystSectorList}
                          selectedData={this.state.selectedData}
                        />
                      </div>
                    }
                  </Tabs.TabPane>
                </Tabs>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            {this.showSubmitButton() ? (
              <Button onClick={this.saveBrokerDetail} disabled={!isLoaded}>
                Submit
              </Button>
            ) : (
              <div></div>
            )}
            <Button onClick={this.props.resetBrokerDetailControlModal}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default BrokerSaveDialog;
