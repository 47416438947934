import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import SplitPane from 'react-split-pane';
import TagComponent from '../../../../common/ui/renderers/agGrid/TagComponent';
import RatingBarShowOnlyComponent from '../../../../common/ui/renderers/agGrid/RatingBarShowOnlyComponent';
import {
  companyScoreMyGridColumns,
  companyScoreDetailGridColumns,
  companyScoreCompanyDetailGridColumns,
  companyScoreOthersGridColumns,
  companyScoreAllGridColumns
} from '../GridColumnMap';
import { Button, Tabs, Modal, Spin } from 'antd';
import client from '../../api/client';
import CompanyScoreDetailDrawer from './drawer/CompanyScoreDetailDrawer';
import IndustryScoreDetailDrawer from './drawer/IndustryScoreDetailDrawer';
import { QuestionCircleOutlined } from '@ant-design/icons';
import config from 'common/config';
import StateSynchronizer from 'common/utils/StateSynchronizer';
import CompareFieldTooltipComponent from 'common/ui/renderers/agGrid/CompareFieldTooltipComponent';
import { getValue, delKey } from 'common/utils/IndexedDBUtils';
import { Loader } from 'semantic-ui-react';

const SOCRE_MY = 'Score_My';
const confirm = Modal.confirm;
class CompanyScoreGrid extends Component {
  constructor(props) {
    super(props);
    let that = this;
    this.state = {
      isLoaded: false,
      isLoadedOther: false,
      isLoadedAllNew: false,
      isDetailLoaded: false,
      activeTab: SOCRE_MY,
      submitStatus: 'READY',
      columnDefs: companyScoreMyGridColumns,
      otherGridColumnDefs: companyScoreOthersGridColumns,
      allGridColumnDefs: companyScoreAllGridColumns,
      companyScoreDetailColumnDefs: companyScoreDetailGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      context: {
        gridComponent: this
      },
      detailFrameworkComponents: {
        tagComponent: TagComponent,
        ratingBarComponent: RatingBarShowOnlyComponent
      },
      groupDefaultExpanded: -1,
      rowGroupPanelShow: 'false',
      autoGroupListColumnDef: {
        field: 'sector',
        headerName: 'Sector',
        cellClass: 'keyword',
        valueFormatter: params => {
          if (params.data) {
            return params.data.version;
          }
        }
      },
      autoOthersGroupListColumnDef: {
        field: 'publishDate',
        headerName: 'Publish Date',
        cellClass: 'keyword',
        valueFormatter: params => {
          if (params.data) {
            return `${params.data.sector}(${params.data.createBy})`;
          }
        }
      },
      autoAllGroupListColumnDef: {
        field: 'createBy',
        headerName: 'User',
        cellClass: 'keyword',
        valueFormatter: params => {
          if (params.data) {
            return `${params.data.sector}`;
          }
        }
      },
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: companyScoreCompanyDetailGridColumns,
          defaultColDef: {
            enableCellChangeFlash: true,
            flex: 1,
            sortable: true
          },
          enableSorting: true,
          context: { componentParent: that },
          frameworkComponents: {
            tagComponent: TagComponent,
            ratingBarComponent: RatingBarShowOnlyComponent,
            compareFieldTooltipComponent: CompareFieldTooltipComponent
          },
          getRowId: function(params) {
            return params.data.id;
          },
          onRowClicked: function(item) {
            that._onDetailCompanyGridRowClickedHandle(item);
          },
          onGridReady: function(params) {
            params.api.sizeColumnsToFit();
          },
          getContextMenuItems: function(params) {
            return that._createDetailMenuOp(params);
          }
        },
        getDetailRowData: function(params) {
          let data = params.data.data ? params.data.data : [];
          data = data.map(r => {
            const { companyBusinessList } = r;
            let firstData = _.isEmpty(companyBusinessList)
              ? {}
              : companyBusinessList[0];
            return {
              ...r,
              ...firstData
            };
          });
          data = data.filter(r => !r.isNew);
          data = _.orderBy(data, ['score'], ['desc']);
          params.successCallback(data);
        }
      },
      getRowHeight: function(params) {
        if (
          params.node &&
          params.node.detail &&
          !_.isEmpty(params.data.__children)
        ) {
          var offset = 80;
          let length = params.data.__children.length;
          var allDetailRowHeight = length * 28;
          return allDetailRowHeight + offset;
        } else {
          return 28;
        }
      },
      selectedData: { id: null, data: [] },
      myDataList: [],
      otherDataList: [],
      allDataList: [],
      industryScoreDrawer: {
        show: false,
        data: {}
      },
      companyScoreDrawer: {
        show: false,
        data: {}
      },
      detailData: [],
      pushModal: {
        show: false,
        data: {}
      }
    };
  }

  componentDidMount() {
    this._init();
  }

  _loadMyDataList = () => {
    const { activeTab } = this.state;

    if (activeTab === SOCRE_MY) {
      this.setState({
        isLoaded: true
      });
      Promise.all([client.getCompanyScoreDetail({}), client.getMyDataList({})])
        .then(([newestDataResp, myDataResp]) => {
          this.setState({
            isLoaded: false,
            selectedData: {
              id: null,
              data: newestDataResp.data ? newestDataResp.data : []
            },
            myDataList: myDataResp.data ? myDataResp.data : []
          });
        })
        .catch(err => {
          this.setState({
            isLoaded: false
          });
          console.log(err);
        });
    } else if (activeTab === 'Socre_Other') {
      this._loadOthersDataList();
    } else if (activeTab === 'Socre_All') {
      this._loadAllNewestDataList();
    }
  };

  _loadOthersDataList = () => {
    this.setState({
      isLoadedOther: true
    });
    client
      .getOthersDataList({})
      .then(resp => {
        this.setState({
          isLoadedOther: false,
          otherDataList: resp.data ? resp.data : []
        });
      })
      .catch(err => {
        this.setState({
          isLoadedOther: false
        });
        console.log(err);
      });
  };

  _loadAllNewestDataList = () => {
    this.setState({
      isLoadedAllNew: true
    });
    client
      .getAllNewestCompanyScoreDataList({})
      .then(resp => {
        this.setState({
          isLoadedAllNew: false,
          allDataList: resp.data ? resp.data : []
        });
      })
      .catch(err => {
        this.setState({
          isLoadedAllNew: false
        });
        console.log(err);
      });
  };

  _init = () => {
    this.setState({
      isLoaded: true
    });
    Promise.all([client.getCompanyScoreDetail({}), client.getMyDataList({})])
      .then(([newestDataResp, myDataResp]) => {
        this.setState(
          {
            isLoaded: false,
            selectedData: {
              id: null,
              data: newestDataResp.data ? newestDataResp.data : []
            },
            myDataList: myDataResp.data ? myDataResp.data : []
          },
          () => {
            this._loadOthersDataList();
            this._loadAllNewestDataList();
          }
        );
      })
      .catch(err => {
        this.setState({
          isLoaded: false
        });
        console.log(err);
      });
  };

  getCompanyScoreDetail = id => {
    const { selectedData } = this.state;
    const queryId = id ? id : selectedData.id;
    client
      .getCompanyScoreDetail({ id: queryId })
      .then(resp => {
        this.setState({
          selectedData: { id, data: resp.data }
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  _createpinnedTopRowData = () => {
    return [{ id: -1, secotr: 'Newest' }];
  };

  _onMyListGridReady = params => {
    this.myListGridApi = params.api;
    this.myListColumnApi = params.columnApi;

    this.myListGridApi.sizeColumnsToFit();
  };

  _onOtherListGridReady = params => {
    this.otherListGridApi = params.api;
    this.otherListColumnApi = params.columnApi;

    const COLUMNS_KEY = 'company-score-others-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.otherGridColumnDefs,
      COLUMNS_KEY
    );
    this.otherListGridApi.sizeColumnsToFit();
  };

  _onAllListGridReady = params => {
    this.allListGridApi = params.api;
    this.allListColumnApi = params.columnApi;

    // const COLUMNS_KEY = 'company-score-others-col-state';
    // StateSynchronizer.syncGrid(
    //   params,
    //   this.state.otherGridColumnDefs,
    //   COLUMNS_KEY
    // );
    this.allListGridApi.sizeColumnsToFit();
  };

  onRowClicked = data => {
    if (!data.data) return;
    if (data.data.id) {
      this.setState({
        selectedData: {
          id: data.data.id,
          data: [data.data]
        }
      });
    } else {
      this.getCompanyScoreDetail(null);
    }
  };

  _createMyListGrid = () => {
    const { myDataList } = this.state;
    const { className = 'grid-wrapper' } = this.props;
    const data = _.isEmpty(myDataList)
      ? []
      : _.orderBy(myDataList, ['version'], ['desc']);
    return (
      <div className={className}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '95%' }}
        >
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={data}
            suppressAggFuncInHeader={true}
            animateRows={true}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            autoGroupColumnDef={this.state.autoGroupListColumnDef}
            pivotPanelShow={false}
            rowSelection="single"
            pinnedTopRowData={[{ id: null, sector: 'Newest Data' }]}
            context={this.state.context}
            sideBar={this.state.sideBar}
            onGridReady={this._onMyListGridReady}
            onRowClicked={this.onRowClicked}
          />
        </div>
      </div>
    );
  };

  _createOtherListGrid = () => {
    const { otherDataList, isLoadedOther } = this.state;
    const { className = 'grid-wrapper' } = this.props;
    const data = _.isEmpty(otherDataList)
      ? []
      : _.orderBy(otherDataList, ['publishDate'], ['desc']);
    return isLoadedOther ? (
      <Loader active inverted content="Loading..." />
    ) : (
      <div className={className}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '95%' }}
        >
          <AgGridReact
            // properties
            columnDefs={this.state.otherGridColumnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={data}
            suppressAggFuncInHeader={true}
            animateRows={true}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={false}
            rowSelection="single"
            context={this.state.context}
            sideBar={this.state.sideBar}
            onGridReady={this._onOtherListGridReady}
            onRowClicked={this.onRowClicked}
            autoGroupColumnDef={this.state.autoOthersGroupListColumnDef}
          />
        </div>
      </div>
    );
  };

  _createAllListGrid = () => {
    const { allDataList, isLoadedAllNew } = this.state;
    const { className = 'grid-wrapper' } = this.props;
    const data = _.isEmpty(allDataList)
      ? []
      : _.orderBy(allDataList, ['createBy'], ['asc']);
    return isLoadedAllNew ? (
      <Loader active inverted content="Loading..." />
    ) : (
      <div className={className}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '95%' }}
        >
          <AgGridReact
            // properties
            columnDefs={this.state.allGridColumnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={data}
            suppressAggFuncInHeader={true}
            animateRows={true}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={false}
            rowSelection="single"
            context={this.state.context}
            sideBar={this.state.sideBar}
            onGridReady={this._onAllListGridReady}
            onRowClicked={this.onRowClicked}
            autoGroupColumnDef={this.state.autoAllGroupListColumnDef}
          />
        </div>
      </div>
    );
  };

  onTabChange = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  _createLeftTabs = () => {
    const { user } = this.props;
    return (
      <Tabs
        tabPosition="top"
        defaultActiveKey={SOCRE_MY}
        key="SocreTabs"
        onChange={this.onTabChange}
      >
        <Tabs.TabPane tab="My" key={SOCRE_MY}>
          {this._createMyListGrid()}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Other" key="Socre_Other">
          {this._createOtherListGrid()}
        </Tabs.TabPane>
        {user && (user.role === 'FundMngr' || user.role === 'Admin') && (
          <Tabs.TabPane tab="All" key="Socre_All">
            {this._createAllListGrid()}
          </Tabs.TabPane>
        )}
      </Tabs>
    );
  };

  onRightGridReady = params => {
    this.rightGridApi = params.api;
    this.rightGridColumnApi = params.columnApi;

    this.rightGridApi.sizeColumnsToFit();
  };

  _onDetailRowClick = params => {
    const { selectedData, activeTab } = this.state;
    const data = params.data;
    if (activeTab === SOCRE_MY && selectedData.id === null) {
      this._updateSector(data);
    } else {
      this._detailSector(data);
    }
  };

  _onDetailCompanyGridRowClickedHandle = params => {
    const { selectedData, activeTab } = this.state;
    const data = params.data;
    let sectorData = {};
    if (selectedData && selectedData.data) {
      const sectorList = selectedData.data.filter(r => r.id === data.sectorId);
      if (!_.isEmpty(sectorList)) sectorData = sectorList[0];
    }
    if (activeTab === SOCRE_MY && selectedData.id === null) {
      this._updateCompany(data, sectorData);
    } else {
      this._detailCompany(data, sectorData);
    }
  };

  _getRowId = params => {
    return params.id;
  };

  _expandFirst = () => {
    const { selectedData } = this.state;
    const nodes = this.rightGridApi.getRenderedNodes();
    if (this.rightGridApi && !_.isEmpty(nodes) && selectedData.id) {
      nodes[0].setExpanded(true);
    } else if (this.rightGridApi && !_.isEmpty(nodes) && !selectedData.id) {
      nodes[0].setExpanded(false);
    }
  };

  _createRightGrid = () => {
    const { selectedData } = this.state;
    const { className = 'grid-wrapper' } = this.props;
    const result = selectedData ? selectedData.data : [];
    let columns = this.state.companyScoreDetailColumnDefs;
    return (
      <div className={className}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '100%' }}
        >
          <AgGridReact
            // properties
            columnDefs={columns}
            defaultColDef={this.state.defaultColDef}
            rowData={result}
            suppressAggFuncInHeader={true}
            animateRows={true}
            deltaRowDataMode={true}
            masterDetail={true}
            detailCellRendererParams={this.state.detailCellRendererParams}
            pivotPanelShow={false}
            frameworkComponents={this.state.detailFrameworkComponents}
            getContextMenuItems={this._createMenuOp}
            rowSelection="single"
            onGridReady={this.onRightGridReady}
            getRowNodeId={this._getRowId}
            onRowClicked={this._onDetailRowClick}
            onRowDataUpdated={this._expandFirst}
          />
        </div>
      </div>
    );
  };

  _addSector = () => {
    this.setState({
      industryScoreDrawer: {
        show: true,
        data: {
          sector: 'Sector'
        },
        mode: 'ADD'
      }
    });
  };

  _updateSector = data => {
    this.setState({
      industryScoreDrawer: {
        show: true,
        data,
        mode: 'UPDATE'
      }
    });
  };

  _detailSector = data => {
    this.setState({
      industryScoreDrawer: {
        show: true,
        data,
        mode: 'DETAIL'
      }
    });
  };

  _addCompany = data => {
    this.setState({
      companyScoreDrawer: {
        show: true,
        data: { sectorId: data.id, companyBusinessList: [{}] },
        mode: 'ADD'
      }
    });
  };

  _updateCompany = (data, sectorData) => {
    const { selectedData } = this.state;
    if (selectedData && selectedData.data) {
    }
    this.setState({
      companyScoreDrawer: {
        show: true,
        sectorData,
        data,
        mode: 'UPDATE'
      }
    });
  };

  _detailCompany = (data, sectorData) => {
    this.setState({
      companyScoreDrawer: {
        show: true,
        sectorData,
        data,
        mode: 'DETAIL'
      }
    });
  };

  _createMenuOp = params => {
    const { activeTab, selectedData } = this.state;
    const contextMenu = [];
    if (activeTab === 'Score_My' && selectedData.id === null) {
      contextMenu.push({
        name: 'Add Sector',
        action: () => {
          this._addSector();
        }
      });
      if (params.node && params.node.data) {
        params.node.data.sector &&
          contextMenu.push({
            name: `[${params.node.data.sector}]Add Company`,
            action: () => {
              this._addCompany(params.node.data);
            }
          });

        params.node.data.status === 'NEW' &&
          contextMenu.push({
            name: `[${params.node.data.sector}]Publish`,
            action: () => {
              this._openPublishModal(params.node.data);
            }
          });
      }
    }
    return contextMenu;
  };

  _createDetailMenuOp = (params, a, b) => {
    const { activeTab, selectedData } = this.state;
    const contextMenu = [];
    if (activeTab === SOCRE_MY && selectedData.id === null) {
      contextMenu.push({
        name: 'Remove Company',
        action: () => {
          this._removeCompany(params);
        }
      });
    }
    return contextMenu;
  };

  _removeCompany = params => {
    if (params && params.node.data) {
      const data = params.node.data;
      const callbackFunc = () => {
        client
          .deleteCompany(data)
          .then(resp => {
            this._loadMyDataList();
          })
          .catch(err => console.log(err));
      };
      confirm({
        title: ``,
        width: '500px',
        content: `Confirm remove Company: ${data.ticker}?`,
        onOk: callbackFunc
      });
    }
  };

  _onCloseSectorDrawer = data => {
    this.setState(
      {
        industryScoreDrawer: {
          show: false,
          data: {}
        }
      },
      () => {
        this._afterCloseSectorScoreDrawer(data);
      }
    );
  };

  _afterCloseSectorScoreDrawer = data => {
    const { selectedData } = this.state;
    const { saveData } = data;
    if (saveData) {
      saveData.status = 'NEW';
      const index = _.findIndex(selectedData.data, r => r.id === saveData.id);
      if (index === -1) {
        selectedData.data.unshift(saveData);
      } else {
        selectedData.data[index] = saveData;
        //this.rightGridApi.applyTransaction({update: [saveData]});
      }
      // this.setState({
      //     selectedData: {
      //         ...selectedData,
      //         data: selectedData.data
      //     }
      // })
      this.rightGridApi.setRowData(selectedData.data);
    }
  };

  _onCloseCompanyScoreDrawer = data => {
    this.setState(
      {
        companyScoreDrawer: {
          show: false,
          data: {}
        }
      },
      () => {
        _.delay(() => {
          this._afterCloseCompanyScoreDrawer(data);
        }, 100);
      }
    );
  };

  _afterCloseCompanyScoreDrawer = data => {
    const { selectedData } = this.state;
    const { saveData } = data;
    if (saveData) {
      const index = _.findIndex(
        selectedData.data,
        r => r.id === saveData.sectorId
      );
      const currentData = { ...selectedData.data[index] };
      selectedData.data[index] = currentData;
      const companyList = currentData.data ? currentData.data : [];
      let companyData = currentData.data;
      const companyIndex = _.findIndex(companyData, r => r.id === saveData.id);
      if (companyIndex === -1) {
        companyData = [saveData, ...companyList];
      } else {
        companyData[companyIndex] = saveData;
      }
      currentData.data = [];
      currentData.status = 'NEW';
      currentData.data = companyData.map(r => {
        const { companyBusinessList } = r;
        let firstData = _.isEmpty(companyBusinessList)
          ? {}
          : companyBusinessList[0];
        return {
          ...r,
          ...firstData
        };
      });

      // const detailGrid = this.rightGridApi.getDetailGridInfo(
      //   `detail_${saveData.sectorId}`
      // );
      // detailGrid.api.setRowData(currentData.data);
      this.rightGridApi.setRowData(selectedData.data);
    }
  };

  _createDrawer = () => {
    const { industryScoreDrawer, companyScoreDrawer } = this.state;
    return (
      <>
        {industryScoreDrawer.show && (
          <IndustryScoreDetailDrawer
            {...this.props}
            {...industryScoreDrawer}
            closeDrawer={this._onCloseSectorDrawer}
            reloadData={this._loadMyDataList}
          />
        )}
        {companyScoreDrawer.show && (
          <CompanyScoreDetailDrawer
            {...this.props}
            {...companyScoreDrawer}
            closeDrawer={this._onCloseCompanyScoreDrawer}
            reloadData={this._loadMyDataList}
          />
        )}
      </>
    );
  };

  _autoSaveAllCache = async currentData => {
    if (!_.isEmpty(currentData.data)) {
      for (let i = 0; i < currentData.data.length; i++) {
        const r = currentData.data[i];
        const value = await getValue(r.id, 'companyScore');
        if (!_.isEmpty(value)) {
          const submitData = {
            ...value,
            reviewDetail: JSON.stringify(value.review)
          };
          try {
            await client.saveCompanyScore(submitData);
            delKey(r.id, 'companyScore');
          } catch (err) {
            console.log(err);
          }
        }
      }
    }
    try {
      const value = await getValue(currentData.id, 'sector');
      if (!_.isEmpty(value)) {
        Object.keys(value.review).forEach(r => {
          if (_.isArray(value.review[r])) {
            value.review[r] = value.review[r].filter(r => r);
          }
        });
        const submitData = {
          ...value,
          reviewDetail: JSON.stringify(value.review)
        };
        await client.saveIndustryScore(submitData);
        delKey(submitData.id, 'sector');
      }
    } catch (err) {
      console.log(err);
    }
  };

  _onSubmitPublish = async id => {
    const { selectedData } = this.state;
    // auto save all cache data
    const index = _.findIndex(selectedData.data, r => r.id === id);
    const currentData = selectedData.data[index];
    this.setState({
      submitStatus: 'SUBMITING'
    });
    await this._autoSaveAllCache(currentData);
    client
      .publishIndustry({ id })
      .then(resp => {
        selectedData.data[index] = resp.data;
        this.rightGridApi.setRowData(selectedData.data);
        //this.rightGridApi.applyTransaction({update: [resp.data]});
        //this._onClosePublishModal();
        this.setState({
          submitStatus: 'READY'
        });
        this._loadMyDataList();
      })
      .catch(err => {
        this.setState({
          submitStatus: 'READY'
        });
        console.log(err);
      });
  };

  // _onClosePublishModal = () => {
  //     this.setState({
  //         pushModal: {
  //             show: false,
  //             data: {}
  //         }
  //     })
  // }

  _openPublishModal = data => {
    this._onSubmitPublish(data.id);
    // this.setState({
    //     pushModal: {
    //         show: true,
    //         data
    //     }
    // })
  };

  openHelpDocument = () => {
    window.open(config.api.RESEARCH_URL + '/file/viewHelpDoc/companyScore');
  };

  _createOpBtns = () => {
    const { activeTab, isLoaded } = this.state;
    const { id } = this.state.selectedData;
    return (
      <>
        <Button
          size="small"
          style={{ marginRight: '5px' }}
          type="primary"
          onClick={this._loadMyDataList}
          disabled={isLoaded}
        >
          {isLoaded ? 'Reloading' : 'Reload'}
        </Button>
        {_.isEmpty(id) && activeTab === SOCRE_MY && (
          <>
            <Button
              onClick={this._addSector}
              size="small"
              style={{ marginRight: '5px' }}
              type="primary"
            >
              Add Sector
            </Button>
          </>
        )}
        <QuestionCircleOutlined
          title="help document"
          style={{
            width: '32px',
            height: '32px',
            color: 'white',
            fontSize: '20px'
          }}
          onClick={this.openHelpDocument}
        />
      </>
    );
  };

  // _createPushModal = () => {
  //     const { show, data } = this.state.pushModal;
  //     return (
  //         show && <PublishModal
  //             onSubmit={this._onSubmitPublish}
  //             closeModal={this._onClosePublishModal}
  //             data={data}
  //         />
  //     )
  // }

  render() {
    const { submitStatus } = this.state;
    return (
      <div className="companyScorePage" style={{ height: '100%' }}>
        <style>
          {`
                       .companyScorePage .ant-rate-star:not(:last-child){
                            margin-right: 2px;
                       }

                       .companyScorePage .blueStar.ant-rate{
                            color: lightseagreen !important;
                       }

                       .companyScorePage .hasNotes {
                          border-left: 3px solid red;
                      }
                    `}
        </style>

        <div style={{ textAlign: 'right' }}>{this._createOpBtns()}</div>
        {submitStatus !== 'READY' ? (
          <Spin spinning={true} tip="Publishing"></Spin>
        ) : (
          <SplitPane
            split="vertical"
            defaultSize="10%"
            style={{ position: 'relative', height: '100%' }}
          >
            {this._createLeftTabs()}
            {this._createRightGrid()}
          </SplitPane>
        )}

        {this._createDrawer()}
        {/* {this._createPushModal()} */}
      </div>
    );
  }
}

export default CompanyScoreGrid;
