import {
  LOAD_SETTINGS_SUCCESS,
  LOAD_SETTINGS_FAILURE,
  HELP_DIALOG_OPEN,
  HELP_DIALOG_RESET,
  USER_SETTING_DIALOG_OPEN,
  USER_SETTING_DIALOG_RESET,
  OPEN_USER_SETTINGS_SUCCESS,
  OPEN_USER_SETTINGS_FAILURE
} from './settingConstants';
import client from './api/client';
import { loadNotificationList } from 'features/notification/notificationActions';

function loadSettingsSuccess(settings) {
  return {
    type: LOAD_SETTINGS_SUCCESS,
    payload: settings
  };
}

function loadSettingsFailure(err) {
  return {
    type: LOAD_SETTINGS_FAILURE,
    payload: err
  };
}

export function loadSettings() {
  return function(dispatch) {
    client
      .loadSettings()
      .then(resp => {
        dispatch(loadSettingsSuccess(resp));

        // Send subscription for realtime data.
        const {
          user: { englishName }
        } = resp;
        dispatch(loadNotificationList({ userName: englishName }));
      })
      .catch(err => {
        dispatch(loadSettingsFailure(err));
      });
  };
}

export function openHelpDialog() {
  return {
    type: HELP_DIALOG_OPEN
  };
}

export function resetHelpDialog() {
  return {
    type: HELP_DIALOG_RESET
  };
}

export function openUserSettingDialog() {
  return function(dispatch) {
    client
      .queryUserCustomSettings()
      .then(resp => {
        dispatch(opUserSettingDialog());
        dispatch(openUserSettingDialogSuccess(resp));
      })
      .catch(err => {
        dispatch(openUserSettingDialogFailed(err));
      });
  };
}

export function openUserSettingDialogSuccess(payload) {
  return {
    type: OPEN_USER_SETTINGS_SUCCESS,
    payload
  };
}

export function openUserSettingDialogFailed(payload) {
  return {
    type: OPEN_USER_SETTINGS_FAILURE
  };
}

export function opUserSettingDialog() {
  return {
    type: USER_SETTING_DIALOG_OPEN
  };
}

export function resetUserSettingDialog() {
  return {
    type: USER_SETTING_DIALOG_RESET
  };
}
