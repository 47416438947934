import _ from 'lodash';
import RouteCalculator from './calculators/RouteCalculator';

const FIELDS = [
  'refId',
  'key',

  'orderRefId',
  'tradeDate',
  'lockDate',

  'fundCode',
  'bookCode',
  'side',
  'ticker',
  'custodian',
  'cfdType',
  'settlementCcy',
  'instruction',
  'algoCode',
  'algoParams',
  'venue',
  'broker',
  'handlingInstruction',
  'qty',
  'limitPrice',
  'orderType',
  'status',
  'account',
  'notes',
  'loanType',

  'filled',
  'avgPrice',
  'fxRate',
  'subSource',
  'accountType'
];

const Route = {
  toForm(route, order, ctx) {
    if (!route) {
      return {};
    }

    const { mmaTicker } = order;
    const borrowRate = isNaN(parseFloat(route.borrowRate))
      ? null
      : parseFloat(route.borrowRate) * 100;

    // In order to calculate isDirectLine.
    const result = RouteCalculator.calcByField(
      { ...route, order },
      'venue',
      ctx
    );

    return this._enrichRoute(
      {
        ..._.pick(route, FIELDS),
        // fundCode,
        // bookCode,

        borrowRate,
        mmaTicker,

        order,
        ...result
      },
      ctx
    );
  },
  toModel(values) {
    const limitPrice =
      values.orderType === 'LMT' ? parseFloat(values.limitPrice) : null;
    const routeModel = {
      ...values,

      qty: parseInt(values.qty, 10),
      avgPrice: values.avgPrice ? parseFloat(values.avgPrice) : null,
      filled: values.filled ? parseInt(values.filled) : null,
      fxRate: values.fxRate ? parseFloat(values.fxRate) : null,
      limitPrice: limitPrice,
      orderType: values.orderType,
      timeInForce: 'DAY',
      borrowRate: !isNaN(parseFloat(values.borrowRate))
        ? parseFloat(values.borrowRate) / 100
        : null,
      algo: {
        code: values.algoCode,
        params: values.algoParams
      },
      subSource: values.msg
    };

    // Remove several realtime fields if edit non-manual route.
    if (routeModel.venue !== 'MANUAL' && routeModel.refId) {
      ['filled', 'avgPrice', 'status'].forEach(f => _.unset(routeModel, f));
    }

    // Calculate status field for manual route.
    if (routeModel.venue === 'MANUAL') {
      routeModel.status = !routeModel.filled
        ? 'PENDING'
        : parseInt(routeModel.filled, 10) === parseInt(routeModel.qty, 10)
        ? 'FILLED'
        : 'PARTFILL';
    }

    return routeModel;
  },
  emptyForm(order, routeInfo, ctx) {
    const {
      refId: orderRefId,
      // fundCode,
      // bookCode,
      side,
      limitPriceLocal,
      algoCode,
      tradeDate,
      orderType,

      mmaTicker
    } = order;

    const r = {
      orderRefId,
      // fundCode,
      // bookCode,
      tradeDate,

      side,
      algoCode,
      venue: 'EMSX',
      handlingInstruction: 'LT',
      limitPrice: limitPriceLocal,
      orderType,
      status: 'PENDING',
      filled: null,
      avgPrice: null,
      borrowRate: null,
      notes: null,

      ...routeInfo,

      mmaTicker,
      order
    };

    return this._enrichRoute(r, ctx);
  },
  parseHoldingKey(r) {
    const {
      accountType,
      order: { strategy }
    } = r;
    //this.parseAccountType(r)
    return `${r.fundCode}|${r.bookCode}|${strategy}|${r.custodian}|${
      r.ticker
    }|${r.cfdType}|${accountType}|${this.parseDirection(r)}`;
  },
  isCancelled(r) {
    return ['REJECTED', 'CANCEL', 'EXPIRED'].includes(r.status);
  },
  isOpen(r) {
    return ['BUY', 'SHRT'].includes(r.side);
  },
  isCloseTdy(r) {
    return ['SELL_T', 'COVR_T'].includes(r.side);
  },
  isShort(r) {
    return ['SHRT', 'COVR', 'COVR_T'].includes(r.side);
  },
  parseSign(r) {
    return ['BUY', 'COVR', 'COVR_T'].includes(r.side) ? 1 : -1;
  },
  parseDirection(r) {
    const {
      order: { side }
    } = r;
    let currentSide = r.side ? r.side : side;
    return ['BUY', 'SELL', 'SELL_T'].includes(currentSide) ? 'LONG' : 'SHORT';
  },
  parseAccountType(r) {
    return r.broker && r.broker.endsWith('MARGIN') ? 'MARGIN' : 'DEFAULT';
  },
  isDirectLineReadOnly(r, brokerMap) {
    const broker = brokerMap[r.broker] || {};
    return !broker.enableDirectLine;
  },
  _enrichRoute(route, { algoMapping = {} }) {
    const {
      algoCode: routeAlgoCode,
      algoParams: routeAlgoParams = {},
      bookCode,
      order
    } = route;
    const { algoCode: orderAlgoCode, algoParams: orderAlgoParams = {} } = order;

    const enrichedAlgoMapping = _.fromPairs(
      Object.entries(algoMapping).map(([k, v]) => {
        const algoParamInfos = v.map(p => ({
          ...p,
          value:
            (routeAlgoCode === k && routeAlgoParams[p.code]) ||
            (orderAlgoCode !== routeAlgoCode &&
              orderAlgoCode === k &&
              orderAlgoParams[p.code]) ||
            p.defaultValues[bookCode] ||
            p.defaultValues['']
        }));

        return [k, algoParamInfos];
      })
    );

    const enrichedAlgoParams = _.fromPairs(
      (enrichedAlgoMapping[routeAlgoCode] || [])
        .filter(p => p.value && p.value !== '')
        .map(p => [p.code, p.value])
    );

    return {
      ...route,

      algoMapping: enrichedAlgoMapping,
      algoParams: enrichedAlgoParams
    };
  }
};

export default Route;
