import config from 'common/config';
import apiClient from 'common/api/apiClient';

const client = {
  loadAllTickers: () => {
    const url = `${config.api.REST_URL}/research/getSfcTickers`;
    return apiClient.get(url);
  },

  loadShortInterest: (startDate, endDate, ticker) => {
    const url = `${config.api.REST_URL}/research/getSfcShortInterest?ticker=${ticker}`;
    return apiClient.get(url);
  },

  loadAxeListTickers: (date, ticker) => {
    const url = ticker
        ? `${config.api.REST_URL}/research/getAxeList?date=${date}&ticker=${ticker}`
        : `${config.api.REST_URL}/research/getAxeList?date=${date}`;

    return apiClient.get(url);
  },

  loadShortableTickers: date => {
    const url = `${config.api.REST_URL}/research/getShortList?date=${date}`;
    return apiClient.get(url);
  },

  loadCompanyResearch: date => {
    const url = `${config.api.REST_URL}/research/getCompanyResearch?date=${date}`;
    return apiClient.get(url);
  },

  submitCompanyResearch: companyResearch => {
    const url = companyResearch.id
        ? `${config.api.REST_URL}/research/updateCompanyResearch`
        : `${config.api.REST_URL}/research/insertCompanyResearch`;
    return apiClient.post(url, companyResearch);
  },

  deleteCompanyResearch: companyResearchId => {
    const url = `${config.api.REST_URL}/research/deleteCompanyResearch`;
    return apiClient.post(url, { id: companyResearchId });
  },

  loadBrokerScores: date => {
    const url = `${config.api.REST_URL}/research/getBrokerScores?date=${date}`;
    return apiClient.get(url);
  },

  submitBrokerScore: brokerScore => {
    const url = brokerScore.id
        ? `${config.api.REST_URL}/research/updateBrokerScore`
        : `${config.api.REST_URL}/research/insertBrokerScore`;
    return apiClient.post(url, brokerScore);
  },

  deleteBrokerScore: brokerScoreId => {
    const url = `${config.api.REST_URL}/research/deleteBrokerScore`;
    return apiClient.post(url, { id: brokerScoreId });
  },

  loadAnalystCompliment: date => {
    const url = `${config.api.REST_URL}/research/getAnalystCompliment?date=${date}`;
    return apiClient.get(url);
  },

  submitAnalystCompliment: analystCompliment => {
    const url = analystCompliment.id
        ? `${config.api.REST_URL}/research/updateAnalystCompliment`
        : `${config.api.REST_URL}/research/insertAnalystCompliment`;
    return apiClient.post(url, analystCompliment);
  },

  deleteAnalystCompliment: analystComplimentId => {
    const url = `${config.api.REST_URL}/research/deleteAnalystCompliment`;
    return apiClient.post(url, { id: analystComplimentId });
  },

  loadBrokerNames: () => {
    const url = `${config.api.REST_URL}/research/getBrokerNames`;
    return apiClient.get(url);
  },

  loadBoxedPosition: () => {
    const url = `${config.api.REST_URL}/portfolio/getBoxedPositions`;
    return apiClient.get(url);
  },

  // monthly review
  loadMonthlyReviewBenchmark: queryParams => {
    const url = `${config.api.RESEARCH_URL}/monthlyReview/getMonthlyReviewBenchmark`;
    return apiClient.post(url, queryParams);
  },

  loadMonthlyReviewMyPortfolio: queryParams => {
    const url = `${config.api.PXY_URL}/research/getMonthlyReviewMyPortfolio`;
    return apiClient.post(url, queryParams);
  },

  loadMonthlyReviewDetail: id => {
    const url = `${config.api.RESEARCH_URL}/monthlyReview/getMonthlyReviewDetail`;
    return apiClient.post(url, { id: id });
  },

  loadMonthlyReviewList: queryParams => {
    const url = `${config.api.RESEARCH_URL}/monthlyReview/getMonthlyReviewList`;
    return apiClient.post(url, queryParams);
  },

  saveMonthlyReview: params => {
    const url = `${config.api.RESEARCH_URL}/monthlyReview/saveMonthlyReview`;
    return apiClient.post(url, params);
  },

  submitMonthlyReview: params => {
    const url = `${config.api.RESEARCH_URL}/monthlyReview/submitMonthlyReview`;
    return apiClient.post(url, params);
  },

  removeMonthlyReview: params => {
    const url = `${config.api.RESEARCH_URL}/monthlyReview/removeMonthlyReview`;
    return apiClient.post(url, params);
  },

  loadBrokerScoreListByScore: queryParams => {
    const url = `${config.api.RESEARCH_URL}/brokerScore/queryBrokerScoreListByScore`;
    return apiClient.post(url, queryParams);
  },

  loadBrokerScoreList: queryParams => {
    const url = `${config.api.RESEARCH_URL}/brokerScore/getBrokerScoreList`;
    return apiClient.post(url, queryParams);
  },

  saveBrokerScore: params => {
    const url = `${config.api.RESEARCH_URL}/brokerScore/saveBrokerScore`;
    return apiClient.post(url, params);
  },

  loadBrokerCanScoreDate: params => {
    const url = `${config.api.RESEARCH_URL}/brokerScore/loadBrokerCanScoreDate`;
    return apiClient.post(url, params);
  },

  removeBrokerScore: params => {
    const url = `${config.api.RESEARCH_URL}/brokerScore/removeBrokerScore`;
    return apiClient.post(url, params);
  },

  loadCorrespondenceList: params => {
    const url = `${config.api.RESEARCH_URL}/brokerScore/getCorrespondenceList`;
    return apiClient.post(url, params);
  },

  loadShortableList: params => {
    const url = `${config.api.RESEARCH_URL}/shortable/getShortList`;
    return apiClient.post(url, params);
  },

  loadShortExchangeList: () => {
    const url = `${config.api.RESEARCH_URL}/shortable/loadExchangeList`;
    return apiClient.post(url);
  },

  loadAnalystList: params => {
    const url = `${config.api.RESEARCH_URL}/analyst/getAnalystList`;
    return apiClient.post(url, params);
  },

  saveAnalyst: params => {
    const url = `${config.api.RESEARCH_URL}/analyst/saveAnalyst`;
    return apiClient.post(url, params);
  },

  deleteAnalyst: params => {
    const url = `${config.api.RESEARCH_URL}/analyst/deleteAnalyst`;
    return apiClient.post(url, params);
  },

  getBrokerList: param => {
    const url = `${config.api.RESEARCH_URL}/brokerScore/getBrokerListWithUserId`;
    return apiClient.post(url, param);
  },

  getAnalystNameEditList: param => {
    const url = `${config.api.RESEARCH_URL}/analyst/getAnalystNameList`;
    return apiClient.post(url, param);
  },

  getAnalystSectorNameList: param => {
    const url = `${config.api.RESEARCH_URL}/analyst/getSectorNameList`;
    return apiClient.post(url, param);
  },

  getAnalystDetailList: params => {
    const url = `${config.api.RESEARCH_URL}/analyst/getAnalystDetailList`;
    return apiClient.post(url, params);
  },

  getGrossAndBetaByFundAndBook: params => {
    const url = `${config.api.RESEARCH_URL}/monthlyReview/getGrossAndBetaByFundAndBook`;
    return apiClient.post(url, params);
  },

  getManagerUserList: () => {
    const url = `${config.api.RESEARCH_URL}/monthlyReview/getManagerUserList`;
    return apiClient.post(url);
  },

  saveBrokerScoreRankList: params => {
    const url = `${config.api.RESEARCH_URL}/brokerScore/saveBrokerScoreRankList`;
    return apiClient.post(url, params);
  },

  queryBrokerScoreRankListByScore: params => {
    const url = `${config.api.RESEARCH_URL}/brokerScore/queryBrokerScoreRankListByScore`;
    return apiClient.post(url, params);
  },

  loadYearBrokerScores: params => {
    const url = `${config.api.RESEARCH_URL}/brokerScore/loadYearBrokerScores`;
    return apiClient.post(url, params);
  },

  loadResearchReportList: params => {
    const url = `${config.api.RESEARCH_URL}/researchReport/loadResearchReportList`;
    return apiClient.post(url, params);
  },

  loadResearchReportDetail: params => {
    const url = `${config.api.RESEARCH_URL}/researchReport/loadResearchReportDetail`;
    return apiClient.post(url, params);
  },

  searchTicker: params => {
    const url = `${config.api.RESEARCH_URL}/researchReport/searchTicker`;
    return apiClient.post(url, params);
  },

  saveResearchReport: params => {
    const url = `${config.api.RESEARCH_URL}/researchReport/saveResearchReport`;
    return apiClient.post(url, params);
  },

  copyResearchReport: params => {
    const url = `${config.api.RESEARCH_URL}/researchReport/copyResearchReport`;
    return apiClient.post(url, params);
  },

  getPersonList: params => {
    const url = `${config.api.RESEARCH_URL}/researchReport/getPersonList`;
    return apiClient.post(url, params);
  },

  getGicsList: params => {
    const url = `${config.api.RESEARCH_URL}/researchReport/getGicsList`;
    return apiClient.post(url, params);
  },

  downloadResearchReportAttch: params => {
    const url = `${config.api.RESEARCH_URL}/file/download`;
    return apiClient.post(url, params);
  },

  deleteResearchReportAttach: params => {
    const url = `${config.api.RESEARCH_URL}/researchReport/deleteAttach`;
    return apiClient.post(url, params);
  },

  getBlockList: params => {
    const url = `${config.api.RESEARCH_URL}/blockList/getBlockList`;
    return apiClient.post(url, params);
  },

  addCompanyKeyDataTrack: params => {
    const url = `${config.api.RESEARCH_URL}/companyKeyDataTrack/addCompanyKeyDataTrack`;
    return apiClient.post(url, params);
  },

  updateCompanyKeyDataTrack: params => {
    const url = `${config.api.RESEARCH_URL}/companyKeyDataTrack/updateCompanyKeyDataTrack`;
    return apiClient.post(url, params);
  },

  getCompanyKeyDataTrackList: params => {
    const url = `${config.api.RESEARCH_URL}/companyKeyDataTrack/getCompanyKeyDataTrackList`;
    return apiClient.post(url, params);
  },

  getCompanyKeyDataTrackDetail: params => {
    const url = `${config.api.RESEARCH_URL}/companyKeyDataTrack/getCompanyKeyDataTrackDetail`;
    return apiClient.post(url, params);
  },

  addCompanyMeetingRecord: params => {
    const url = `${config.api.RESEARCH_URL}/companyMeetingRecord/addCompanyMeetingRecord`;
    return apiClient.post(url, params);
  },

  updateCompanyMeetingRecord: params => {
    const url = `${config.api.RESEARCH_URL}/companyMeetingRecord/updateCompanyMeetingRecord`;
    return apiClient.post(url, params);
  },

  getCompanyMeetingRecordList: params => {
    const url = `${config.api.RESEARCH_URL}/companyMeetingRecord/getCompanyMeetingRecordList`;
    return apiClient.post(url, params);
  },

  getCompanyMeetingRecordDetail: params => {
    const url = `${config.api.RESEARCH_URL}/companyMeetingRecord/getCompanyMeetingRecordDetail`;
    return apiClient.post(url, params);
  },

  addCompanyReplayScore: params => {
    const url = `${config.api.RESEARCH_URL}/companyReplayScore/addCompanyReplayScore`;
    return apiClient.post(url, params);
  },

  updateCompanyReplayScore: params => {
    const url = `${config.api.RESEARCH_URL}/companyReplayScore/updateCompanyReplayScore`;
    return apiClient.post(url, params);
  },

  getCompanyReplayScoreList: params => {
    const url = `${config.api.RESEARCH_URL}/companyReplayScore/getCompanyReplayScoreList`;
    return apiClient.post(url, params);
  },

  getCompanyReplayScoreDetail: params => {
    const url = `${config.api.RESEARCH_URL}/companyReplayScore/getCompanyReplayScoreDetail`;
    return apiClient.post(url, params);
  },

  fetchAllExcellentCompany: () => {
    const url = `${config.api.RESEARCH_URL}/researchReport/fetchAllExcellentCompany`;
    return apiClient.post(url);
  },

  copyCompanyReplayScoreDetail: params => {
    const url = `${config.api.RESEARCH_URL}/companyReplayScore/copy`;
    return apiClient.post(url, params);
  },

  copyCompanyKeyDataTrackDetail: params => {
    const url = `${config.api.RESEARCH_URL}/companyKeyDataTrack/copy`;
    return apiClient.post(url, params);
  },

  loadShortInterestPercent: params => {
    const url = `${config.api.RESEARCH_URL}/shortInterest/getShortInterestPercent`;
    return apiClient.post(url, params);
  },

  //push votes
  loadPushVotesData: params => {
    const url = `${config.api.RESEARCH_URL}/pushVotes/queryPushVotesList`;
    return apiClient.post(url, params);
  },

  loadPushVoteReturnData: date => {
    const url = `${config.api.RESEARCH_URL}/pushVoteReturn/queryPushVotePerformanceList/${date}`;
    return apiClient.get(url);
  },

  querySubscriptionList: params => {
    const url = `${config.api.RESEARCH_URL}/pushVotes/querySubscriptionList`;
    return apiClient.post(url, params);
  },

  queryUserAccountList: params => {
    const url = `${config.api.RESEARCH_URL}/pushVotes/queryUserAccountList`;
    return apiClient.post(url, params);
  },

  savePushVotes: params => {
    const url = `${config.api.RESEARCH_URL}/pushVotes/savePushVotes`;
    return apiClient.post(url, params);
  },

  adjustPushVotes: params => {
    const url = `${config.api.RESEARCH_URL}/pushVotes/adjustPushVotes`;
    return apiClient.post(url, params);
  },

  addPushVoteNote: params => {
    const url = `${config.api.RESEARCH_URL}/pushVotes/addPushVoteNote`;
    return apiClient.post(url, params);
  },

  queryPushVoteNoteList: params => {
    const url = `${config.api.RESEARCH_URL}/pushVotes/queryPushVoteNoteList`;
    return apiClient.post(url, params);
  },

  readRecordDetailInfo: params => {
    const url = `${config.api.RESEARCH_URL}/pushVotes/readRecordDetailInfo`;
    return apiClient.post(url, params);
  },

  queryPushVotesByIdAndMarkRead: params => {
    const url = `${config.api.RESEARCH_URL}/pushVotes/queryPushVotesByIdAndMarkRead`;
    return apiClient.post(url, params);
  },

  queryPushVotesById: params => {
    const url = `${config.api.RESEARCH_URL}/pushVotes/queryPushVotesById`;
    return apiClient.post(url, params);
  },

  getPortfolioPlanList: params => {
    const url = `${config.api.RESEARCH_URL}/portfolioPlan/getPortfolioPlanList`;
    return apiClient.post(url, params);
  },

  savePortfolioPlan: params => {
    const url = `${config.api.RESEARCH_URL}/portfolioPlan/savePortfolioPlan`;
    return apiClient.post(url, params);
  },

  getPortfolioPlanByMonth: params => {
    const url = `${config.api.RESEARCH_URL}/portfolioPlan/getPortfolioPlanByMonth`;
    return apiClient.post(url, params);
  },

  saveIndustryScore: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/saveIndustryScore`;
    return apiClient.post(url, params);
  },

  saveCompanyScore: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/saveCompanyScore`;
    return apiClient.post(url, params);
  },

  savePublishCompanyScore: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/savePublishCompanyScore`;
    return apiClient.post(url, params);
  },

  publishIndustry: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/publishIndustry`;
    return apiClient.post(url, params);
  },

  savePublishIndustryScore: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/savePublishIndustryScore`;
    return apiClient.post(url, params);
  },
  
  getCompanyScoreDetail: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/getCompanyScoreDetail `;
    return apiClient.post(url, params);
  },

  getMyDataList: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/getMyDataList `;
    return apiClient.post(url, params);
  },

  getOthersDataList: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/getOthersDataList `;
    return apiClient.post(url, params);
  },

  getAllNewestCompanyScoreDataList: () => {
    const url = `${config.api.RESEARCH_URL}/companyScore/getAllNewestDataList `;
    return apiClient.post(url);
  },

  viewHelpDoc: type => {
    const url = `${config.api.RESEARCH_URL}/file/viewHelpDoc/${type}`;
    return apiClient.get(url);
  },

  saveCompanyStrategy: params => {
    const url = `${config.api.RESEARCH_URL}/companyStrategy/saveCompanyStrategy `;
    return apiClient.post(url, params);
  },

  getCompanyStrategyList: () => {
    const url = `${config.api.RESEARCH_URL}/companyStrategy/getCompanyStrategyList `;
    return apiClient.post(url);
  },

  getCompanyStrategyDetail: yearMonth => {
    const url = `${config.api.RESEARCH_URL}/companyStrategy/getCompanyStrategyDetail/${yearMonth} `;
    return apiClient.post(url);
  },

  queryStockHolding: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/queryStockHolding `;
    return apiClient.post(url, params);
  },

  queryStockIndexInfoList: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/queryStockIndexInfoList`;
    return apiClient.post(url, params);
  },

  getLastCompanyList: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/getLastCompanyList`;
    return apiClient.post(url, params);
  },

  getLastSnapshot: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/getLastSnapshot`;
    return apiClient.post(url, params);
  },

  deleteCompany: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/deleteCompany`;
    return apiClient.post(url, params);
  },

  saveCompanyScoreNotes: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/companyScoreNotes/save`;
    return apiClient.post(url, params);
  },

  overrideCompanyScoreNotes: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/companyScoreNotes/override`;
    return apiClient.post(url, params);
  },

  getResultCompanyScoreNotes: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/companyScoreNotes/getResult`;
    return apiClient.post(url, params);
  },

  queryThemeResult: params => {
    const url = `${config.api.RESEARCH_URL}/themeAnalysis/query`;
    return apiClient.post(url, params);
  },

  queryAllThemes: () => {
    const url = `${config.api.RESEARCH_URL}/themeAnalysis/queryAllThemes`;
    return apiClient.post(url);
  },

  queryNotes: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/companyScoreNotes/queryNotes`;
    return apiClient.post(url, { noteIds: params });
  },

  saveAnalystDaily: params => {
    const url = `${config.api.RESEARCH_URL}/analystDaily/save`;
    return apiClient.post(url, params);
  },

  getDailyList: () => {
    const url = `${config.api.RESEARCH_URL}/analystDaily/getDailyList`;
    return apiClient.post(url);
  },

  getAnalystDailyContext: () => {
    const url = `${config.api.RESEARCH_URL}/analystDaily/getContext`;
    return apiClient.post(url);
  },

  getDailyWithId: params => {
    const url = `${config.api.RESEARCH_URL}/analystDaily/getDailyWithId`;
    return apiClient.post(url, params);
  },

  getAllDailyList: () => {
    const url = `${config.api.RESEARCH_URL}/analystDaily/getAllDailyList`;
    return apiClient.post(url);
  },

  getHistLog: params => {
    const url = `${config.api.RESEARCH_URL}/analystDaily/getHistLog`;
    return apiClient.post(url, params);
  },

  getCustomConfig: () => {
    const url = `${config.api.RESEARCH_URL}/analystDaily/getCustomConfig`;
    return apiClient.post(url);
  },

  getConfigUserList: () => {
    const url = `${config.api.RESEARCH_URL}/analystDaily/getConfigUserList`;
    return apiClient.post(url);
  },

  addConfig: params => {
    const url = `${config.api.RESEARCH_URL}/analystDaily/addConfig`;
    return apiClient.post(url, params);
  },

  updateConfig: params => {
    const url = `${config.api.RESEARCH_URL}/analystDaily/updateConfig`;
    return apiClient.post(url, params);
  },

  getUserDefaultPushConfig: () => {
    const url = `${config.api.RESEARCH_URL}/analystDaily/getUserDefaultPushConfig`;
    return apiClient.post(url);
  },

  getAllCanConfigUser: () => {
    const url = `${config.api.RESEARCH_URL}/analystDaily/getAllCanConfigUser`;
    return apiClient.post(url);
  },

  removeConfigUser: params => {
    const url = `${config.api.RESEARCH_URL}/analystDaily/remove`;
    return apiClient.post(url, params);
  },

  getResearchLog: params => {
    const url = `${config.api.RESEARCH_URL}/pushVotes/getResearchLog`;
    return apiClient.post(url, params);
  },

  getAllStudentInfos: () => {
    const url = `${config.api.RESEARCH_URL}/student/getAllStudentInfos`;
    return apiClient.post(url);
  },

  getCompanyResearchInfos: params => {
    const url = `${config.api.RESEARCH_URL}/student/getCompanyResearchInfos`;
    return apiClient.post(url, params);
  },

  submitStudentManageData: data => {
    const url = `${config.api.RESEARCH_URL}/student/submitStudentManageData`;
    return apiClient.post(url, { data });
  },

  getShortListHist: data => {
    const url = `${config.api.RESEARCH_URL}/shortable/getShortListHist`;
    return apiClient.post(url, data);
  },

  getPaperRiskAnalysts: date => {
    const url = `${config.api.RESEARCH_URL}/student/getPaperRiskAnalysts/${date}`;
    return apiClient.get(url);
  },

  getCompanyChat: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/getCompanyChat`;
    return apiClient.post(url, params);
  },

  findCompanyScoreDetail: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/findCompanyScoreDetail`;
    return apiClient.post(url, params);
  },

  getRelPosition: () => {
    const url = `${config.api.RESEARCH_URL}/pushVotes/getRelPosition`;
    return apiClient.post(url);
  },

  getCollegeTrades: params => {
    const url = `${config.api.PXY_URL}/research/getCollegeTrades`;
    return apiClient.post(url, params);
  },

  getPairCompanyInfo: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/getPairCompanyInfo`;
    return apiClient.post(url, params);
  },

  findCompanyScoreDetailByTicker: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/findCompanyScoreDetailByTicker`;
    return apiClient.post(url, params);
  },

  getPmNotice: params => {
    const url = `${config.api.RESEARCH_URL}/pushVotes/getPmNotice`;
    return apiClient.post(url, params);
  },

  getSectorDesc: params => {
    const url = `${config.api.RESEARCH_URL}/companyScore/getSectorDesc`;
    return apiClient.post(url, params);
  },
};

export default client;
