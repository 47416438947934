import React, { Component } from 'react';

import SingleTradeDialog from './dialogs/SingleTradeDialog';
import CancelTradeModalForm from './dialogs/CancelTradeModalForm';
import TradeGrid from './TradeGrid';
import OperationBar from './OperationBar';

import { Loader } from 'semantic-ui-react';
import Trade from '../entities/Trade';
import _ from 'lodash';
import './OrderDashboard.css';
import {
  TRADE_VIEW_MODE_LIVE,
  DIALOG_CANCEL_TRADE,
  DIALOG_EXECUTE_SINGLE_TRADE,
  DIALOG_EXECUTE_MULTI_TRADES,
  DIALOG_EXECUTE_QUANT_TRADES,
  DIALOG_EXECUTE_IPO_TRADES,
  DIALOG_ADJUST_PORTFOLIO,
  DIALOG_ROLL_POSITIONS,
  DIALOG_EXECUTE_CB_TRADES
} from '../orderConstants';
import {
  canPlaceOrder,
  isPaperTradeFund
} from '../../../common/utils/DomainUtils';
import MultiTradesDialog from './dialogs/MultiTradesDialog';
import QuantTradesDialog from './dialogs/QuantTradesDialog';
import IPOTradesDialog from './dialogs/IPOTradesDialog';
import AdjustPortfolioDialog from './dialogs/AdjustPortfolioDialog';
import RollPositionsDialog from './dialogs/RollPositionsDialog';
import CBBatchTradesDialog from './dialogs/CBBatchTradesDialog';

class OrderDashboard extends Component {
  _openDialog = (dialogCode, info = {}) => {
    this.props.openDialog(dialogCode, info);
  };

  onEditButtonClick = () => {
    const { selectedTrade } = this.props;

    const defaultFields = Trade.toForm(selectedTrade);
    const requestType = defaultFields['requestType'];
    let dialogCode = DIALOG_EXECUTE_SINGLE_TRADE;
    if (requestType === 'IPO') {
      defaultFields['securityCode'] = selectedTrade['securityCode'];
      dialogCode = DIALOG_EXECUTE_IPO_TRADES;
    }
    this._openDialog(dialogCode, {
      createNew: false,
      defaultFields
    });
  };

  getContextMenuItems = trade => {
    const { tradeLabel } = this.props.settings;
    const { selectedTrades } = this.props;
    const isEditDisabled = this.isEditDisabled(trade);
    const isCancelDisabled = _.isEmpty(selectedTrades);
    const canDoTrade = this.canDoTrade();
    if (canDoTrade) {
      return [
        isEditDisabled
          ? null
          : {
              name: `Edit ${tradeLabel}`,
              action: () => this.onEditButtonClick()
            },
        isCancelDisabled
          ? null
          : {
              name: `Cancel ${tradeLabel}`,
              action: () => this._openDialog(DIALOG_CANCEL_TRADE)
            },
        'separator'
      ].filter(Boolean);
    }

    return [];
  };

  isCancelDisabled = trade => {
    return (
      !trade ||
      ['SUBMITTING', 'WITHDRAWING', 'MODIFYING', 'WITHDRAW'].includes(
        trade.pmStatus
      ) ||
      trade.traderStatus === 'REJECT' ||
      trade.requestStatus === 'ARCHIVE'
    );
  };

  isEditDisabled = trade => {
    return this.isCancelDisabled(trade) || isPaperTradeFund(trade.fundCode);
  };

  canDoTrade = () => {
    const {
      user,
      ui: { viewMode }
    } = this.props;

    return (
      canPlaceOrder(user ? user.role : '') && viewMode === TRADE_VIEW_MODE_LIVE
    );
  };

  _createDialog = (dialogCode, info) => {
    const {
      selectedTrades,
      closeDialog,
      submitDialogSuccess,
      settings,
      liveRiskInfos,
      liveRiskInfoMap,
      livePositionMap
    } = this.props;

    switch (dialogCode) {
      case DIALOG_CANCEL_TRADE:
        return (
          <CancelTradeModalForm
            key={dialogCode}
            closeDialog={closeDialog}
            submitDialogSuccess={submitDialogSuccess}
            isCancelDisabled={this.isCancelDisabled}
            selectedTrades={selectedTrades}
            settings={settings}
          />
        );
      case DIALOG_EXECUTE_SINGLE_TRADE:
        return (
          <SingleTradeDialog
            key={dialogCode}
            info={info}
            closeDialog={closeDialog}
            submitDialogSuccess={submitDialogSuccess}
            settings={settings}
            liveRiskInfos={liveRiskInfos}
          />
        );
      case DIALOG_EXECUTE_MULTI_TRADES:
        return (
          <MultiTradesDialog
            key={dialogCode}
            info={info}
            closeDialog={closeDialog}
            submitDialogSuccess={submitDialogSuccess}
            settings={settings}
            liveRiskInfos={liveRiskInfos}
          />
        );
      case DIALOG_EXECUTE_QUANT_TRADES:
        return (
          <QuantTradesDialog
            key={dialogCode}
            info={info}
            closeDialog={closeDialog}
            submitDialogSuccess={submitDialogSuccess}
            settings={settings}
            liveRiskInfos={liveRiskInfos}
          />
        );
      case DIALOG_EXECUTE_IPO_TRADES:
        return (
          <IPOTradesDialog
            key={dialogCode}
            info={info}
            closeDialog={closeDialog}
            submitDialogSuccess={submitDialogSuccess}
            settings={settings}
            liveRiskInfoMap={liveRiskInfoMap}
          />
        );
      case DIALOG_EXECUTE_CB_TRADES:
        return (
          <CBBatchTradesDialog
            key={dialogCode}
            info={info}
            closeDialog={closeDialog}
            openDialog={this._openDialog}
            submitDialogSuccess={submitDialogSuccess}
            settings={settings}
            liveRiskInfos={liveRiskInfos}
          />
        );
      case DIALOG_ADJUST_PORTFOLIO:
        return (
          <AdjustPortfolioDialog
            key={dialogCode}
            closeDialog={closeDialog}
            submitDialogSuccess={submitDialogSuccess}
            settings={settings}
            livePositionMap={livePositionMap}
            liveRiskInfoMap={liveRiskInfoMap}
          />
        );
      case DIALOG_ROLL_POSITIONS:
        return (
          <RollPositionsDialog
            key={dialogCode}
            openDialog={this._openDialog}
            closeDialog={closeDialog}
            submitDialogSuccess={submitDialogSuccess}
            settings={settings}
            livePositionMap={livePositionMap}
            liveRiskInfoMap={liveRiskInfoMap}
          />
        );
      default:
        return null;
    }
  };

  _createDialogs = () => {
    const { dialogs } = this.props;
    return Object.keys(dialogs).map(dialogCode => {
      const dialog = dialogs[dialogCode];

      return dialog.isOpened
        ? this._createDialog(dialogCode, dialog.info)
        : null;
    });
  };

  componentDidMount() {
    const { user } = this.props;
    if (!user) return;

    this.props.sendSubscription({
      view: 'TRADES',
      userName: user.englishName
    });
  }

  componentDidUpdate(prevProps) {
    const { user: prevUser } = prevProps;
    const { user } = this.props;

    if (!prevUser && user) {
      this.props.sendSubscription({
        view: 'TRADES',
        userName: user.englishName
      });
    }
  }

  render() {
    const {
      isLoaded,
      toggleViewMode,
      changeViewDate,
      ui,
      user,
      selectedTrade,
      selectedTrades,
      getTradeBlotter
    } = this.props;

    const dialogs = this._createDialogs();

    return (
      <div className="orderDashboardWrapper">
        <div className="widget">
          <OperationBar
            user={user}
            selectedTrade={selectedTrade}
            selectedTrades={selectedTrades}
            openSingleTradeDialog={() =>
              this._openDialog(DIALOG_EXECUTE_SINGLE_TRADE, { createNew: true })
            }
            openMultiTradesDialog={() =>
              this._openDialog(DIALOG_EXECUTE_MULTI_TRADES, { createNew: true })
            }
            openQuantTradesDialog={() =>
              this._openDialog(DIALOG_EXECUTE_QUANT_TRADES, { createNew: true })
            }
            openIPOTradesDialog={() =>
              this._openDialog(DIALOG_EXECUTE_IPO_TRADES, { createNew: true })
            }
            openCBTradesDialog={() =>
              this._openDialog(DIALOG_EXECUTE_CB_TRADES, { createNew: true })
            }
            openAdjustPortfolioDialog={() =>
              this._openDialog(DIALOG_ADJUST_PORTFOLIO)
            }
            openRollPositionsDialog={() =>
              this._openDialog(DIALOG_ROLL_POSITIONS)
            }
            openCancelTradeModal={() => this._openDialog(DIALOG_CANCEL_TRADE)}
            ui={ui}
            toggleViewMode={toggleViewMode}
            changeViewDate={changeViewDate}
            getTradeBlotter={getTradeBlotter}
            onEditButtonClick={this.onEditButtonClick}
            canDoTrade={this.canDoTrade}
            isEditDisabled={this.isEditDisabled}
            isCancelDisabled={this.isCancelDisabled}
          />

          {!isLoaded ? (
            <Loader active inverted content="Loading..." />
          ) : (
            <div style={{ overflow: 'hidden', flexGrow: '1', height: '100%' }}>
              <TradeGrid
                {...this.props}
                getContextMenuItems={this.getContextMenuItems}
              />

              {dialogs}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default OrderDashboard;
