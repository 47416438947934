import React, { Component } from 'react';
import { Drawer, Form, Row, Col, Button, Collapse } from 'antd';
import researchClient from '../../../api/client';
import CompanyReview from '../review/CompanyReview';
import _ from 'lodash';
import { getNextQuarter } from 'common/utils/DateUtils';
import TickerUtils from 'common/utils/TickerUtils';
import LinkPannel from './LinkPannel';

const { Panel } = Collapse;
class CompanyScoreDetailDrawerOnlyForDetail extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      scoreList: [1, 2, 3, 4, 5],
      loadNotesStatus: 'READY',
      conclusionList: ['Long', 'Short', 'No action'],
      competitionList: ['Intensifying', 'Weakening', 'No change'],
      marketViewList: ['High', 'Med', 'Low'],
      deltaList: ['Accelerate', 'Decelerate', 'No change'],
      companyBusinessList: [],
      data: {},
      submitStatus: 'READY',
      defaultSingleQData: [
        {
          id: 1,
          type: 'Revenue (million)',
          chineseIndex: '单季营收 (百万)',
          index: 'Revenue (million)',
          firstRow: true
        },
        {
          id: 2,
          type: 'Revenue (million)',
          index: 'YoY',
          disabled: true,
          isPercent: true
        },
        {
          id: 3,
          type: 'Revenue (million)',
          index: 'QoQ',
          disabled: true,
          isPercent: true
        },
        {
          id: 4,
          type: 'GP Margin',
          index: 'GP Margin',
          firstRow: true,
          isPercent: true
        },
        {
          id: 5,
          type: 'GP Margin',
          index: 'YoY(ppt)',
          disabled: true,
          isPpt: true
        },
        {
          id: 6,
          type: 'GP Margin',
          index: 'QoQ(ppt)',
          disabled: true,
          isPpt: true
        },
        {
          id: 7,
          type: 'Net profit',
          index: 'Net profit (million) ',
          firstRow: true,
          isPercent: true
        },
        {
          id: 8,
          type: 'Net profit',
          index: 'YoY',
          disabled: true
        },
        {
          id: 9,
          type: 'Net profit',
          index: 'QoQ',
          disabled: true
        },
        {
          id: 10,
          type: 'Other metrics',
          chineseIndex: '其他指标1',
          index: 'Other metrics 1'
        },
        {
          id: 11,
          type: 'Other metrics',
          chineseIndex: '其他指标2',
          index: 'Other metrics 2'
        },
        {
          id: 12,
          type: 'Other metrics',
          chineseIndex: '其他指标3',
          index: 'Other metrics 3'
        }
      ],
      defaultBottomData: [
        { id: 1, index: 'Sales expense' },
        { id: 2, index: 'Admin expense' },
        { id: 3, index: 'R&D (absolute value)' },
        {
          id: 4,
          type: 'Net interest expense',
          index: 'Net interest expense',
          excludeMarkets: ['CH']
        },
        {
          id: 5,
          type: 'Finance expense',
          index: 'Finance expense',
          markets: ['CH']
        }
      ],
      defaultFutureTrendData: [
        { id: 1, index: 1 },
        { id: 2, index: 2 },
        { id: 3, index: 3 },
        { id: 4, index: 4 }
      ],
      happenList: [
        'Preliminary results',
        'Results',
        'News',
        'Expert',
        'Company visit',
        'Event',
        'Other'
      ],
      notesDraw: {
        show: false,
        name: ''
      },
      isDraft: false,
      showChatModal: false,
      tableConfig:[
        {group: 'Long-term factors', weightKey: 'longWeight', factor: 'Total Score', scoreKey: 'score', scoreOption: 'scoreList', reasonKey: 'longScoreReason', min: 30, required: true},
        {group: '', weightKey: false, factor: 'Investment Direction', scoreKey: 'longInvestmentConclusion', scoreOption: 'conclusionList', reasonKey: 'longInvestDirReason', required: true},
        {group: '', weightKey: false, factor: '3.5 Sentences', scoreKey: 'threeDirScore', scoreOption: 'threeDirList', reasonKey: 'threeDirReason'},
        {group: '', weightKey: false, factor: 'Corporate Governance', scoreKey: 'management', scoreOption: 'scoreList', reasonKey: 'managementDesc'},
        {group: '', weightKey: false, factor: '—Incentives', scoreKey: 'stockBasedCompensationScore', scoreOption: 'scoreList', reasonKey: 'stockBasedCompensation'},
        {group: '', weightKey: false, factor: '—Shareholder structure', scoreKey: 'equityStructureScore', scoreOption: 'scoreList', reasonKey: 'equityStructure'},
        {group: '', weightKey: false, factor: '—Punishment', scoreKey: 'punishmentScore', scoreOption: 'scoreList', reasonKey: 'punishment'},
        {group: '', weightKey: false, factor: '—Outside ownership', scoreKey: 'assetsOfMajorScore', scoreOption: 'scoreList', reasonKey: 'assetsOfMajorShareholders'},
        {group: '', weightKey: false, factor: 'Competitiveness', scoreKey: 'competitionScore', scoreOption: 'scoreList', reasonKey: 'competition'},
        {group: '', weightKey: false, factor: '—Market share Change', scoreKey: 'mkShareChgScore', scoreOption: 'scoreList', reasonKey: 'mkShareChgReason'},
        {group: '', weightKey: false, factor: '—Intensity of competition', scoreKey: 'intensityScore', scoreOption: 'scoreList', reasonKey: 'intensityReason'},

        {group: 'Short-term factors', weightKey: 'shortWeight', factor: 'Total Score', scoreKey: 'shortTotalScore', scoreOption: 'scoreList', reasonKey: 'shortTotalReason', required: true},
        {group: '', weightKey: false, factor: 'Investment Direction', scoreKey: 'shortInvestmentConclusion', scoreOption: 'conclusionList', reasonKey: 'shortInvestmentConclusionReason', required: true},
        {group: '', weightKey: false, render: 'list', renderData: [
            {group: '', weightKey: false, factorKey: 'segment', scoreKey: 'segmentScore', scoreOption: 'scoreList', reasonKey: 'segmentReason'},
            {group: '', weightKey: false, factor: '—Volume', scoreKey: 'volume', scoreOption: 'scoreList', reasonKey: 'volumeSecondDerivative'},
            {group: '', weightKey: false, factor: '—ASP', scoreKey: 'price', scoreOption: 'scoreList', reasonKey: 'priceSecondDerivative'},
            {group: '', weightKey: false, factor: '—Margin', scoreKey: 'margin', scoreOption: 'scoreList', reasonKey: 'marginSecondDerivative'},
          ], datakey: 'companyBusinessList'},
        {group: '', weightKey: false, factor: 'W.C./Revenue', scoreKey: 'workingCapitalDivRevenue', scoreOption: 'scoreList', reasonKey: 'workingCapitalReason'},
        {group: '', weightKey: 'valuationWeight', factor: 'Valuation', scoreKey: 'valuation', scoreOption: 'scoreList', reasonKey: 'valuationReason', required: true},
        {group: '', weightKey: false, factor: '—EV/EBITDA', scoreKey: 'ebitdaScore', scoreOption: 'scoreList', reasonKey: 'ebitdaReason'},
        {group: '', weightKey: false, factor: '—PE', scoreKey: 'peScore', scoreOption: 'scoreList', reasonKey: 'peReason'},
        {group: '', weightKey: false, factor: '—My forecasts vs. Consensus', scoreKey: 'forConScore', scoreOption: 'scoreList', reasonKey: 'forConReason'},
        {group: '', weightKey: false, factor: 'Other financial metrics', scoreKey: 'otherFinScore', scoreOption: false, reasonKey: 'otherFinancialIndex'},
        {group: '', weightKey: false, factor: 'Catalysts', scoreKey: 'catalystsScore', scoreOption: false, reasonKey: 'catalyst'},
        {group: '', weightKey: false, factor: 'Risk', scoreKey: 'riskScore', scoreOption: false, reasonKey: 'risk'},
        {group: '', weightKey: false, factor: 'Key things to follow', scoreKey: 'trackingScore', scoreOption: false, reasonKey: 'tracking'},

        {group: 'Market Sentiments', weightKey: 'mktSentimentsWeight', factor: 'Total Score', scoreKey: 'mktSentimentsScore', scoreOption: 'scoreList', reasonKey: 'mktSentimentsReason', required: true},
        {group: '', weightKey: false, factor: '—Mutual funds\' view', scoreKey: 'publicFundScore', scoreOption: 'scoreList', reasonKey: 'publicFundDesc'},
        {group: '', weightKey: false, factor: '—Hedge funds\' view', scoreKey: 'privateFundScore', scoreOption: 'scoreList', reasonKey: 'privateFundDesc'},
        {group: '', weightKey: false, factor: '—Individual investors\' view', scoreKey: 'privateInvestorScore', scoreOption: 'scoreList', reasonKey: 'privateInvestorDesc'},
        {group: '', weightKey: false, factor: '—Research depth', scoreKey: 'researchDepthScore', scoreOption: 'scoreList', reasonKey: 'researchDepthDesc'},

        {group: 'Chip&flow', weightKey: 'chipFlowWeight', factor: 'Total Score', scoreKey: 'marketView', scoreOption: 'scoreList', reasonKey: 'marketViewDesc', required: true},
        {group: '', weightKey: false, factor: '—Dividends', scoreKey: 'dividendsScore', scoreOption: 'scoreList', reasonKey: 'dividendsDesc'},
        {group: '', weightKey: false, factor: '—Share buybacks', scoreKey: 'shareBuybackScore', scoreOption: 'scoreList', reasonKey: 'shareBuybackDesc'},
        {group: '', weightKey: false, factor: '—Key shareholders action', scoreKey: 'shareholdersScore', scoreOption: 'scoreList', reasonKey: 'shareholdersReason'},

        {group: 'Sum/conclusion', weightKey: 'sumWeight', factor: '', scoreKey: 'sumScore', scoreOption: false, reasonKey: false, readLine: true},
      ],
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    this._getDetailData();
  }

  _getSectorDesc = () => {
    const { data } = this.state;
    if(!data.sectorId) return;
    researchClient.getSectorDesc({id: data.sectorId}).then(resp => {
      const fldlist = [{key: 'sector', name: 'Sector'},
        {key: 'score', name: 'Score'},
        {key: 'beta', name: 'Beta'},  {key: 'busModel', name: 'Business Model'}, {key: 'policy', name: 'Policy'}];
      const fldArr = fldlist.map(r => resp[r.key]?`${r.name}: ${resp[r.key]}`:null).filter(r => r);
      const sectorDesc = !_.isEmpty(fldArr)?_.join(fldArr, ', '):null;
      this.setState({
        sectorDesc
      })
    }).catch(err => console.log(err));
  }

  _getDetailData = () => {
    const { id } = this.props.data;
    researchClient
        .findCompanyScoreDetail({ id })
        .then(resp => {
          this.setState({
            data: resp.data
          }, this._getSectorDesc);
        })
        .catch(err => console.log(err));
  };

  _isReadOnly = () => {
    return true;
  };

  _createCompanyDetailForm = () => {
    const { data, tableConfig, lastVersionData } = this.state;
    const readOnly = this._isReadOnly();
    const tickerLabel = `Ticker${readOnly ? '' : '(BBG ticker + Press [Tab])'}`;
    return (
        <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={data}
        >
          <Row gutter={24}>
            {!readOnly && (
                <>
                  <Col span={24} style={{ marginBottom: '8px', height: '45px' }}>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 21 }}
                        name="ticker"
                        label={tickerLabel}
                    >
                      <div className={'ui form field'}>
                        <div style={{ width: '35%', float: 'left' }}>
                          <span>{data.ticker}</span>
                        </div>
                        <div style={{ width: '64%', float: 'right' }}>
                          {data.ticker}
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                </>
            )}
          </Row>
          <Row gutter={24} className='dataTable'>
            <Col span={2} className='tableHeader'></Col>
            <Col span={2} className='tableHeader'>Weight</Col>
            <Col span={3} className='tableHeader'>Factors</Col>
            <Col span={3} className='tableHeader'>Score</Col>
            <Col span={9} className='tableHeader'>Reason</Col>
            <Col span={3} className='tableHeader'>Previous Score</Col>
            <Col span={2} className='tableHeader tableLast'>AI Score</Col>
          </Row>
          {
            tableConfig.map((r, index) => this._createItem(r, data, lastVersionData, index === tableConfig.length-1))
          }
        </Form>
    );
  };

  _createItem = (item, data, preData, lastRow) => {
    const render = item.render;
    const renderData = item.renderData;
    const detail = data[item.datakey];
    // const preDetail = preData?preData[item.datakey]:[];
    switch(render){
      case 'list':
        return detail.map((e, eindex) => renderData.map((r) => this._createFlds(r, e, {}, false, eindex)));
      default:
        return this._createFlds(item, data, preData, lastRow);
    }
  }

  _createFlds = (item, data, preData, lastRow, segmentIndex) => {
    const weightValue = item.weightKey?data[item.weightKey]:null;
    const required = item.required;
    const scoreValue = item.scoreKey?data[item.scoreKey]:null;
    const reasonValue = data[item.reasonKey];
    const preScoreValue = preData?preData[item.scoreKey]:null;
    const aiScoreValue = data[item.aiScoreKey];
    const readLine = item.readLine;
    return (
        <Row gutter={24} className={`dataTable ${required?'requiredItem':''}`} >
          <Col span={2} className={`tableItem ${lastRow?'lastRow':''}`}>{item.group}</Col>
          <Col span={2} className={`tableItem ${lastRow?'lastRow':''}`}>
            {this._createItemCompent('num', item.weightKey, weightValue, null, segmentIndex, item.min?item.min:1, readLine)}
          </Col>
          <Col span={3} className={`tableItem ${lastRow?'lastRow':''}`}>
            {item.factor?item.factor:this._createItemCompent('text', item.factorKey, data[item.factorKey], null, segmentIndex, null, readLine)}
          </Col>
          <Col span={3} className={`tableItem ${lastRow?'lastRow':''}`}>{this._createItemCompent('select', item.scoreKey, scoreValue, item.scoreOption, segmentIndex, null, readLine)}</Col>
          <Col span={9} className={`tableItem ${lastRow?'lastRow':''}`}>{this._createItemCompent('text', item.reasonKey, reasonValue, null, segmentIndex, null, readLine)}</Col>
          <Col span={3} className={`tableItem ${lastRow?'lastRow':''}`}>{preScoreValue}</Col>
          <Col span={2} className={`tableItem tableLast ${lastRow?'lastRow':''}`}>{aiScoreValue}</Col>
        </Row>
    )
  }

  _createItemCompent = (type, key, value, option, segmentIndex, min, readLine) => {
    return <span className='tableReadItem'>{value}</span>;
  }

  _closeDraw = () => {
    const { closeDrawer } = this.props;
    closeDrawer();
  };

  _createCompanyReview = () => {
    const {
      data,
      defaultSingleQData,
      defaultBottomData,
      defaultFutureTrendData,
      companyBusinessList
    } = this.state;
    if (!data.reviewDetail && !data.review) {
      const nextQuanter = getNextQuarter();
      data.review = {
        quanter: nextQuanter.quarterKey,
        singleQuarterData: defaultSingleQData,
        performanceSplit: [],
        bottomLineData: defaultBottomData,
        futureTrendData: defaultFutureTrendData,
        marketFeedback: [{ id: 1 }],
        investmentConclusion: [{ id: 1 }],
        followUp: [{ id: 1 }]
      };
    } else {
      data.review = data.review ? data.review : JSON.parse(data.reviewDetail);
    }
    return (
        <CompanyReview
            {...this.props}
            companyBusinessList={companyBusinessList}
            onReviewDataChange={this._onReviewDataChange}
            onCompanyScoreDataChange={this.onInputChange}
            readOnly={true}
            getTickerExch={this._getTickerExch}
            defaultTemplate={{
              defaultSingleQData,
              defaultBottomData,
              defaultFutureTrendData
            }}
            data={data}
        />
    );
  };

  _getTickerExch = () => {
    const { ticker } = this.state.data;
    if (!ticker) return '';
    return TickerUtils.parseExch(ticker);
  };

  _createAttachList = () => {
    const { data } = this.state;
    if (_.isEmpty(data)) return;
    return (
        <LinkPannel {...this.props} data={data} readOnly={this._isReadOnly()} />
    );
  };

  render() {
    const { data, sectorDesc } = this.state;
    const readOnly = this._isReadOnly();
    const title = (
        <div>
          <div style={{ float: 'left', width: '40%', textAlign: 'left' }}>
          <span style={{ color: '#FF9E28' }}>
            {data.ticker ? data.ticker : ''}
          </span>
            <span>{data.name ? `(${data.name}${sectorDesc ? `, ${sectorDesc}` : ''})` : ''}</span>
          </div>
          <div style={{ float: 'right', width: '60%', textAlign: 'right' }}>
            <Button
                size="small"
                onClick={this._closeDraw}
                style={{ marginLeft: '10px' }}
            >
              Cancel
            </Button>
          </div>
        </div>
    );
    return (
        <>
          <style>
            {`
                      .planDetailDrawerClass {
                      }

                      .planDetailDrawerClass  .ant-drawer-content{
                        background-color: #2d3436;
                      }

                      .planDetailDrawerClass  .ant-drawer-header{
                        background-color: #2d3436;
                      }

                      .planDetailDrawerClass .ant-drawer-title {
                        color: white;
                      }

                      .planDetailDrawerClass .ant-form-item-label > label{
                        color: #FF9E28;
                        font-size: 12px;
                      }

                      .planDetailDrawerClass .ant-input{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 13px;
                      }

                      .planDetailDrawerClass .ant-input-number-lg input{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 13px;
                        height: 30px;
                      }

                      .planDetailDrawerClass .ant-input-number{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 13px;
                        height: 30px;
                      }

                      .planDetailDrawerClass .ant-input-group-addon{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                      }

                      .planDetailDrawerClass .ant-page-header-heading-title{
                        color: white;
                        font-size: 15px;
                      }

                      .planDetailDrawerClass .ant-page-header{
                        padding: 5px;
                      }

                      .planDetailDrawerClass .anticon{
                        color: white;
                      }

                      .planDetailDrawerClass .ant-divider{
                        margin-top: 0px;
                      }

                      .planDetailDrawerClass .ant-divider-plain.ant-divider-with-text{
                        color: white;
                      }

                      .planDetailDrawerClass .ant-col {
                        height: 48px;
                      }

                      .planDetailDrawerClass .ui.form input[type="text"] {
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                      }

                      .planDetailDrawerClass .ui.input {
                        width: 100%
                      }

                      .planDetailDrawerClass i.icon {
                        color: white;
                      }

                      .planDetailDrawerClass .ant-select-selector{
                        background-color: #2d3436 !important;
                        border: 1px solid #594e4a !important;
                        color: white !important;
                      }

                      .planDetailDrawerClass .companyScoreSelect .ant-select-selector{
                        background-color: #2d3436 !important;
                        border: 0px solid #594e4a !important;
                        color: white !important;
                        height: 28px;
                      }

                      .planDetailDrawerClass .companyConclusionSelect .ant-select-selector{
                        background-color: #2d3436 !important;
                        border: 0px solid #594e4a !important;
                        color: white !important;
                        height: 28px;
                        width: 120px;
                      }
                      .planDetailDrawerClass .derivativeCalss {
                        margin-left: 5px;
                        color: #FF9E28;
                        font-size: 12px;
                      }
                      .planDetailDrawerClass .ant-select-arrow{
                        color: gray !important;
                      }

                      .planDetailDrawerClass .ant-input-number{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 12px;
                        width: 100%;
                      }

                      .lastVersionDataClass{
                        color: green;
                        margin-left: 10px;
                      }

                      .planDetailDrawerClass .ant-collapse-header{
                        border: 0px !important;
                        background-color: #2d3436;
                        color: white !important;
                      }

                      .planDetailDrawerClass .ant-collapse-header .ant-collapse-arrow{
                        color: white !important;
                      }

                      .planDetailDrawerClass .ant-collapse-content-box{
                        border: 0px !important;
                        background-color: #2d3436;
                        color: white !important;
                        padding: 0px;
                      }

                      .planDetailDrawerClass .ant-collapse {
                        background-color: #2d3436;
                        border: 0px solid #d9d9d9 !important;
                        border-bottom: 0;
                        border-radius: 2px;
                      }

                      .planDetailDrawerClass .ant-collapse-item {
                        border-bottom: 0px solid #d9d9d9 !important;
                      }

                      .planDetailDrawerClass .ant-collapse-content {
                        overflow: hidden;
                        border-top: 0px solid #d9d9d9 !important;
                        background-color: #2d3436;
                      }

                       .planDetailDrawerClass .dataTable .tableHeader {
                          font-size: 18px;
                          color: white;
                          border-top: 0.1px solid gray;
                          border-left: 0.1px solid gray;
                          height: 26px;
                      }

                      .planDetailDrawerClass .dataTable .tableItem {
                          font-size: 15px;
                          color: #FF9E28;
                          border-top: 0.1px solid gray;
                          border-left: 0.1px solid gray;
                          height: 26px;
                      }

                      .planDetailDrawerClass .dataTable .tableLast {
                          border-right: 0.1px solid gray;
                      }

                      .planDetailDrawerClass .dataTable .lastRow {
                          border-bottom: 0.1px solid gray;
                      }
                    `}
          </style>
          <Drawer
              width={'100%'}
              title={title}
              placement="right"
              maskClosable={readOnly}
              onClose={this._closeDraw}
              closable={false}
              visible={true}
              className="planDetailDrawerClass"
          >
            {!_.isEmpty(data) && this._createCompanyDetailForm()}
            <Collapse defaultActiveKey={['1', '2']}>
              <Panel header="Attach List" key="1">
                {this._createAttachList()}
              </Panel>
              <Panel header="Review" key="2">
                {this._createCompanyReview()}
              </Panel>
            </Collapse>
          </Drawer>
        </>
    );
  }
}

export default CompanyScoreDetailDrawerOnlyForDetail;
