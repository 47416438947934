import {
  // All Tickers
  LOAD_ALL_TICKERS_SUCCESS,
  LOAD_ALL_TICKERS_FAILURE,
  // Short Interest
  LOAD_SHORT_INTEREST_SUCCESS,
  LOAD_SHORT_INTEREST_FAILURE,
  // Shortable Tickers
  LOAD_SHORTABLE_TICKERS_SUCCESS,
  LOAD_SHORTABLE_TICKERS_FAILURE,
  // axe list Tickers
  LOAD_AXELIST_TICKERS_SUCCESS,
  LOAD_AXELIST_TICKERS_FAILURE,
  // Research Company
  LOAD_COMPANY_RESEARCH_SUCCESS,
  LOAD_COMPANY_RESEARCH_FAILURE,
  COMPANY_RESEARCH_SUBMIT_REQUEST,
  COMPANY_RESEARCH_SUBMIT_SUCCESS,
  COMPANY_RESEARCH_SUBMIT_FAILURE,
  DELETE_COMPANY_RESEARCH_SUCCESS,
  DELETE_COMPANY_RESEARCH_FAILURE,
  COMPANY_RESEARCH_VALIDATION_FAILURE,
  OPEN_COMPANY_RESEARCH_NEW_DIALOG,
  OPEN_COMPANY_RESEARCH_UPDATE_DIALOG,
  COMPANY_RESEARCH_INPUT_CHANGE,

  // Broker Score
  LOAD_BROKER_SCORES_SUCCESS,
  LOAD_BROKER_SCORES_FAILURE,
  DELETE_BROKER_SCORES_SUCCESS,
  DELETE_BROKER_SCORES_FAILURE,
  BROKER_SCORES_SUBMIT_REQUEST,
  BROKER_SCORES_SUBMIT_SUCCESS,
  BROKER_SCORES_SUBMIT_FAILURE,
  OPEN_BROKER_SCORES_NEW_DIALOG,
  OPEN_BROKER_SCORES_UPDATE_DIALOG,
  BROKER_SCORES_INPUT_CHANGE,
  NEW_BROKER_SCORE_ROW_DATA,
  OPEN_BROKER_SCORES_DETAIL_DIALOG,
  OPEN_YEAR_BROKER_SCORES_DETAIL_DIALOG,
  RESET_YEAR_BROKER_SCORES_DETAIL_DIALOG,
  RESET_BROKER_SCORES_DETAIL_DIALOG,
  LOAD_BROKER_SCORE_BY_BROKER_SUCCESS,
  LOAD_BROKER_SCORE_BY_BROKER_FAILURE,
  LOAD_BROKER_SCORE_LIST_SUCCESS,
  LOAD_BROKER_SCORE_LIST_FAILURE,
  SAVE_BROKER_SCORE_SUCCESS,
  SAVE_BROKER_SCORE_FAILURE,
  REMOVE_BROKER_SCORE_SUCCESS,
  REMOVE_BROKER_SCORE_FAILURE,
  LOAD_CORRESPONDENCE_LIST_SUCCESS,
  LOAD_CORRESPONDENCE_LIST_FAILURE,
  INITIATE_WEIGHT_MAP_LOADER_STATUS,
  CHANGE_BROKER_QUERY_PARAMS,
  CHANGE_BROKER_SCORE_MODAL_INPUT,

  // Analyst Compliment
  LOAD_ANALYST_COMPLIMENT_SUCCESS,
  LOAD_ANALYST_COMPLIMENT_FAILURE,
  DELETE_ANALYST_COMPLIMENT_SUCCESS,
  DELETE_ANALYST_COMPLIMENT_FAILURE,
  ANALYST_COMPLIMENT_SUBMIT_REQUEST,
  ANALYST_COMPLIMENT_SUBMIT_SUCCESS,
  ANALYST_COMPLIMENT_SUBMIT_FAILURE,
  OPEN_ANALYST_COMPLIMENT_NEW_DIALOG,
  OPEN_ANALYST_COMPLIMENT_UPDATE_DIALOG,
  ANALYST_COMPLIMENT_INPUT_CHANGE,

  // Broker Names
  LOAD_BROKER_NAMES_SUCCESS,
  LOAD_BROKER_NAMES_FAILURE,
  // Boxed Position
  LOAD_BOXED_POSITION_SUCCESS,
  LOAD_BOXED_POSITION_FAILURE,
  // reset research control
  RESEARCH_CONTROL_RESET,
  // reset broker control
  BROKER_CONTROL_RESET,
  // reset analyst control
  ANALYST_CONTROL_RESET,

  // initiate loader status
  INITIATE_LOADER_STATUS,
  TICKERS_SEARCH_SUCCESS,
  TICKERS_SEARCH_REQUEST,
  LOAD_SHORTABLE_LIST_FAILURE,
  LOAD_SHORTABLE_LIST_SUCCESS,
  LOAD_SHORTABLE_EXCHANGE_LIST_SUCCESS,
  LOAD_SHORTABLE_EXCHANGE_LIST_FAILURE,
  INIT_SHORTABLE_EXCHANGE_STATUS,
  LOAD_ANALYST_LIST_SUCCESS,
  LOAD_ANALYST_LIST_FAILURE,
  SAVE_ANALYST_SUCCESS,
  SAVE_ANALYST_FAILURE,
  DELETE_ANALYST_SUCCESS,
  DELETE_ANALYST_FAILURE,
  CHANGE_ANALYST_QUERY_PARAMS,
  CHANGE_ANALYST_MODAL_INPUT,
  CLOSE_ANALYST_MODAL_WIN,
  GET_BROKER_LIST_SUCCESS,
  GET_BROKER_LIST_ERROR,
  INIT_LOAD_BROKER_LIST_STATUS,
  CHANGE_ANALYST_UPDATE_MODAL_INPUT,
  GET_ANALYST_NAME_LIST_SUCCESS,
  GET_ANALYST_NAME_LIST_ERROR,
  INIT_LOAD_ANALYST_NAME_LIST_STATUS,
  GET_ANALYST_SECTOR_LIST_SUCCESS,
  GET_ANALYST_SECTOR_LIST_ERROR,
  INIT_LOAD_ANALYST_SECTOR_LIST_STATUS,
  GET_ANALYST_DETAIL_SUCCESS,
  GET_ANALYST_DETAIL_ERROR,
  INIT_LOAD_ANALYST_DETAIL_STATUS,

  //monthly review
  LOAD_MONTHLY_BENCHMARK_SUCCESS,
  LOAD_MONTHLY_BENCHMARK_FAILURE,
  LOAD_MONTHLY_MY_PORTFOLIO_SUCCESS,
  LOAD_MONTHLY_MY_PORTFOLIO_FAILURE,
  LOAD_MONTHLY_REVIEW_DETAIL_SUCCESS,
  LOAD_MONTHLY_REVIEW_DETAIL_FAILURE,
  MONTHLY_REVIEW_DETAIL_INPUT_CHANGE,
  LOAD_MONTHLY_REVIEW_LIST_SUCCESS,
  LOAD_MONTHLY_REVIEW_LIST_FAILURE,
  SAVE_MONTHLY_REVIEW_SUCCESS,
  SAVE_MONTHLY_REVIEW_FAILURE,
  SUBMIT_MONTHLY_REVIEW_SUCCESS,
  SUBMIT_MONTHLY_REVIEW_FAILURE,
  REMOVE_MONTHLY_REVIEW_SUCCESS,
  REMOVE_MONTHLY_REVIEW_FAILURE,
  INITIATE_MONTHLY_REVIEW_LOADER_STATUS,
  INITIATE_MONTHLY_REVIEW_DETAIL_DATA,
  CHAGE_MONTHLY_REVIEW_QUERY_PARAMS,
  INITIATE_MONTHLY_REVIEW_LOADER_BENCHMARK_STATUS,
  INITIATE_MONTHLY_REVIEW_LOADER_MYPERFORMACNE_STATUS,
  GET_GROSS_AND_BETA_BY_FUNDANDBOOK_SUCCESS,
  GET_GROSS_AND_BETA_BY_FUNDANDBOOK_FAILURE,
  GET_MANAGER_USER_LIST_SUCCESS,
  GET_MANAGER_USER_LIST_FAILURE,
  SAVE_BROKER_SCORE_RANK_SUCCESS,
  SAVE_BROKER_SCORE_RANK_FAILURE,
  QUERY_BROKER_SCORE_RANK_SUCCESS,
  QUERY_BROKER_SCORE_RANK_FAILURE,
  LOAD_YEAR_BROKER_SCORE_SUCCESS,
  LOAD_YEAR_BROKER_SCORE_FAILURE,
  LOAD_RESEARCH_REPORT_LIST_SUCCESS,
  LOAD_RESEARCH_REPORT_LIST_FAILURE,
  LOAD_RESEARCH_REPORT_DETAIL_SUCCESS,
  LOAD_RESEARCH_REPORT_DETAIL_FAILURE,
  INIT_LOAD_RESEARCH_REPORT_STATUS,
  INIT_LOAD_RESEARCH_REPORT_DETAIL_STATUS,
  CHANGE_RESEARCH_REPORT_DETAIL_PARAMS,
  OPEN_RESEARCH_REPORT_MODAL,
  CLOSE_RESEARCH_REPORT_MODAL,
  SEARCH_TICKER_SUCCESS,
  SEARCH_TICKER_FAILURE,
  SAVE_RESERCH_REPORT_SUCCESS,
  SAVE_RESERCH_REPORT_FAILURE,
  CHAGE_RESEARCH_REPORT_QUERY_PARAMS,
  COPY_RESERCH_REPORT_SUCCESS,
  COPY_RESERCH_REPORT_FAILURE,
  GET_RESERCH_PERSON_LIST_SUCCESS,
  GET_RESERCH_PERSON_LIST_FAILURE,
  GET_RESERCH_GICS_LIST_SUCCESS,
  GET_RESERCH_GICS_LIST_FAILURE,
  DELETE_RESERCH_ATTACH_SUCCESS,
  DELETE_RESERCH_ATTACH_FAILURE,
  RESERCH_REPORT_CELL_CHANGE,
  LOAD_SHORT_INTEREST_PERCENT_SUCCESS,
  LOAD_SHORT_INTEREST_PERCENT_FAILURE,
  GET_BLOCK_LIST_SUCCESS,
  GET_BLOCK_LIST_FAILURE,
  ADD_COMPANY_KEY_DATA_SUCCESS,
  ADD_COMPANY_KEY_DATA_FAILURE,
  UPDATE_COMPANY_KEY_DATA_SUCCESS,
  UPDATE_COMPANY_KEY_DATA_FAILURE,
  GET_COMPANY_KEY_DATA_SUCCESS,
  GET_COMPANY_KEY_DATA_FAILURE,
  ADD_COMPANY_MEETING_RECORD_SUCCESS,
  ADD_COMPANY_MEETING_RECORD_FAILURE,
  UPDATE_COMPANY_MEETING_RECORD_SUCCESS,
  UPDATE_COMPANY_MEETING_RECORD_FAILURE,
  GET_COMPANY_MEETING_RECORD_SUCCESS,
  GET_COMPANY_MEETING_RECORD_FAILURE,
  ADD_COMPANY_REPLAY_SCORE_SUCCESS,
  ADD_COMPANY_REPLAY_SCORE_FAILURE,
  UPDATE_COMPANY_REPLAY_SCORE_SUCCESS,
  UPDATE_COMPANY_REPLAY_SCORE_FAILURE,
  GET_COMPANY_REPLAY_SCORE_SUCCESS,
  GET_COMPANY_REPLAY_SCORE_FAILURE,
  GET_COMPANY_KEY_DETAIL_DATA_SUCCESS,
  GET_COMPANY_KEY_DETAIL_DATA_FAILURE,
  OPEN_COMPANY_KEY_DETAIL_DATA_MODAL,
  CLOSE_COMPANY_KEY_DETAIL_DATA_MODAL,
  GET_COMPANY_MEETING_RECORD_DETAIL_SUCCESS,
  GET_COMPANY_MEETING_RECORD_DETAIL_FAILURE,
  OPEN_COMPANY_MEETING_RECORD_DETAIL_MODAL,
  CLOSE_COMPANY_MEETING_RECORD_DETAIL_MODAL,
  GET_COMPANY_REPLAY_SCORE_DETAIL_SUCCESS,
  GET_COMPANY_REPLAY_SCORE_DETAIL_FAILURE,
  OPEN_COMPANY_REPLAY_SCORE_MODAL,
  CLOSE_COMPANY_REPLAY_SCORE_MODAL,
  FETCH_ALL_EXCELLENTCOMPANY_SUCCESS,
  FETCH_ALL_EXCELLENTCOMPANY_FAILURE,
  //push votes
  OPEN_ADD_UPDATE_PUSH_VOTES_MODAL,
  CLOSE_ADD_UPDATE_PUSH_VOTES_MODAL,
  CHANGE_PUSH_VOTES_MODAL_INPUT,
  LOAD_PUSH_VOTES_DATA_SUCCESS,
  LOAD_PUSH_VOTES_DATA_FAILURE,
  LOAD_PUSH_VOTE_RETURN_DATA_SUCCESS,
  LOAD_PUSH_VOTE_RETURN_DATA_FAILURE,
  SAVE_PUSH_VOTES_DATA_SUCCESS,
  SAVE_PUSH_VOTES_DATA_FAILURE,
  OPEN_ADD_PUSH_VOTE_NOTE_MODAL,
  CHANGE_PUSH_VOTE_NOTE_MODAL_INPUT,
  CLOSE_ADD_PUSH_VOTE_NOTE_MODAL,
  GET_PUSH_VOTES_SUBSCRIPT_CONFIG_SUCCESS,
  GET_PUSH_VOTES_SUBSCRIPT_CONFIG_FAILURE,
  GET_PUSH_VOTES_PM_USER_LIST_SUCCESS,
  GET_PUSH_VOTES_PM_USER_LIST_FAILURE,
  GET_PUSH_VOTES_ANALYST_USER_LIST_SUCCESS,
  GET_PUSH_VOTES_ANALYST_USER_LIST_FAILURE,
  CHANGE_PUSH_VOTES_SELECT_ITEM,
  ADJUST_PUSH_VOTES_DATA_SUCCESS,
  ADJUST_PUSH_VOTES_DATA_FAILURE,
  INIT_PUSH_VOTES_STATUS,
  SET_PUSH_VOTES_RECORD_READED,

  //portfolio plan
  OPEN_ADD_UPDATE_PORTFOLIO_PLAN_MODAL,
  CLOSE_ADD_UPDATE_PORTFOLIO_PLAN_MODAL,
  LOAD_PUSH_VOTES_DETAIL_SUCCESS,
  LOAD_PUSH_VOTES_DETAIL_FAILURE
} from './researchConstants';

import client from './api/client';
import orderClient from '../order/api/client';
import moment from 'moment';

// All Tickers
function loadAllTickersSuccess(allTickers) {
  return {
    type: LOAD_ALL_TICKERS_SUCCESS,
    payload: allTickers
  };
}

function loadAllTickersFailure(err) {
  return {
    type: LOAD_ALL_TICKERS_FAILURE,
    payload: err
  };
}

export function loadAllTickers() {
  return function(dispatch) {
    client
      .loadAllTickers()
      .then(resp => dispatch(loadAllTickersSuccess(resp)))
      .catch(err => dispatch(loadAllTickersFailure(err)));
  };
}

// Short Interest
function loadShortInterestSuccess(shortInterest) {
  return {
    type: LOAD_SHORT_INTEREST_SUCCESS,
    payload: shortInterest
  };
}

function loadShortInterestFailure(err) {
  return {
    type: LOAD_SHORT_INTEREST_FAILURE,
    payload: err
  };
}

export function loadShortInterest(payload) {
  return function(dispatch) {
    client
      .loadShortInterest(payload.startDate, payload.endDate, payload.ticker)
      .then(resp => dispatch(loadShortInterestSuccess(resp)))
      .catch(err => dispatch(loadShortInterestFailure(err)));
  };
}

// axe List Tickers
function loadAxeListTickersSuccess(axeListTickers) {
  return {
    type: LOAD_AXELIST_TICKERS_SUCCESS,
    payload: axeListTickers
  };
}

function loadAxeListTickersFailure(err) {
  return {
    type: LOAD_AXELIST_TICKERS_FAILURE,
    payload: err
  };
}

export function loadAxeListTickers(date, ticker) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .loadAxeListTickers(date, ticker)
      .then(resp => dispatch(loadAxeListTickersSuccess(resp)))
      .catch(err => dispatch(loadAxeListTickersFailure(err)));
  };
}

// Shortable Tickers
function loadShortableTickersSuccess(shortableTickers) {
  return {
    type: LOAD_SHORTABLE_TICKERS_SUCCESS,
    payload: shortableTickers
  };
}

function loadShortableTickersFailure(err) {
  return {
    type: LOAD_SHORTABLE_TICKERS_FAILURE,
    payload: err
  };
}

export function loadShortableTickers(date) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .loadShortableTickers(date)
      .then(resp => dispatch(loadShortableTickersSuccess(resp)))
      .catch(err => dispatch(loadShortableTickersFailure(err)));
  };
}

// 获取Company Research 数据
function loadCompanyResearchSuccess(companyResearch) {
  return {
    type: LOAD_COMPANY_RESEARCH_SUCCESS,
    payload: companyResearch
  };
}

function loadCompanyResearchFailure(err) {
  return {
    type: LOAD_COMPANY_RESEARCH_FAILURE,
    payload: err
  };
}

export function loadCompanyResearch(date) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .loadCompanyResearch(date)
      .then(resp => dispatch(loadCompanyResearchSuccess(resp)))
      .catch(err => dispatch(loadCompanyResearchFailure(err)));
  };
}

export function openCompanyResearchNewDialog() {
  return {
    type: OPEN_COMPANY_RESEARCH_NEW_DIALOG
  };
}

export function openCompanyResearchUpdateDialog(payload) {
  return {
    type: OPEN_COMPANY_RESEARCH_UPDATE_DIALOG,
    payload: payload
  };
}

// 编辑/新增 Company Research
export function submitCompanyResearchRequest() {
  return {
    type: COMPANY_RESEARCH_SUBMIT_REQUEST
  };
}

export function submitCompanyResearchSuccess(companyResearch) {
  return {
    type: COMPANY_RESEARCH_SUBMIT_SUCCESS,
    payload: companyResearch
  };
}

export function submitCompanyResearchFailure(err) {
  return {
    type: COMPANY_RESEARCH_SUBMIT_FAILURE,
    payload: err
  };
}

export function submitCompanyResearch(companyResearch) {
  return function(dispatch) {
    dispatch(submitCompanyResearchRequest());
    client
      .submitCompanyResearch(companyResearch)
      .then(resp => {
        dispatch(submitCompanyResearchSuccess(resp));
        dispatch(loadCompanyResearch(moment().format('YYYY-MM-DD')));
      })
      .catch(err => dispatch(submitCompanyResearchFailure(err)));
  };
}

// 删除Company Research
export function deleteCompanyResearch(companyResearchId) {
  return function(dispatch) {
    client
      .deleteCompanyResearch(companyResearchId)
      .then(resp => dispatch(deleteCompanyResearchSuccess(companyResearchId)))
      .catch(err => dispatch(deleteCompanyResearchFailure(err)));
  };
}

export function deleteCompanyResearchSuccess(companyResearchId) {
  return {
    type: DELETE_COMPANY_RESEARCH_SUCCESS,
    payload: companyResearchId
  };
}

export function deleteCompanyResearchFailure(err) {
  return {
    type: DELETE_COMPANY_RESEARCH_FAILURE,
    payload: err
  };
}

// 获取Broker Score 数据
function loadBrokerScoresSuccess(brokerScores) {
  return {
    type: LOAD_BROKER_SCORES_SUCCESS,
    payload: brokerScores
  };
}

function loadBrokerScoresFailure(err) {
  return {
    type: LOAD_BROKER_SCORES_FAILURE,
    payload: err
  };
}

export function loadBrokerScores(date) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .loadBrokerScores(date)
      .then(resp => dispatch(loadBrokerScoresSuccess(resp)))
      .catch(err => dispatch(loadBrokerScoresFailure(err)));
  };
}

export function openBrokerScoresNewDialog() {
  return {
    type: OPEN_BROKER_SCORES_NEW_DIALOG
  };
}

export function openBrokerScoresUpdateDialog(payload) {
  return {
    type: OPEN_BROKER_SCORES_UPDATE_DIALOG,
    payload: payload
  };
}

// 编辑/新增 Broker Score
export function submitBrokerScoreRequest() {
  return {
    type: BROKER_SCORES_SUBMIT_REQUEST
  };
}

export function submitBrokerScoreSuccess(resp) {
  return {
    type: BROKER_SCORES_SUBMIT_SUCCESS,
    payload: resp
  };
}

export function submitBrokerScoreFailure(err) {
  return {
    type: BROKER_SCORES_SUBMIT_FAILURE,
    payload: err
  };
}

export function submitBrokerScore(brokerScore) {
  return function(dispatch) {
    dispatch(submitBrokerScoreRequest());
    client
      .submitBrokerScore(brokerScore)
      .then(resp => {
        dispatch(submitBrokerScoreSuccess(resp));
        dispatch(loadBrokerScores(moment().format('YYYY-MM-DD')));
      })
      .catch(err => dispatch(submitBrokerScoreFailure(err)));
  };
}

// 删除 Broker Score
export function deleteBrokerScore(brokerScoreId) {
  return function(dispatch) {
    client
      .deleteBrokerScore(brokerScoreId)
      .then(resp => dispatch(deleteBrokerScoreSuccess(brokerScoreId)))
      .catch(err => dispatch(deleteBrokerScoreFailure(err)));
  };
}

export function deleteBrokerScoreSuccess(brokerScoreId) {
  return {
    type: DELETE_BROKER_SCORES_SUCCESS,
    payload: brokerScoreId
  };
}

export function deleteBrokerScoreFailure(err) {
  return {
    type: DELETE_BROKER_SCORES_FAILURE,
    payload: err
  };
}

export function newBrokerScoreRowData(brokerRowData) {
  return {
    type: NEW_BROKER_SCORE_ROW_DATA,
    payload: brokerRowData
  };
}

// 获取Analyst Compliment 数据
function loadAnalystComplimentSuccess(analystCompliment) {
  return {
    type: LOAD_ANALYST_COMPLIMENT_SUCCESS,
    payload: analystCompliment
  };
}

function loadAnalystComplimentFailure(err) {
  return {
    type: LOAD_ANALYST_COMPLIMENT_FAILURE,
    payload: err
  };
}

export function loadAnalystCompliment(date) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .loadAnalystCompliment(date)
      .then(resp => dispatch(loadAnalystComplimentSuccess(resp)))
      .catch(err => dispatch(loadAnalystComplimentFailure(err)));
  };
}

export function openAnalystComplimentNewDialog() {
  return {
    type: OPEN_ANALYST_COMPLIMENT_NEW_DIALOG
  };
}

export function openAnalystComplimentUpdateDialog(payload) {
  return {
    type: OPEN_ANALYST_COMPLIMENT_UPDATE_DIALOG,
    payload: payload
  };
}

// 编辑/新增 Analyst Compliment
export function submitAnalystComplimentRequest() {
  return {
    type: ANALYST_COMPLIMENT_SUBMIT_REQUEST
  };
}

export function submitAnalystComplimentSuccess(resp) {
  return {
    type: ANALYST_COMPLIMENT_SUBMIT_SUCCESS,
    payload: resp
  };
}

export function submitAnalystComplimentFailure(err) {
  return {
    type: ANALYST_COMPLIMENT_SUBMIT_FAILURE,
    payload: err
  };
}

export function submitAnalystCompliment(analystCompliment) {
  return function(dispatch) {
    dispatch(submitAnalystComplimentRequest());
    client
      .submitAnalystCompliment(analystCompliment)
      .then(resp => {
        dispatch(submitAnalystComplimentSuccess(resp));
        dispatch(loadAnalystCompliment(moment().format('YYYY-MM-DD')));
      })
      .catch(err => dispatch(submitAnalystComplimentFailure(err)));
  };
}

// 删除 Analyst Compliment
export function deleteAnalystCompliment(analystComplimentId) {
  return function(dispatch) {
    client
      .deleteAnalystCompliment(analystComplimentId)
      .then(resp =>
        dispatch(deleteAnalystComplimentSuccess(analystComplimentId))
      )
      .catch(err => dispatch(deleteAnalystComplimentFailure(err)));
  };
}

export function deleteAnalystComplimentSuccess(analystComplimentId) {
  return {
    type: DELETE_ANALYST_COMPLIMENT_SUCCESS,
    payload: analystComplimentId
  };
}

export function deleteAnalystComplimentFailure(err) {
  return {
    type: DELETE_ANALYST_COMPLIMENT_FAILURE,
    payload: err
  };
}

// Broker Names
function loadBrokerNamesSuccess(brokerNames) {
  return {
    type: LOAD_BROKER_NAMES_SUCCESS,
    payload: brokerNames
  };
}

function loadBrokerNamesFailure(err) {
  return {
    type: LOAD_BROKER_NAMES_FAILURE,
    payload: err
  };
}

export function loadBrokerNames() {
  return function(dispatch) {
    client
      .loadBrokerNames()
      .then(resp => dispatch(loadBrokerNamesSuccess(resp)))
      .catch(err => dispatch(loadBrokerNamesFailure(err)));
  };
}

// Boxed Position
function loadBoxedPositionSuccess(brokerNames) {
  return {
    type: LOAD_BOXED_POSITION_SUCCESS,
    payload: brokerNames
  };
}

function loadBoxedPositionFailure(err) {
  return {
    type: LOAD_BOXED_POSITION_FAILURE,
    payload: err
  };
}

export function loadBoxedPosition() {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .loadBoxedPosition()
      .then(resp => dispatch(loadBoxedPositionSuccess(resp)))
      .catch(err => dispatch(loadBoxedPositionFailure(err)));
  };
}

// reset research control
export function resetResearchControlModal() {
  return {
    type: RESEARCH_CONTROL_RESET
  };
}

export function companyResearchChangeInput({ name, value, error }) {
  return {
    type: COMPANY_RESEARCH_INPUT_CHANGE,
    payload: {
      name,
      value,
      error
    }
  };
}

export function brokerScoreChangeInput({ name, value, error }) {
  return {
    type: BROKER_SCORES_INPUT_CHANGE,
    payload: {
      name,
      value,
      error
    }
  };
}

export function analystComplimentChangeInput({ name, value, error }) {
  return {
    type: ANALYST_COMPLIMENT_INPUT_CHANGE,
    payload: {
      name,
      value,
      error
    }
  };
}

// 编辑/新增 Company Research 字段校验错误
export function sendValidationFailure(fieldErrors) {
  return {
    type: COMPANY_RESEARCH_VALIDATION_FAILURE,
    payload: fieldErrors
  };
}

// reset research control
export function resetBrokerControlModal() {
  return {
    type: BROKER_CONTROL_RESET
  };
}

// reset analyst control
export function resetAnalystControlModal() {
  return {
    type: ANALYST_CONTROL_RESET
  };
}

// initiate loader status
export function initiateLoaderStatus() {
  return {
    type: INITIATE_LOADER_STATUS
  };
}

export function searchTickersRequest() {
  return {
    type: TICKERS_SEARCH_REQUEST
  };
}

export function searchTickersSuccess(tickers) {
  return {
    type: TICKERS_SEARCH_SUCCESS,
    payload: tickers
  };
}

export function searchTickers(term) {
  return function(dispatch) {
    dispatch(searchTickersRequest());
    orderClient
      .searchTickers(term)
      .then(resp =>
        dispatch(
          searchTickersSuccess(resp.tickers.map(t => ({ key: t, ticker: t })))
        )
      )
      .catch(_ => dispatch(searchTickersSuccess([])));
  };
}

/**
 * Broker
 */
export function openBrokerScoresDetailDialog(votingMonth) {
  return {
    type: OPEN_BROKER_SCORES_DETAIL_DIALOG,
    payload: votingMonth
  };
}

// reset research control
export function resetBrokerDetailControlModal() {
  return {
    type: RESET_BROKER_SCORES_DETAIL_DIALOG
  };
}

export function openYearBrokerScoresDetailDialog() {
  return {
    type: OPEN_YEAR_BROKER_SCORES_DETAIL_DIALOG
  };
}

export function resetYearBrokerScoresDetailDialog() {
  return {
    type: RESET_YEAR_BROKER_SCORES_DETAIL_DIALOG
  };
}

function loadBrokerScoreListByScoreSuccess(result) {
  return {
    type: LOAD_BROKER_SCORE_BY_BROKER_SUCCESS,
    payload: result
  };
}

function loadBrokerScoreListByScoreFailure(err) {
  return {
    type: LOAD_BROKER_SCORE_BY_BROKER_FAILURE,
    payload: err
  };
}

export function loadBrokerScoreListByScore(queryParams) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .loadBrokerScoreListByScore(queryParams)
      .then(resp => dispatch(loadBrokerScoreListByScoreSuccess(resp)))
      .catch(err => dispatch(loadBrokerScoreListByScoreFailure(err)));
  };
}

function loadBrokerScoreListSuccess(result) {
  return {
    type: LOAD_BROKER_SCORE_LIST_SUCCESS,
    payload: result
  };
}

function loadBrokerScoreListFailure(err) {
  return {
    type: LOAD_BROKER_SCORE_LIST_FAILURE,
    payload: err
  };
}

export function loadBrokerScoreList(queryParams) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .loadBrokerScoreList(queryParams)
      .then(resp => dispatch(loadBrokerScoreListSuccess(resp)))
      .catch(err => dispatch(loadBrokerScoreListFailure(err)));
  };
}

function saveBrokerScoreSuccess(result) {
  return {
    type: SAVE_BROKER_SCORE_SUCCESS,
    payload: result
  };
}

function saveBrokerScoreFailure(err) {
  return {
    type: SAVE_BROKER_SCORE_FAILURE,
    payload: err
  };
}

export function saveBrokerScore(params, queryParams) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .saveBrokerScore(params)
      .then(resp => {
        dispatch(saveBrokerScoreSuccess(resp));
        dispatch(loadBrokerScoreList(queryParams));
      })
      .catch(err => dispatch(saveBrokerScoreFailure(err)));
  };
}

function removeBrokerScoreSuccess(result) {
  return {
    type: REMOVE_BROKER_SCORE_SUCCESS,
    payload: result
  };
}

function removeBrokerScoreFailure(err) {
  return {
    type: REMOVE_BROKER_SCORE_FAILURE,
    payload: err
  };
}

export function removeBrokerScore(params, queryParams) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .removeBrokerScore(params)
      .then(resp => {
        dispatch(removeBrokerScoreSuccess(resp));
        dispatch(loadBrokerScoreList(queryParams));
      })
      .catch(err => dispatch(removeBrokerScoreFailure(err)));
  };
}

function loadCorrespondenceListSuccess(result) {
  return {
    type: LOAD_CORRESPONDENCE_LIST_SUCCESS,
    payload: result
  };
}

function loadCorrespondenceListFailure(err) {
  return {
    type: LOAD_CORRESPONDENCE_LIST_FAILURE,
    payload: err
  };
}

export function loadCorrespondenceList(params) {
  return function(dispatch) {
    dispatch(initiateWeightMapLoaded());
    client
      .loadCorrespondenceList(params)
      .then(resp => dispatch(loadCorrespondenceListSuccess(resp)))
      .catch(err => dispatch(loadCorrespondenceListFailure(err)));
  };
}

export function initiateWeightMapLoaded() {
  return {
    type: INITIATE_WEIGHT_MAP_LOADER_STATUS
  };
}

export function changeBrokerQureyParams(input) {
  return {
    type: CHANGE_BROKER_QUERY_PARAMS,
    payload: input
  };
}

export function changeBrokerScoreControlModalInput(input) {
  return {
    type: CHANGE_BROKER_SCORE_MODAL_INPUT,
    payload: input
  };
}

function loadShortableListSuccess(result) {
  return {
    type: LOAD_SHORTABLE_LIST_SUCCESS,
    payload: result
  };
}

function loadShortableListFailure(err) {
  return {
    type: LOAD_SHORTABLE_LIST_FAILURE,
    payload: err
  };
}

export function loadShortableList(params) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .loadShortableList(params)
      .then(resp => dispatch(loadShortableListSuccess(resp)))
      .catch(err => dispatch(loadShortableListFailure(err)));
  };
}

function loadShortExchangeListSuccess(result) {
  return {
    type: LOAD_SHORTABLE_EXCHANGE_LIST_SUCCESS,
    payload: result
  };
}

function loadShortExchangeListFailure(err) {
  return {
    type: LOAD_SHORTABLE_EXCHANGE_LIST_FAILURE,
    payload: err
  };
}

export function loadShortExchangeList() {
  return function(dispatch) {
    dispatch(initiateLoaderShortExchangeStatus());
    client
      .loadShortExchangeList()
      .then(resp => dispatch(loadShortExchangeListSuccess(resp)))
      .catch(err => dispatch(loadShortExchangeListFailure(err)));
  };
}

export function initiateLoaderShortExchangeStatus() {
  return {
    type: INIT_SHORTABLE_EXCHANGE_STATUS
  };
}

//analyst
function loadAnalystListSuccess(result) {
  return {
    type: LOAD_ANALYST_LIST_SUCCESS,
    payload: result
  };
}

function loadAnalystListFailure(err) {
  return {
    type: LOAD_ANALYST_LIST_FAILURE,
    payload: err
  };
}

export function loadAnalystList(queryParams) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .loadAnalystList(queryParams)
      .then(resp => dispatch(loadAnalystListSuccess(resp)))
      .catch(err => dispatch(loadAnalystListFailure(err)));
  };
}

//analyst
function saveAnalystSuccess(result) {
  return {
    type: SAVE_ANALYST_SUCCESS,
    payload: result
  };
}

function saveAnalystFailure(err) {
  return {
    type: SAVE_ANALYST_FAILURE,
    payload: err
  };
}

export function saveAnalyst(params, queryParams) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .saveAnalyst(params)
      .then(resp => {
        dispatch(saveAnalystSuccess(resp));
        dispatch(changeAnalystModalInput({ name: 'isOpened', value: false }));
        dispatch(changeAnalystModalInput({ name: 'data', value: [{}] }));
        dispatch(
          changeAnalystUpdateModalInput({ name: 'isOpened', value: false })
        );
        dispatch(loadAnalystList(queryParams));
      })
      .catch(err => dispatch(saveAnalystFailure(err)));
  };
}

function deleteAnalystSuccess(result) {
  return {
    type: DELETE_ANALYST_SUCCESS,
    payload: result
  };
}

function deleteAnalystFailure(err) {
  return {
    type: DELETE_ANALYST_FAILURE,
    payload: err
  };
}

export function deleteAnalyst(params, queryParams) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .deleteAnalyst(params)
      .then(resp => {
        dispatch(deleteAnalystSuccess(resp));
        dispatch(loadAnalystList(queryParams));
      })
      .catch(err => dispatch(deleteAnalystFailure(err)));
  };
}

export function changeAnalystQueryParams(input) {
  return {
    type: CHANGE_ANALYST_QUERY_PARAMS,
    payload: input
  };
}

export function changeAnalystModalInput(input) {
  return {
    type: CHANGE_ANALYST_MODAL_INPUT,
    payload: input
  };
}

export function closeAnalystModal() {
  return {
    type: CLOSE_ANALYST_MODAL_WIN
  };
}

export function getBrokerList(params) {
  return function(dispatch) {
    dispatch(initiateLoaderBrokerStatus());
    client
      .getBrokerList(params)
      .then(resp => dispatch(getBrokerListSuccess(resp)))
      .catch(err => dispatch(getBrokerListFailure(err)));
  };
}

function getBrokerListSuccess(result) {
  return {
    type: GET_BROKER_LIST_SUCCESS,
    payload: result
  };
}

function getBrokerListFailure(err) {
  return {
    type: GET_BROKER_LIST_ERROR,
    payload: err
  };
}

export function initiateLoaderBrokerStatus() {
  return {
    type: INIT_LOAD_BROKER_LIST_STATUS
  };
}

export function changeAnalystUpdateModalInput(input) {
  return {
    type: CHANGE_ANALYST_UPDATE_MODAL_INPUT,
    payload: input
  };
}

export function getAnalystNameEditList(params) {
  return function(dispatch) {
    dispatch(initiateLoaderAnalystNameListStatus());
    client
      .getAnalystNameEditList(params)
      .then(resp => dispatch(getAnalystNameEditListSuccess(resp)))
      .catch(err => dispatch(getAnalystNameEditListFailure(err)));
  };
}

function getAnalystNameEditListSuccess(result) {
  return {
    type: GET_ANALYST_NAME_LIST_SUCCESS,
    payload: result
  };
}

function getAnalystNameEditListFailure(err) {
  return {
    type: GET_ANALYST_NAME_LIST_ERROR,
    payload: err
  };
}

export function initiateLoaderAnalystNameListStatus() {
  return {
    type: INIT_LOAD_ANALYST_NAME_LIST_STATUS
  };
}

export function getAnalystSectorList(params) {
  return function(dispatch) {
    dispatch(initiateLoaderAnalystSectorListStatus());
    client
      .getAnalystSectorNameList(params)
      .then(resp => dispatch(getAnalystSectorListSuccess(resp)))
      .catch(err => dispatch(getAnalystSectorListFailure(err)));
  };
}

function getAnalystSectorListSuccess(result) {
  return {
    type: GET_ANALYST_SECTOR_LIST_SUCCESS,
    payload: result
  };
}

function getAnalystSectorListFailure(err) {
  return {
    type: GET_ANALYST_SECTOR_LIST_ERROR,
    payload: err
  };
}

export function initiateLoaderAnalystSectorListStatus() {
  return {
    type: INIT_LOAD_ANALYST_SECTOR_LIST_STATUS
  };
}

export function getAnalystDetailList(params) {
  return function(dispatch) {
    dispatch(initiateLoaderAnalystDetailListStatus());
    client
      .getAnalystDetailList(params)
      .then(resp => dispatch(getAnalystDetailListSuccess(resp)))
      .catch(err => dispatch(getAnalystDetailListFailure(err)));
  };
}

function getAnalystDetailListSuccess(result) {
  return {
    type: GET_ANALYST_DETAIL_SUCCESS,
    payload: result
  };
}

function getAnalystDetailListFailure(err) {
  return {
    type: GET_ANALYST_DETAIL_ERROR,
    payload: err
  };
}

export function initiateLoaderAnalystDetailListStatus() {
  return {
    type: INIT_LOAD_ANALYST_DETAIL_STATUS
  };
}

// get monthly review benchmark performance data
function loadMonthlyReviewBenchmarkSuccess(benchmarkMarkData, updateMode) {
  benchmarkMarkData['updateMode'] = updateMode;
  return {
    type: LOAD_MONTHLY_BENCHMARK_SUCCESS,
    payload: benchmarkMarkData
  };
}

function loadMonthlyReviewBenchmarkFailure(err) {
  return {
    type: LOAD_MONTHLY_BENCHMARK_FAILURE,
    payload: err
  };
}

export function loadMonthlyReviewBenchmark(queryParams, updateMode) {
  return function(dispatch) {
    dispatch(initMonthlyReviewBenchmarkStatus());
    client
      .loadMonthlyReviewBenchmark(queryParams)
      .then(resp =>
        dispatch(loadMonthlyReviewBenchmarkSuccess(resp, updateMode))
      )
      .catch(err => dispatch(loadMonthlyReviewBenchmarkFailure(err)));
  };
}

// get monthly review my portfilio data
function loadMonthlyReviewMyPortfilioSuccess(data, updateMode) {
  data['updateMode'] = updateMode;
  return {
    type: LOAD_MONTHLY_MY_PORTFOLIO_SUCCESS,
    payload: data
  };
}

function loadMonthlyReviewMyPortfilioFailure(err) {
  return {
    type: LOAD_MONTHLY_MY_PORTFOLIO_FAILURE,
    payload: err
  };
}

export function loadMonthlyReviewMyPortfilio(queryParams, updateMode) {
  return function(dispatch) {
    dispatch(initMonthlyReviewMyPerformanceStatus());
    client
      .loadMonthlyReviewMyPortfolio(queryParams)
      .then(resp =>
        dispatch(loadMonthlyReviewMyPortfilioSuccess(resp, updateMode))
      )
      .catch(err => dispatch(loadMonthlyReviewMyPortfilioFailure(err)));
  };
}

//get monthly review detail info
function loadMonthlyReviewDetailSuccess(data, updateMode) {
  data['updateMode'] = updateMode;
  return {
    type: LOAD_MONTHLY_REVIEW_DETAIL_SUCCESS,
    payload: data
  };
}

function loadMonthlyReviewDetailFailure(err) {
  return {
    type: LOAD_MONTHLY_REVIEW_DETAIL_FAILURE,
    payload: err
  };
}

export function loadMonthlyReviewDetail(id, updateMode) {
  return function(dispatch) {
    dispatch(initiateMonthlyReviewDetailLoaderStatus());
    client
      .loadMonthlyReviewDetail(id)
      .then(resp => {
        const queryParams = {
          yearMonth: resp.data['yearMonth'],
          year: resp.data['year'],
          monthlyReviewId: resp.data['id'],
          bookCodeList: [resp.data['bookCode']]
        };
        dispatch(loadMonthlyReviewMyPortfilio(queryParams, updateMode));
        dispatch(loadMonthlyReviewBenchmark(queryParams, updateMode));
        dispatch(loadMonthlyReviewDetailSuccess(resp, updateMode));
        if (updateMode) {
          dispatch(
            monthlyReviewDetailChangeInput({ name: 'modalShow', value: true })
          );
        }
      })
      .catch(err => dispatch(loadMonthlyReviewDetailFailure(err)));
  };
}

export function initiateMonthlyReviewDetailLoaderStatus() {
  return {
    type: INITIATE_MONTHLY_REVIEW_LOADER_STATUS
  };
}

export function monthlyReviewDetailChangeInput({
  name,
  value,
  notDirty,
  error
}) {
  return {
    type: MONTHLY_REVIEW_DETAIL_INPUT_CHANGE,
    payload: {
      name,
      value,
      notDirty,
      error
    }
  };
}

export function monthlyReviewDetailDataInit() {
  return {
    type: INITIATE_MONTHLY_REVIEW_DETAIL_DATA
  };
}

//get monthly review list
function loadMonthlyReviewListSuccess(data) {
  return {
    type: LOAD_MONTHLY_REVIEW_LIST_SUCCESS,
    payload: data
  };
}

function loadMonthlyReviewListFailure(err) {
  return {
    type: LOAD_MONTHLY_REVIEW_LIST_FAILURE,
    payload: err
  };
}

export function loadMonthlyReviewList(queryParams) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .loadMonthlyReviewList(queryParams)
      .then(resp => dispatch(loadMonthlyReviewListSuccess(resp)))
      .catch(err => dispatch(loadMonthlyReviewListFailure(err)));
  };
}

//save monthly review
function saveMonthlyReviewSuccess(data) {
  return {
    type: SAVE_MONTHLY_REVIEW_SUCCESS,
    payload: data
  };
}

function saveMonthlyReviewFailure(err) {
  return {
    type: SAVE_MONTHLY_REVIEW_FAILURE,
    payload: err
  };
}

export function saveMonthlyReview(params, queryParams) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .saveMonthlyReview(params)
      .then(resp => {
        dispatch(saveMonthlyReviewSuccess(resp));
        dispatch(loadMonthlyReviewDetail(resp.data));
        dispatch(loadMonthlyReviewList(queryParams));
      })
      .catch(err => dispatch(saveMonthlyReviewFailure(err)));
  };
}

//submit monthly review
function submitMonthlyReviewSuccess(data) {
  return {
    type: SUBMIT_MONTHLY_REVIEW_SUCCESS,
    payload: data
  };
}

function submitMonthlyReviewFailure(err) {
  return {
    type: SUBMIT_MONTHLY_REVIEW_FAILURE,
    payload: err
  };
}

export function submitMonthlyReview(params, queryParams) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .submitMonthlyReview(params)
      .then(resp => {
        if (resp && resp.code !== '0') {
          const error = { message: resp.message };
          dispatch(submitMonthlyReviewFailure(error));
        } else {
          dispatch(submitMonthlyReviewSuccess(resp));
          dispatch(loadMonthlyReviewDetail(resp.data));
          dispatch(loadMonthlyReviewList(queryParams));
          dispatch(
            monthlyReviewDetailChangeInput({ name: 'modalShow', value: false })
          );
        }
      })
      .catch(err => {
        dispatch(submitMonthlyReviewFailure(err));
      });
  };
}

//remove monthly review
function removeMonthlyReviewSuccess(data) {
  return {
    type: REMOVE_MONTHLY_REVIEW_SUCCESS,
    payload: data
  };
}

function removeMonthlyReviewFailure(err) {
  return {
    type: REMOVE_MONTHLY_REVIEW_FAILURE,
    payload: err
  };
}

export function removeMonthlyReview(params, queryParams) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .removeMonthlyReview(params)
      .then(resp => {
        dispatch(removeMonthlyReviewSuccess(resp));
        dispatch(loadMonthlyReviewList(queryParams));
      })
      .catch(err => dispatch(removeMonthlyReviewFailure(err)));
  };
}

export function changeMonthlyReviewQueryParams({ name, value }) {
  return {
    type: CHAGE_MONTHLY_REVIEW_QUERY_PARAMS,
    payload: {
      name,
      value
    }
  };
}

export function initMonthlyReviewBenchmarkStatus() {
  return {
    type: INITIATE_MONTHLY_REVIEW_LOADER_BENCHMARK_STATUS
  };
}

export function initMonthlyReviewMyPerformanceStatus() {
  return {
    type: INITIATE_MONTHLY_REVIEW_LOADER_MYPERFORMACNE_STATUS
  };
}

function getGrossAndBetaByFundAndBookSuccess(data) {
  return {
    type: GET_GROSS_AND_BETA_BY_FUNDANDBOOK_SUCCESS,
    payload: data
  };
}

function getGrossAndBetaByFundAndBookFailure(err) {
  return {
    type: GET_GROSS_AND_BETA_BY_FUNDANDBOOK_FAILURE,
    payload: err
  };
}

export function getGrossAndBetaByFundAndBook(params) {
  return function(dispatch) {
    const readOnlyPage = params['readOnlyPage'];
    client
      .getGrossAndBetaByFundAndBook(params)
      .then(resp => {
        resp['readOnlyPage'] = readOnlyPage;
        dispatch(getGrossAndBetaByFundAndBookSuccess(resp));
      })
      .catch(err => dispatch(getGrossAndBetaByFundAndBookFailure(err)));
  };
}

export function getManagerUserList() {
  return function(dispatch) {
    client
      .getManagerUserList()
      .then(resp => dispatch(getManagerUserListSuccess(resp)))
      .catch(err => dispatch(getManagerUserListFailure(err)));
  };
}

function getManagerUserListSuccess(data) {
  return {
    type: GET_MANAGER_USER_LIST_SUCCESS,
    payload: data
  };
}

function getManagerUserListFailure(err) {
  return {
    type: GET_MANAGER_USER_LIST_FAILURE,
    payload: err
  };
}

export function saveBrokerScoreRankList(params) {
  return function(dispatch) {
    client
      .saveBrokerScoreRankList(params)
      .then(resp => {
        dispatch(saveBrokerScoreRankListSuccess(resp));
        dispatch(loadYearBrokerScores(params));
      })
      .catch(err => dispatch(saveBrokerScoreRankListFailure(err)));
  };
}

function saveBrokerScoreRankListSuccess(data) {
  return {
    type: SAVE_BROKER_SCORE_RANK_SUCCESS,
    payload: data
  };
}

function saveBrokerScoreRankListFailure(err) {
  return {
    type: SAVE_BROKER_SCORE_RANK_FAILURE,
    payload: err
  };
}

export function queryBrokerScoreRankListByScore(params) {
  return function(dispatch) {
    client
      .queryBrokerScoreRankListByScore(params)
      .then(resp => dispatch(queryBrokerScoreRankListByScoreSuccess(resp)))
      .catch(err => dispatch(queryBrokerScoreRankListByScoreFailure(err)));
  };
}

function queryBrokerScoreRankListByScoreSuccess(data) {
  return {
    type: QUERY_BROKER_SCORE_RANK_SUCCESS,
    payload: data
  };
}

function queryBrokerScoreRankListByScoreFailure(err) {
  return {
    type: QUERY_BROKER_SCORE_RANK_FAILURE,
    payload: err
  };
}

export function loadYearBrokerScores(params) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .loadYearBrokerScores(params)
      .then(resp => dispatch(loadYearBrokerScoresSuccess(resp)))
      .catch(err => dispatch(loadYearBrokerScoresFailure(err)));
  };
}

function loadYearBrokerScoresSuccess(data) {
  return {
    type: LOAD_YEAR_BROKER_SCORE_SUCCESS,
    payload: data
  };
}

function loadYearBrokerScoresFailure(err) {
  return {
    type: LOAD_YEAR_BROKER_SCORE_FAILURE,
    payload: err
  };
}

export function loadResearchReportList(params) {
  return function(dispatch) {
    dispatch(initiateResearchReportStatus());
    client
      .loadResearchReportList(params)
      .then(resp => dispatch(loadResearchReportListSuccess(resp)))
      .catch(err => dispatch(loadResearchReportListFailure(err)));
  };
}

function loadResearchReportListSuccess(data) {
  return {
    type: LOAD_RESEARCH_REPORT_LIST_SUCCESS,
    payload: data
  };
}

function loadResearchReportListFailure(err) {
  return {
    type: LOAD_RESEARCH_REPORT_LIST_FAILURE,
    payload: err
  };
}

export function loadResearchReportDetail(params) {
  return function(dispatch) {
    dispatch(initiateResearchReportDetailStatus());
    client
      .loadResearchReportDetail(params)
      .then(resp => dispatch(loadResearchReportDetailSuccess(resp, params)))
      .catch(err => dispatch(loadResearchReportDetailFailure(err)));
  };
}

function loadResearchReportDetailSuccess(data, params) {
  return {
    type: LOAD_RESEARCH_REPORT_DETAIL_SUCCESS,
    payload: { data, params }
  };
}

function loadResearchReportDetailFailure(err) {
  return {
    type: LOAD_RESEARCH_REPORT_DETAIL_FAILURE,
    payload: err
  };
}

export function initiateResearchReportStatus() {
  return {
    type: INIT_LOAD_RESEARCH_REPORT_STATUS
  };
}

export function initiateResearchReportDetailStatus() {
  return {
    type: INIT_LOAD_RESEARCH_REPORT_DETAIL_STATUS
  };
}

export function changeResearchReportDetailParams({ name, value, notDirty }) {
  return {
    type: CHANGE_RESEARCH_REPORT_DETAIL_PARAMS,
    payload: {
      name,
      value,
      notDirty
    }
  };
}

export function openResearchReportDetailModal(op) {
  return {
    type: OPEN_RESEARCH_REPORT_MODAL,
    payload: op
  };
}

export function closeResearchReportDetailModal(op) {
  return {
    type: CLOSE_RESEARCH_REPORT_MODAL,
    payload: op
  };
}

export function searchTicker(params) {
  return function(dispatch) {
    client
      .searchTicker(params)
      .then(resp => {
        dispatch(searchTickerSuccess(resp));
        dispatch(
          loadResearchReportDetail({ companyCode: params['ticker'], op: 'NEW' })
        );
      })
      .catch(err => dispatch(searchTickerFailure(err)));
  };
}

function searchTickerSuccess(data) {
  return {
    type: SEARCH_TICKER_SUCCESS,
    payload: data
  };
}

function searchTickerFailure(err) {
  return {
    type: SEARCH_TICKER_FAILURE,
    payload: err
  };
}

export function saveResearchReport(params, queryParams) {
  return function(dispatch) {
    client
      .saveResearchReport(params)
      .then(resp => {
        dispatch(saveResearchReportSuccess(resp));
        dispatch(closeResearchReportDetailModal(resp));
        dispatch(loadResearchReportList(queryParams));
      })
      .catch(err => dispatch(saveResearchReportFailure(err)));
  };
}

function saveResearchReportSuccess(data) {
  return {
    type: SAVE_RESERCH_REPORT_SUCCESS,
    payload: data
  };
}

function saveResearchReportFailure(err) {
  return {
    type: SAVE_RESERCH_REPORT_FAILURE,
    payload: err
  };
}

export function changeResearchReportQueryParams({ name, value }) {
  return {
    type: CHAGE_RESEARCH_REPORT_QUERY_PARAMS,
    payload: {
      name,
      value
    }
  };
}

export function copyResearchReport(params, queryParams) {
  return function(dispatch) {
    client
      .copyResearchReport(params)
      .then(resp => {
        dispatch(copyResearchReportSuccess(resp));
        dispatch(loadResearchReportList(queryParams));
      })
      .catch(err => dispatch(copyResearchReportFailure(err)));
  };
}

function copyResearchReportSuccess(data) {
  return {
    type: COPY_RESERCH_REPORT_SUCCESS,
    payload: data
  };
}

function copyResearchReportFailure(err) {
  return {
    type: COPY_RESERCH_REPORT_FAILURE,
    payload: err
  };
}

export function getPersonList(params) {
  return function(dispatch) {
    client
      .getPersonList(params)
      .then(resp => {
        dispatch(getPersonListSuccess(resp));
      })
      .catch(err => dispatch(getPersonListFailure(err)));
  };
}

function getPersonListSuccess(data) {
  return {
    type: GET_RESERCH_PERSON_LIST_SUCCESS,
    payload: data
  };
}

function getPersonListFailure(err) {
  return {
    type: GET_RESERCH_PERSON_LIST_FAILURE,
    payload: err
  };
}

export function getGicsList(params) {
  return function(dispatch) {
    client
      .getGicsList(params)
      .then(resp => {
        dispatch(getGicsListSuccess(resp));
      })
      .catch(err => dispatch(getGicsListFailure(err)));
  };
}

function getGicsListSuccess(data) {
  return {
    type: GET_RESERCH_GICS_LIST_SUCCESS,
    payload: data
  };
}

function getGicsListFailure(err) {
  return {
    type: GET_RESERCH_GICS_LIST_FAILURE,
    payload: err
  };
}

export function downloadResearchReportAttch(params) {
  return function(dispatch) {
    client
      .downloadResearchReportAttch(params)
      .then(resp => {})
      .catch(err => {});
  };
}

export function deleteResearchReportAttch(params) {
  return function(dispatch) {
    client
      .deleteResearchReportAttach(params)
      .then(resp => {
        resp.params = params;
        dispatch(deleteResearchReportAttchSuccess(resp));
      })
      .catch(err => {
        dispatch(deleteResearchReportAttchFailure(err));
      });
  };
}

function deleteResearchReportAttchSuccess(data) {
  return {
    type: DELETE_RESERCH_ATTACH_SUCCESS,
    payload: data
  };
}

function deleteResearchReportAttchFailure(err) {
  return {
    type: DELETE_RESERCH_ATTACH_FAILURE,
    payload: err
  };
}

export function researchReportCellChange() {
  return {
    type: RESERCH_REPORT_CELL_CHANGE
  };
}

export function getBlockList(params) {
  return function(dispatch) {
    client
      .getBlockList(params)
      .then(resp => {
        dispatch(getBlockListSuccess(resp));
      })
      .catch(err => dispatch(getBlockListFailure(err)));
  };
}

function getBlockListSuccess(data) {
  return {
    type: GET_BLOCK_LIST_SUCCESS,
    payload: data
  };
}

function getBlockListFailure(err) {
  return {
    type: GET_BLOCK_LIST_FAILURE,
    payload: err
  };
}

export function addCompanyKeyDataTrack(params) {
  return function(dispatch) {
    client
      .addCompanyKeyDataTrack(params)
      .then(resp => {
        dispatch(addCompanyKeyDataTrackSuccess(resp));
        dispatch(getCompanyKeyDataTrackList({}));
        dispatch(closeCompanyKeyDataTrackModal());
      })
      .catch(err => dispatch(addCompanyKeyDataTrackFailure(err)));
  };
}

function addCompanyKeyDataTrackSuccess(data) {
  return {
    type: ADD_COMPANY_KEY_DATA_SUCCESS,
    payload: data
  };
}

function addCompanyKeyDataTrackFailure(err) {
  return {
    type: ADD_COMPANY_KEY_DATA_FAILURE,
    payload: err
  };
}

export function updateCompanyKeyDataTrack(params) {
  return function(dispatch) {
    client
      .updateCompanyKeyDataTrack(params)
      .then(resp => {
        dispatch(updateCompanyKeyDataTrackSuccess(resp));
        dispatch(getCompanyKeyDataTrackList({}));
        dispatch(closeCompanyKeyDataTrackModal());
      })
      .catch(err => dispatch(updateCompanyKeyDataTrackFailure(err)));
  };
}

function updateCompanyKeyDataTrackSuccess(data) {
  return {
    type: UPDATE_COMPANY_KEY_DATA_SUCCESS,
    payload: data
  };
}

function updateCompanyKeyDataTrackFailure(err) {
  return {
    type: UPDATE_COMPANY_KEY_DATA_FAILURE,
    payload: err
  };
}

export function getCompanyKeyDataTrackList(params) {
  return function(dispatch) {
    client
      .getCompanyKeyDataTrackList(params)
      .then(resp => dispatch(getCompanyKeyDataTrackListSuccess(resp)))
      .catch(err => dispatch(getCompanyKeyDataTrackListFailure(err)));
  };
}

function getCompanyKeyDataTrackListSuccess(data) {
  return {
    type: GET_COMPANY_KEY_DATA_SUCCESS,
    payload: data
  };
}

function getCompanyKeyDataTrackListFailure(err) {
  return {
    type: GET_COMPANY_KEY_DATA_FAILURE,
    payload: err
  };
}

export function getCompanyKeyDataTrackDetail(params) {
  return function(dispatch) {
    client
      .getCompanyKeyDataTrackDetail(params)
      .then(resp => dispatch(getCompanyKeyDataTrackDetailSuccess(resp)))
      .catch(err => dispatch(getCompanyKeyDataTrackDetailFailure(err)));
  };
}

function getCompanyKeyDataTrackDetailSuccess(data) {
  return {
    type: GET_COMPANY_KEY_DETAIL_DATA_SUCCESS,
    payload: data
  };
}

function getCompanyKeyDataTrackDetailFailure(err) {
  return {
    type: GET_COMPANY_KEY_DETAIL_DATA_FAILURE,
    payload: err
  };
}

export function openCompanyKeyDataTrackModal(params) {
  return {
    type: OPEN_COMPANY_KEY_DETAIL_DATA_MODAL,
    payload: params
  };
}

export function closeCompanyKeyDataTrackModal(params) {
  return {
    type: CLOSE_COMPANY_KEY_DETAIL_DATA_MODAL,
    payload: params
  };
}

export function addCompanyMeetingRecord(params) {
  return function(dispatch) {
    client
      .addCompanyMeetingRecord(params)
      .then(resp => {
        dispatch(addCompanyMeetingRecordSuccess(resp));
        dispatch(getCompanyMeetingRecordList({}));
        dispatch(closeCompanyMeetingRecordModal());
      })
      .catch(err => dispatch(addCompanyMeetingRecordFailure(err)));
  };
}

function addCompanyMeetingRecordSuccess(data) {
  return {
    type: ADD_COMPANY_MEETING_RECORD_SUCCESS,
    payload: data
  };
}

function addCompanyMeetingRecordFailure(err) {
  return {
    type: ADD_COMPANY_MEETING_RECORD_FAILURE,
    payload: err
  };
}

export function updateCompanyMeetingRecord(params) {
  return function(dispatch) {
    client
      .updateCompanyMeetingRecord(params)
      .then(resp => {
        dispatch(updateCompanyMeetingRecordSuccess(resp));
        dispatch(getCompanyMeetingRecordList({}));
        dispatch(closeCompanyMeetingRecordModal());
      })
      .catch(err => dispatch(updateCompanyMeetingRecordFailure(err)));
  };
}

function updateCompanyMeetingRecordSuccess(data) {
  return {
    type: UPDATE_COMPANY_MEETING_RECORD_SUCCESS,
    payload: data
  };
}

function updateCompanyMeetingRecordFailure(err) {
  return {
    type: UPDATE_COMPANY_MEETING_RECORD_FAILURE,
    payload: err
  };
}

export function getCompanyMeetingRecordList(params) {
  return function(dispatch) {
    client
      .getCompanyMeetingRecordList(params)
      .then(resp => dispatch(getCompanyMeetingRecordListSuccess(resp)))
      .catch(err => dispatch(getCompanyMeetingRecordListFailure(err)));
  };
}

function getCompanyMeetingRecordListSuccess(data) {
  return {
    type: GET_COMPANY_MEETING_RECORD_SUCCESS,
    payload: data
  };
}

function getCompanyMeetingRecordListFailure(err) {
  return {
    type: GET_COMPANY_MEETING_RECORD_FAILURE,
    payload: err
  };
}

export function getCompanyMeetingRecordDetail(params) {
  return function(dispatch) {
    client
      .getCompanyMeetingRecordDetail(params)
      .then(resp => dispatch(getCompanyMeetingRecordDetailSuccess(resp)))
      .catch(err => dispatch(getCompanyMeetingRecordDetailFailure(err)));
  };
}

function getCompanyMeetingRecordDetailSuccess(data) {
  return {
    type: GET_COMPANY_MEETING_RECORD_DETAIL_SUCCESS,
    payload: data
  };
}

function getCompanyMeetingRecordDetailFailure(err) {
  return {
    type: GET_COMPANY_MEETING_RECORD_DETAIL_FAILURE,
    payload: err
  };
}

export function openCompanyMeetingRecordModal(params) {
  return {
    type: OPEN_COMPANY_MEETING_RECORD_DETAIL_MODAL,
    payload: params
  };
}

export function closeCompanyMeetingRecordModal(params) {
  return {
    type: CLOSE_COMPANY_MEETING_RECORD_DETAIL_MODAL,
    payload: params
  };
}

export function addCompanyReplayScore(params) {
  return function(dispatch) {
    client
      .addCompanyReplayScore(params)
      .then(resp => {
        dispatch(addCompanyReplayScoreSuccess(resp));
        dispatch(getCompanyReplayScoreList({}));
        dispatch(closeCompanyReplayScoreModal());
      })
      .catch(err => dispatch(addCompanyReplayScoreFailure(err)));
  };
}

function addCompanyReplayScoreSuccess(data) {
  return {
    type: ADD_COMPANY_REPLAY_SCORE_SUCCESS,
    payload: data
  };
}

function addCompanyReplayScoreFailure(err) {
  return {
    type: ADD_COMPANY_REPLAY_SCORE_FAILURE,
    payload: err
  };
}

export function updateCompanyReplayScore(params) {
  return function(dispatch) {
    client
      .updateCompanyReplayScore(params)
      .then(resp => {
        dispatch(updateCompanyReplayScoreSuccess(resp));
        dispatch(getCompanyReplayScoreList({}));
        dispatch(closeCompanyReplayScoreModal());
      })
      .catch(err => dispatch(updateCompanyReplayScoreFailure(err)));
  };
}

function updateCompanyReplayScoreSuccess(data) {
  return {
    type: UPDATE_COMPANY_REPLAY_SCORE_SUCCESS,
    payload: data
  };
}

function updateCompanyReplayScoreFailure(err) {
  return {
    type: UPDATE_COMPANY_REPLAY_SCORE_FAILURE,
    payload: err
  };
}

export function getCompanyReplayScoreList(params) {
  return function(dispatch) {
    client
      .getCompanyReplayScoreList(params)
      .then(resp => dispatch(getCompanyReplayScoreListSuccess(resp)))
      .catch(err => dispatch(getCompanyReplayScoreListFailure(err)));
  };
}

function getCompanyReplayScoreListSuccess(data) {
  return {
    type: GET_COMPANY_REPLAY_SCORE_SUCCESS,
    payload: data
  };
}

function getCompanyReplayScoreListFailure(err) {
  return {
    type: GET_COMPANY_REPLAY_SCORE_FAILURE,
    payload: err
  };
}

export function getCompanyReplayScoreDetail(params) {
  return function(dispatch) {
    client
      .getCompanyReplayScoreDetail(params)
      .then(resp => dispatch(getCompanyReplayScoreDetailSuccess(resp)))
      .catch(err => dispatch(getCompanyReplayScoreDetailFailure(err)));
  };
}

function getCompanyReplayScoreDetailSuccess(data) {
  return {
    type: GET_COMPANY_REPLAY_SCORE_DETAIL_SUCCESS,
    payload: data
  };
}

function getCompanyReplayScoreDetailFailure(err) {
  return {
    type: GET_COMPANY_REPLAY_SCORE_DETAIL_FAILURE,
    payload: err
  };
}

export function openCompanyReplayScoreModal(params) {
  return {
    type: OPEN_COMPANY_REPLAY_SCORE_MODAL,
    payload: params
  };
}

export function closeCompanyReplayScoreModal(params) {
  return {
    type: CLOSE_COMPANY_REPLAY_SCORE_MODAL,
    payload: params
  };
}

export function fetchAllExcellentCompany(params) {
  return function(dispatch) {
    client
      .fetchAllExcellentCompany(params)
      .then(resp => dispatch(fetchAllExcellentCompanySuccess(resp)))
      .catch(err => dispatch(fetchAllExcellentCompanyFailure(err)));
  };
}

function fetchAllExcellentCompanySuccess(data) {
  return {
    type: FETCH_ALL_EXCELLENTCOMPANY_SUCCESS,
    payload: data
  };
}

function fetchAllExcellentCompanyFailure(err) {
  return {
    type: FETCH_ALL_EXCELLENTCOMPANY_FAILURE,
    payload: err
  };
}

export function copyCompanyReplayScoreDetail(params, queryParams) {
  return function(dispatch) {
    client
      .copyCompanyReplayScoreDetail(params)
      .then(resp => dispatch(getCompanyReplayScoreList(queryParams)))
      .catch(err => {});
  };
}

export function copyCompanyKeyDataTrackDetail(params, queryParams) {
  return function(dispatch) {
    client
      .copyCompanyKeyDataTrackDetail(params)
      .then(resp => dispatch(getCompanyKeyDataTrackList(queryParams)))
      .catch(err => {});
  };
}

export function loadShortInterestByPerenct(params) {
  return function(dispatch) {
    client
      .loadShortInterestPercent(params)
      .then(resp => dispatch(loadShortInterestPercentSuccess(resp)))
      .catch(err => dispatch(loadShortInterestPercentFailure(err)));
  };
}

function loadShortInterestPercentSuccess(data) {
  return {
    type: LOAD_SHORT_INTEREST_PERCENT_SUCCESS,
    payload: data
  };
}

function loadShortInterestPercentFailure(err) {
  return {
    type: LOAD_SHORT_INTEREST_PERCENT_FAILURE,
    payload: err
  };
}

export function openPushVotesModal(params) {
  return {
    type: OPEN_ADD_UPDATE_PUSH_VOTES_MODAL,
    payload: params
  };
}

export function setPushVotesRecordRead(params) {
  return {
    type: SET_PUSH_VOTES_RECORD_READED,
    payload: params
  };
}

export function closePushVotesModal() {
  return {
    type: CLOSE_ADD_UPDATE_PUSH_VOTES_MODAL
  };
}

export function changePushVotesModalInput(params) {
  return {
    type: CHANGE_PUSH_VOTES_MODAL_INPUT,
    payload: params
  };
}

export function loadPushVotesData(params) {
  return function(dispatch) {
    client
      .loadPushVotesData(params)
      .then(resp => dispatch(loadPushVotesDataSuccess(resp)))
      .catch(err => dispatch(loadPushVotesDataFailure(err)));
  };
}

export function queryPushVotesById(params) {
  return function(dispatch) {
    client
      .queryPushVotesById(params)
      .then(resp => dispatch(queryPushVotesByIdSuccess(resp.data)))
      .catch(err => dispatch(queryPushVotesByIdFailure(err)));
  };
}

export function loadPushVoteReturnData(date) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .loadPushVoteReturnData(date)
      .then(resp => dispatch(loadPushVoteReturnDataSuccess(resp)))
      .catch(err => dispatch(loadPushVoteReturnDataFailure(err)));
  };
}

function loadPushVoteReturnDataSuccess(data) {
  return {
    type: LOAD_PUSH_VOTE_RETURN_DATA_SUCCESS,
    payload: data
  };
}

function loadPushVoteReturnDataFailure(err) {
  return {
    type: LOAD_PUSH_VOTE_RETURN_DATA_FAILURE,
    payload: err
  };
}

function loadPushVotesDataSuccess(data) {
  return {
    type: LOAD_PUSH_VOTES_DATA_SUCCESS,
    payload: data
  };
}

function loadPushVotesDataFailure(err) {
  return {
    type: LOAD_PUSH_VOTES_DATA_FAILURE,
    payload: err
  };
}

function queryPushVotesByIdSuccess(data) {
  return {
    type: LOAD_PUSH_VOTES_DETAIL_SUCCESS,
    payload: data
  };
}

function queryPushVotesByIdFailure(err) {
  return {
    type: LOAD_PUSH_VOTES_DETAIL_FAILURE,
    payload: err
  };
}

export function savePushVotes(params) {
  return function(dispatch) {
    dispatch(initPushVotesSubmitStatus());
    client
      .savePushVotes(params)
      .then(resp => {
        if (resp && resp.code === '0') {
          dispatch(queryPushVotesById({ id: resp.data }));
          dispatch(loadPushVotesData({}));
        }
        dispatch(savePushVotesSuccess(resp));
      })
      .catch(err => dispatch(savePushVotesFailure(err)));
  };
}

function savePushVotesSuccess(data) {
  return {
    type: SAVE_PUSH_VOTES_DATA_SUCCESS,
    payload: data
  };
}

function savePushVotesFailure(err) {
  return {
    type: SAVE_PUSH_VOTES_DATA_FAILURE,
    payload: err
  };
}

export function openPushVoteNoteModal(params) {
  return {
    type: OPEN_ADD_PUSH_VOTE_NOTE_MODAL,
    payload: params
  };
}

export function changePushVoteNoteModalInput(params) {
  return {
    type: CHANGE_PUSH_VOTE_NOTE_MODAL_INPUT,
    payload: params
  };
}

export function closePushVoteNoteModal() {
  return {
    type: CLOSE_ADD_PUSH_VOTE_NOTE_MODAL
  };
}

export function addPushVoteNote(params) {
  return function(dispatch) {
    client.addPushVoteNote(params).then(resp => {
      dispatch(closePushVoteNoteModal());
    });
  };
}

export function querySubscriptionList(params) {
  return function(dispatch) {
    client
      .querySubscriptionList(params)
      .then(resp => dispatch(querySubscriptionListSuccess(resp)))
      .catch(err => dispatch(querySubscriptionListFailure(err)));
  };
}

function querySubscriptionListSuccess(data) {
  return {
    type: GET_PUSH_VOTES_SUBSCRIPT_CONFIG_SUCCESS,
    payload: data
  };
}

function querySubscriptionListFailure(err) {
  return {
    type: GET_PUSH_VOTES_SUBSCRIPT_CONFIG_FAILURE,
    payload: err
  };
}

export function loadPushVotesPmList(params) {
  return function(dispatch) {
    client
      .queryUserAccountList(params)
      .then(resp => dispatch(loadPushVotesPmListSuccess(resp)))
      .catch(err => dispatch(loadPushVotesPmListFailure(err)));
  };
}

function loadPushVotesPmListSuccess(data) {
  return {
    type: GET_PUSH_VOTES_PM_USER_LIST_SUCCESS,
    payload: data
  };
}

function loadPushVotesPmListFailure(err) {
  return {
    type: GET_PUSH_VOTES_PM_USER_LIST_FAILURE,
    payload: err
  };
}

export function loadPushVotesAnalystList(params) {
  return function(dispatch) {
    client
      .queryUserAccountList(params)
      .then(resp => dispatch(loadPushVotesAnalystListSuccess(resp)))
      .catch(err => dispatch(loadPushVotesAnalystListFailure(err)));
  };
}

function loadPushVotesAnalystListSuccess(data) {
  return {
    type: GET_PUSH_VOTES_ANALYST_USER_LIST_SUCCESS,
    payload: data
  };
}

function loadPushVotesAnalystListFailure(err) {
  return {
    type: GET_PUSH_VOTES_ANALYST_USER_LIST_FAILURE,
    payload: err
  };
}

export function changePushVotesSelectItem(params) {
  return {
    type: CHANGE_PUSH_VOTES_SELECT_ITEM,
    payload: params
  };
}

export function adjustPushVotes(params) {
  return function(dispatch) {
    dispatch(initPushVotesSubmitStatus());
    client
      .adjustPushVotes(params)
      .then(resp => {
        dispatch(closePushVotesModal());
        dispatch(loadPushVotesData({}));
        dispatch(adjustPushVotesSuccess(resp));
      })
      .catch(err => dispatch(adjustPushVotesFailure(err)));
  };
}

function adjustPushVotesSuccess(data) {
  return {
    type: ADJUST_PUSH_VOTES_DATA_SUCCESS,
    payload: data
  };
}

function adjustPushVotesFailure(err) {
  return {
    type: ADJUST_PUSH_VOTES_DATA_FAILURE,
    payload: err
  };
}

export function initPushVotesSubmitStatus() {
  return {
    type: INIT_PUSH_VOTES_STATUS
  };
}

export function openPortfolioPlanDialog() {
  return {
    type: OPEN_ADD_UPDATE_PORTFOLIO_PLAN_MODAL
  };
}

export function closePortfolioPlanDialog() {
  return {
    type: CLOSE_ADD_UPDATE_PORTFOLIO_PLAN_MODAL
  };
}
