const config = {
  dev: {
    api: {
      // SOCKET_URL: 'http://localhost:8025',
      SOCKET_URL: 'https://imsqa.pinpointfund.com',
      // REST_URL: 'http://192.168.101.69:8021/api',
      PXY_URL: 'https://imsqa.pinpointfund.com/pxy',
      //PXY_URL: 'http://192.168.101.69:8028/api',
      REST_URL: 'https://imsqa.pinpointfund.com/hub',
      //RESEARCH_URL: 'http://192.168.101.69:8500/api',
      //  RISK_URL: 'http://192.168.101.69:8950/api',
      RISK_URL: 'https://imsqa.pinpointfund.com/riskSrv',
      RESEARCH_URL: 'https://imsqa.pinpointfund.com/rsrch',
      //SOCKET_URL: '/',
      //REST_URL: '/hub',
      //RESEARCH_URL: '/rsrch',
      PRS_URL: 'http://prs.pinpointfund.com:8105',
      CMS_URL: 'http://cms.report.pinpointfund.com',
      CMS_ADMIN_URL: 'http://cms.pinpointfund.com',
      TREASURY_URL: 'https://imsqa.pinpointfund.com/tsy'
    },
    env: 'dev',
    system: 'OFFSHORE',
    title: 'PP-DEV'
  },
  'qa-hk': {
    api: {
      // SOCKET_URL: 'https://imsqa.pinpointfund.com',
      // REST_URL: 'https://imsqa.pinpointfund.com/hub',
      // RESEARCH_URL: 'https://imsqa.pinpointfund.com/rsrch',
      SOCKET_URL: '/',
      REST_URL: '/hub',
      PXY_URL: '/pxy',
      RESEARCH_URL: '/rsrch',
      PRS_URL: 'http://prs.pinpointfund.com:8105',
      CMS_URL: 'http://cms.report.pinpointfund.com',
      RISK_URL: '/riskSrv',
      CMS_ADMIN_URL: 'http://cms.pinpointfund.com',
      TREASURY_URL: '/tsy'
    },
    env: 'qa',
    system: 'OFFSHORE',
    title: 'PP-QA'
  },
  'qa-sh': {
    api: {
      SOCKET_URL: '/',
      REST_URL: '/hub',
      PXY_URL: '/pxy',
      RESEARCH_URL: '/rsrch',
      // SOCKET_URL: '/',
      // REST_URL: '/hub',
      // RESEARCH_URL: '/rsrch',
      PRS_URL: 'http://prs.pinpointfund.cn:8105',
      CMS_URL: 'http://cms.report.pinpointfund.cn',
      RISK_URL: '/riskSrv',
      CMS_ADMIN_URL: 'http://cms.pinpointfund.cn',
      TREASURY_URL: '/tsy'
    },
    env: 'qa',
    system: 'ONSHORE',
    title: 'PP-QA'
  },
  'prod-hk': {
    api: {
      // SOCKET_URL: 'https://ims.pinpointfund.com',
      // REST_URL: 'https://ims.pinpointfund.com/hub',
      // RESEARCH_URL: 'https://ims.pinpointfund.com/rsrch',
      SOCKET_URL: '/',
      REST_URL: '/hub',
      PXY_URL: '/pxy',
      RESEARCH_URL: '/rsrch',
      PRS_URL: 'http://cost.pinpointfund.com',
      CMS_URL: 'http://cms.report.pinpointfund.com',
      RISK_URL: '/riskSrv',
      CMS_ADMIN_URL: 'http://cms.pinpointfund.com',
      TREASURY_URL: '/tsy'
    },
    env: 'prod',
    system: 'OFFSHORE',
    title: 'PINPOINT'
  },
  'prod-sh': {
    api: {
      // SOCKET_URL: 'https://imssh.pinpointfund.com',
      // REST_URL: 'https://imssh.pinpointfund.com/hub',
      // RESEARCH_URL: 'https://imssh.pinpointfund.com/rsrch',
      SOCKET_URL: '/',
      REST_URL: '/hub',
      PXY_URL: '/pxy',
      RESEARCH_URL: '/rsrch',
      PRS_URL: 'http://cost.pinpointfund.com',
      CMS_URL: 'http://cms.report.pinpointfund.cn',
      RISK_URL: '/riskSrv',
      CMS_ADMIN_URL: 'http://cms.pinpointfund.cn',
      TREASURY_URL: '/tsy'
    },
    env: 'prod',
    system: 'ONSHORE',
    title: 'PINPOINT'
  },
  'prod-sg': {
    api: {
      // SOCKET_URL: 'https://ims.pinpointfund.com',
      // REST_URL: 'https://ims.pinpointfund.com/hub',
      // RESEARCH_URL: 'https://ims.pinpointfund.com/rsrch',
      SOCKET_URL: '/',
      REST_URL: '/hub',
      PXY_URL: '/pxy',
      RESEARCH_URL: '/rsrch',
      PRS_URL: 'http://cost.pinpointfund.com',
      CMS_URL: 'http://cms.report.pinpointfund.com',
      RISK_URL: '/riskSrv',
      CMS_ADMIN_URL: 'http://cms.pinpointfund.com',
      TREASURY_URL: '/tsy'
    },
    env: 'prod',
    system: 'OFFSHORE',
    title: 'PINPOINT'
  }
}[process.env.REACT_APP_ENV || 'dev'];

export default config;
