import React, { Component } from 'react';
import {
  Header,
  Modal,
  Form,
  Input,
  Dropdown,
  Icon,
  Message,
  Button,
  Label
} from 'semantic-ui-react';
import { Spin } from 'antd';
import TickerSearchField from 'common/ui/TickerSearchField';
import Options from '../../entities/Options';
import TradeValidator from '../../entities/validators/TradeValidator';
import TradeCalculator from '../../entities/calculators/TradeCalculator';
import NumberFormat from 'react-number-format';
import numeral from 'numeral';
import {
  isInternalFund,
  isPaperTradeFund,
  roleTradeLabel,
  ROLE_ADVISOR
} from '../../../../common/utils/DomainUtils';
import TickerUtils from 'common/utils/TickerUtils';
import Trade from '../../entities/Trade';
import _ from 'lodash';
import client from '../../api/client';
import { DIALOG_EXECUTE_SINGLE_TRADE } from '../../orderConstants';

const { parseAssetClass, AssetClass } = TickerUtils;
class SingleTradeDialog extends Component {
  state = {
    isInitialized: false,

    submitStatus: 'READY',
    serverErrMsg: '',
    fields: Trade.emptyForm(),
    fieldErrors: {},
    isLoadingSecurity: false,
    security: null,

    liveRiskInfoMap: {}
  };

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    this._debouncedSubmit = _.debounce(this.onFormSubmit, 500);

    const { info, liveRiskInfos } = this.props;
    const liveRiskInfoMap = liveRiskInfos.reduce((acc, curr) => {
      const key = `${curr.fundCode}-${curr.bookCode}`;
      acc[key] = curr;
      return acc;
    }, {});
    this.setState({
      liveRiskInfoMap
    });

    if (info.createNew) {
      this._initForNew();
    } else {
      this._initForEdit();
    }
  }

  _invokeInitialization(fields) {
    if (fields.ticker) {
      client
        .getSecurities([
          {
            ticker: fields.ticker,
            excludedTradeId: fields.tradeId,
            excludedRefId: fields.refId
          }
        ])
        .then(([security]) => {
          this.setState({
            security,
            fields,
            isInitialized: true
          });

          this._doCalcAfterGetSecurity(security, true);
        })
        .catch(ex => {
          this.setState({
            fields,
            serverErrMsg: 'Fail to get initialization data.',
            isInitialized: true
          });
        });
    } else {
      this.setState({
        fields,
        isInitialized: true
      });
    }
  }

  _initForNew() {
    const {
      info: { defaultFields = {} },
      settings: { preference, funds }
    } = this.props;

    const firstFund =
      _.find(funds, b => b.books && _.find(b.books, a => a.enableTxn)) || {};
    const firstBook =
      _.find(
        (firstFund && firstFund.books) || [],
        b => b.name !== firstFund.name && b.enableTxn
      ) || {};

    const timeInForce = TradeCalculator.calcTimeInForce(
      firstFund.name,
      preference.timeInForce
    );
    const algoCode = TradeCalculator.calcAlgo(preference.algoCode);
    const orderType = TradeCalculator.calcOrderType(preference.orderType);

    const fields = {
      ...this.state.fields,
      fundCode: firstFund.name,
      bookCode: firstBook.name,
      ...defaultFields,
      timeInForce,
      algoCode,
      orderType
    };

    this._invokeInitialization(fields);
  }

  _initForEdit() {
    const {
      info: { defaultFields }
    } = this.props;

    if (defaultFields) {
      const fields = {
        ...this.state.fields,
        ...defaultFields
      };

      this._invokeInitialization(fields);
    }
  }

  _calcExtraFields = (name, value, security, fields, preference) => {
    let extraFields = { [name]: value };
    const { funds, fundBooks } = this.props.settings;

    if ('fundCode' === name) {
      extraFields['timeInForce'] = TradeCalculator.calcTimeInForce(
        value,
        preference.timeInForce
      );

      const selectedFund = funds.find(f => f.name === value);
      const firstBookCode =
        (selectedFund &&
          selectedFund.books &&
          selectedFund.books[0] &&
          selectedFund.books[0].name) ||
        '';

      extraFields['bookCode'] = firstBookCode;
    }

    const qtyInput =
      TradeCalculator.getQtyInputByFieldName(name) ||
      (['fundCode', 'bookCode', 'side', 'limitPriceLocal'].includes(name) &&
        fields.qtyInput) ||
      null;
    if (qtyInput) {
      extraFields['qtyInput'] = qtyInput;
    }

    if (security && qtyInput) {
      const { liveRiskInfoMap } = this.state;
      const calcResult = TradeCalculator.calcAll(
        {
          ...fields,
          ...extraFields
        },
        security,
        liveRiskInfoMap,
        fundBooks
      );

      extraFields = {
        ...extraFields,
        ...calcResult
      };
    }

    return extraFields;
  };

  _changeInput = ({ name, value }) => {
    const { fundBooks, user } = this.props.settings;
    this.setState((prevState, props) => {
      const { security, fields, fieldErrors } = prevState;
      const {
        settings: { preference }
      } = props;

      const extraFields = this._calcExtraFields(
        name,
        value,
        security,
        fields,
        preference
      );

      const updatedFields = {
        ...fields,
        [name]: value,
        ...extraFields
      };

      // Do the validation for the updated field.
      let extraFieldErrors = {};
      if (security) {
        const { liveRiskInfoMap } = this.state;
        extraFieldErrors = TradeValidator.validate(
          updatedFields,
          security,
          liveRiskInfoMap,
          name,
          fundBooks,
          user
        );
      }

      // Normalize pm remark if algo code is changed.
      const pmRemark =
        name === 'algoCode'
          ? Trade.normalizePmRemark(updatedFields)
          : updatedFields.pmRemark;

      return {
        fields: {
          ...updatedFields,
          pmRemark
        },
        fieldErrors: {
          ...fieldErrors,
          ...extraFieldErrors
        }
      };
    });
  };

  onInputChange = ({ name, value }) => {
    this._changeInput({
      name,
      value
    });
  };

  _calcQtyByCloseOption = payload => {
    const { security, fields } = this.state;

    const quantity = TradeCalculator.calcQtyByCloseOption(
      fields,
      security,
      payload
    );
    if (quantity !== null) {
      this._changeInput({ name: 'quantity', value: quantity });
    }
  };

  _filterRiskByBook(items, fund, book) {
    if (!fund || !book) return [];
    return items.filter(i => i.fundCode === fund && i.bookCode === book);
  }

  _getLiveRiskInfo = (fund, book) => {
    const { liveRiskInfoMap } = this.state;
    const key = `${fund}-${book}`;
    return liveRiskInfoMap[key];
  };

  onFormSubmit = () => {
    const { fields, security, liveRiskInfoMap } = this.state;
    const { fundBooks, user } = this.props.settings;
    // Validate data before submitting trade.
    const fieldErrors = TradeValidator.validate(
      fields,
      security,
      liveRiskInfoMap,
      '',
      fundBooks,
      user
    );
    const errKeys = Object.keys(fieldErrors).filter(
      k => fieldErrors[k] && !fieldErrors[k].isWarning
    );
    if (errKeys.length) {
      this.setState({
        fieldErrors
      });
      return;
    }

    const liveRiskInfo = this._getLiveRiskInfo(
      fields.fundCode,
      fields.bookCode
    );
    const isRiskControlled = liveRiskInfo && liveRiskInfo.riskControlLimit;

    let trade = Trade.toModel(fields, security, isRiskControlled);

    const { submitDialogSuccess, info } = this.props;

    this.setState({ submitStatus: 'SUBMITTING' });
    client
      .submitTrade(trade)
      .then(result => {
        if (info.createNew) {
          trade.tradeId = result.id;
          trade.refId = result.refId;
        }
        submitDialogSuccess(DIALOG_EXECUTE_SINGLE_TRADE, {
          trade,
          createNew: info.createNew
        });
        this.setState({ submitStatus: 'READY' });
      })
      .catch(_ => {
        this.setState({
          submitStatus: 'ERROR',
          serverErrMsg: 'Server Error! Please contact system administrator.'
        });
      });
  };

  _doCalcAfterGetSecurity = (security, init = false) => {
    const { fundBooks, user } = this.props.settings;
    if (!security) {
      this._assertSecurityError();
      return;
    }

    this.setState((prevState, props) => {
      const { fields, liveRiskInfoMap } = prevState;
      const {
        info: { createNew }
      } = props;

      // If edit order and init = true, must use ByShare, otherwise the qty would be changed.
      const fieldValues = TradeCalculator.calcAll(
        { ...fields, ...(init && { qtyInput: 'ByShare' }) },
        security,
        liveRiskInfoMap,
        fundBooks
      );

      const updatedFields = {
        ...fields,
        ...fieldValues,
        ticker: createNew ? security.ticker : fields.ticker,
        lotSize: (security && security.lotSize) || fields.lotSize || 1,
        limitPriceLocal: createNew
          ? (security && security.price) || 0
          : fields.limitPriceLocal,
        secCcy: security.secCcy,
        txnClass: security.txnClass
      };

      // Do the validation.
      const fieldErrors = TradeValidator.validate(
        updatedFields,
        security,
        liveRiskInfoMap,
        '',
        fundBooks,
        user
      );

      return {
        isLoadingSecurity: false,
        security,
        fields: updatedFields,
        fieldErrors
      };
    });
  };

  _assertSecurityError = () => {
    const { fields } = this.state;
    const { fundBooks, user } = this.props.settings;

    const fieldErrors = TradeValidator.validate(
      fields,
      null,
      null,
      'ticker',
      fundBooks,
      user
    );

    this.setState({
      isLoadingSecurity: false,
      security: null,
      fieldErrors
    });
  };

  _getSecurity = ticker => {
    if (!ticker) {
      this._assertSecurityError();
    }

    this.setState({ isLoadingSecurity: true });

    client
      .getSecurities([{ ticker }])
      .then(([security]) => {
        this._doCalcAfterGetSecurity(security);
      })
      .catch(_ => {
        this._assertSecurityError();
      });
  };

  onTickerSelect = ticker => {
    this._getSecurity(ticker);
  };

  _displaySecurityInfo = () => {
    const { fields, security } = this.state;

    if (security) {
      const { name, price, avgVolume, barraData = {} } = security;

      let advPct;
      if (fields.quantity && avgVolume) {
        const value = parseInt(fields.quantity, 10) / avgVolume;
        advPct =
          Math.abs(value) < 0.0001 ? '0%' : numeral(value).format('0.00%');
      }

      const beta = barraData.predBeta
        ? numeral(barraData.predBeta).format('0.00')
        : 'N.A.';

      return `${name}  [Price: ${price ||
        'N.A.'}, Beta: ${beta}, ADV%: ${advPct || 'N.A.'}]`;
    }

    return null;
  };

  _displayPositionInfo = role => {
    const { liveRiskInfoMap, fields, security } = this.state;
    const { fundBooks } = this.props.settings;

    const key = `${fields.fundCode}-${fields.bookCode}`;
    const masterKey = _.get(fundBooks, [key, 'masterFundBook']);
    const liveRisk = liveRiskInfoMap[key] || liveRiskInfoMap[masterKey];

    const { usdNav } = liveRisk || {};
    const { posnEndPct } = fields;

    const usdNavString = numeral(usdNav).format('0.00a');
    const posnEndPerenct = posnEndPct / 100;
    const posnEndBps = posnEndPct * 100;
    const posnEndPctString =
      Math.abs(posnEndBps) < 0.1
        ? `${_.round(posnEndBps, 3)}bps`
        : numeral(posnEndPerenct).format('0.000%');

    let eqtyBetaMV = '';
    const assetClass = parseAssetClass(fields.ticker);
    if (
      assetClass === AssetClass.EQTY &&
      !_.isEmpty(security) &&
      !_.isEmpty(security.barraData) &&
      security.barraData.predBeta
    ) {
      eqtyBetaMV = numeral(
        (posnEndPct / 100) * security.barraData.predBeta
      ).format('0.000%');
    }

    const { securityType2, usdValuePerShare = 0 } = security || {};
    const isOption = securityType2 === 'Option';
    const infos = [
      `${
        role === ROLE_ADVISOR ? 'AUA' : 'AUM'
      } ($): ${usdNavString} | TARGET MV: ${posnEndPctString} ${
        _.isEmpty(eqtyBetaMV) ? '' : ` | TARGET BETA MV: ${eqtyBetaMV} `
      } ${
        isOption
          ? ` | PREMIUM VAL: ${_.round(usdValuePerShare * fields.quantity, 1)}`
          : ''
      }`
    ];

    if (isOption) {
      infos.push(
        `For option, both USD value and ${
          role === ROLE_ADVISOR ? 'AUA' : 'AUM'
        } % input are based on underlying notnl value.`
      );
    }

    return infos;
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_EXECUTE_SINGLE_TRADE);
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus, fields, security } = this.state;
    return {
      SUBMITTING: <Button secondary disabled loading content="Submitting..." />,
      ERROR: (
        <Button secondary onClick={handleSubmit} content="Fail - Retry?" />
      ),
      READY: (
        <Button
          secondary
          disabled={!security}
          onClick={handleSubmit}
          content={fields.side}
        />
      )
    }[submitStatus];
  };

  _createForm = () => {
    const { user = {} } = this.props.settings;
    const { role } = user;
    const { fields, fieldErrors, isLoadingSecurity, serverErrMsg } = this.state;

    const { funds } = this.props.settings;
    const fundOptions = funds
      .map(f => {
        const books = f.books ? f.books.filter(r => r.enableTxn) : [];
        if (_.isEmpty(books)) return null;
        return {
          key: f.name,
          text: f.name,
          value: f.name
        };
      })
      .filter(f => f);
    const matchedFund = funds.find(f => f.name === fields.fundCode) || {};
    const bookOptions = (matchedFund.books || [])
      .filter(b => b.name !== matchedFund.name && b.enableTxn)
      .map(b => ({
        key: b.name,
        text: b.name,
        value: b.name
      }));

    const errorMsgs = Object.values(fieldErrors)
      .filter(v => v && !v.isWarning)
      .map(v => v.msg);
    if (serverErrMsg) {
      errorMsgs.push(serverErrMsg);
    }

    const warningMsgs = Object.values(fieldErrors)
      .filter(v => v && v.isWarning)
      .map(v => v.msg);

    const isOpenTrade = ['BUY', 'SHRT'].includes(fields.side);

    const hideFundBook =
      !funds ||
      !funds.length ||
      (funds.length === 1 && funds[0].books.length <= 1);

    const hideLimitPrice = fields.orderType === 'MKT';
    const orderTypeOptions = Options.orderType.filter(
      o => !isPaperTradeFund(matchedFund.name) || o.key === 'MKT'
    );
    const isNewTrade = this.props.info.createNew;
    const isInternalUse = isInternalFund(fields.fundCode);

    const matchedBook =
      (matchedFund.books || []).find(b => b.name === fields.bookCode) || {};
    const hideStrategy = !matchedBook.enableTxnStrategy;

    return (
      <Form onSubmit={this._debouncedSubmit}>
        {hideFundBook ? (
          ''
        ) : (
          <div>
            <Form.Group widths="equal">
              <Form.Select
                fluid
                disabled={!isNewTrade}
                options={fundOptions}
                value={fields.fundCode}
                placeholder="Select Fund..."
                onChange={(e, { value }) =>
                  this.onInputChange({ name: 'fundCode', value })
                }
              />
              <Form.Select
                fluid
                disabled={!isNewTrade}
                options={bookOptions}
                value={fields.bookCode}
                placeholder="Select Book..."
                onChange={(e, { value }) =>
                  this.onInputChange({ name: 'bookCode', value })
                }
              />
            </Form.Group>
          </div>
        )}

        <Form.Group widths="equal">
          <Form.Field>
            {!isNewTrade ? (
              <Input disabled value={fields.ticker} />
            ) : (
              <TickerSearchField
                name="ticker"
                value={fields.ticker}
                onChange={this.onInputChange}
                selectTicker={this.onTickerSelect}
              />
            )}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            fluid
            placeholder="Name [Price, Beta, ADV%]"
            loading={isLoadingSecurity}
            value={this._displaySecurityInfo() || ''}
            readOnly
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Select
            fluid
            disabled={!isNewTrade}
            options={Options.side}
            value={fields.side}
            placeholder="Select Side..."
            onChange={(e, { value }) =>
              this.onInputChange({ name: 'side', value })
            }
          />
          <Form.Select
            fluid
            options={Options.algo}
            value={fields.algoCode}
            placeholder="Select Algo..."
            onChange={(e, { value }) =>
              this.onInputChange({ name: 'algoCode', value })
            }
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Select
            fluid
            options={orderTypeOptions}
            value={fields.orderType}
            placeholder="Select Order Type..."
            onChange={(e, { value }) =>
              this.onInputChange({ name: 'orderType', value })
            }
          />
          <Form.Field>
            <Input
              disabled={hideLimitPrice}
              labelPosition="left"
              placeholder="Enter Lmt Price..."
              type="text"
              value={fields.limitPriceLocal}
              onChange={(e, { value }) =>
                this.onInputChange({ name: 'limitPriceLocal', value })
              }
            >
              <Label basic>Limit:</Label>
              <input />
            </Input>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Select
              fluid
              disabled={!isInternalUse}
              options={Options.timeInForce}
              value={fields.timeInForce}
              placeholder="Select TIF..."
              onChange={(e, { value }) =>
                this.onInputChange({ name: 'timeInForce', value })
              }
            />
          </Form.Field>
          {hideStrategy ? null : (
            <Form.Field>
              <Input
                labelPosition="left"
                placeholder="Enter Strategy..."
                type="text"
                value={fields.strategy}
                onChange={(e, { value }) =>
                  this.onInputChange({
                    name: 'strategy',
                    value: (value || '').toUpperCase()
                  })
                }
              >
                <Label basic>Stgy:</Label>
                <input />
              </Input>
            </Form.Field>
          )}
        </Form.Group>

        <Message info list={this._displayPositionInfo(role)} />

        <Form.Group>
          <Form.Field>
            <Input
              disabled={!isNewTrade && !isInternalUse}
              labelPosition="right"
              placeholder={`Enter ${
                role === ROLE_ADVISOR ? 'AUA' : 'AUM'
              } %...`}
              type="text"
              value={fields.qtyPct}
              onChange={(e, { value }) =>
                this.onInputChange({ name: 'qtyPct', value })
              }
            >
              <Label basic>{role === ROLE_ADVISOR ? 'AUA' : 'AUM'}:</Label>
              <input />
              <Label>%</Label>
            </Input>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Input
              disabled={!isNewTrade && !isInternalUse}
              labelPosition={isOpenTrade ? 'left' : 'right'}
            >
              <Label basic>QTY:</Label>
              <NumberFormat
                thousandSeparator={true}
                value={fields.quantity}
                placeholder="Enter Qty..."
                onValueChange={values =>
                  this.onInputChange({
                    name: 'quantity',
                    value: values.floatValue
                  })
                }
              />
              {isOpenTrade ? (
                ''
              ) : (
                <Label>
                  <Dropdown
                    options={Options.qtyCalc}
                    onChange={(e, { value }) =>
                      this._calcQtyByCloseOption(value)
                    }
                    trigger={
                      <span>
                        <Icon name="calculator" />
                      </span>
                    }
                  />
                </Label>
              )}
            </Input>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Input
              disabled={!isNewTrade && !isInternalUse}
              labelPosition="left"
            >
              <Label basic>USD:</Label>
              <NumberFormat
                thousandSeparator={true}
                value={fields.qtyUsd}
                prefix={'$'}
                placeholder="Enter USD value..."
                onValueChange={values =>
                  this.onInputChange({
                    name: 'qtyUsd',
                    value: values.floatValue
                  })
                }
              />
            </Input>
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.TextArea
            placeholder="Enter Reason..."
            value={fields.pmReason}
            onChange={(e, { value }) =>
              this.onInputChange({ name: 'pmReason', value })
            }
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.TextArea
            placeholder="Enter instructions to trader, if need to specify algo time range, please write as HH:mm-HH:mm or just HH:mm for end time..."
            value={fields.pmRemark}
            onChange={(e, { value }) =>
              this.onInputChange({ name: 'pmRemark', value })
            }
          />
        </Form.Group>

        <Message
          error
          header="Fix Below Errors"
          visible={errorMsgs.length > 0}
          list={errorMsgs}
        />

        <Message
          warning
          header="Warnings"
          visible={warningMsgs.length > 0}
          list={warningMsgs}
        />
      </Form>
    );
  };

  render() {
    const {
      info: { createNew },
      settings: { user = {} }
    } = this.props;
    const label = roleTradeLabel(user, true);

    const { isInitialized } = this.state;

    return (
      <div>
        <style>
          {`
            .ui.modals {
              display: flex !important;
            }
            .ui.modal {
              margin-top: 0px !important;
            }
            .ui.basic.label {
              width: 55px;
            }
          `}
        </style>

        <Modal size="tiny" open={true}>
          <Header
            icon="file text outline"
            content={createNew ? `New Single ${label}` : `Edit Single ${label}`}
          />

          <Modal.Content>
            <Spin tip="Initializing..." spinning={!isInitialized}>
              {this._createForm()}
            </Spin>
          </Modal.Content>
          <Modal.Actions>
            {this._createSubmitBtn(this._debouncedSubmit)}
            <Button secondary content="Cancel" onClick={this.closeDialog} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default SingleTradeDialog;
