import React, { PureComponent } from 'react';
import { Modal, Form, Input, InputNumber, TimePicker, Row, Col, Select, Checkbox } from 'antd';
import _ from 'lodash';
import { Divider } from "antd";

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 }
};

const Option = Select.Option;

const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};

class AlgoParamsPairDialog extends PureComponent {

  constructor(props){
    super(props);
    this.state ={
      algoParams: this.props.algoParams,
      priceConventionOptions:[
        {name: 'CB_PREMIUM', desc: 'cb_px/(par_val / conv_px * eqty_px) - 1', revertDesc: 'cb_px/(par_val / conv_px * eqty_px) - 1'},
        {name: 'RATIO', desc: '(Leg2 * Weight2) / (Leg1 * Weight1)', revertDesc: '(Leg1 * Weight1) / (Leg2 * Weight2)'},
        {name: 'SPREAD', desc: '(Leg2 * PriceRatio2) - (Leg1 * PriceRatio1)', revertDesc: '(Leg1 * PriceRatio1) - (Leg2 * PriceRatio2)'},
        {name: 'SPREAD_PCT', desc: '100 * (L2 * Wt2 -  L1 * Wt1)/L1', revertDesc: '100 * (L1 * Wt1 -  L2 * Wt2)/L2'},
      ],
      execStyles: [
        'INLINE',
        'DMA'
      ]
    }
  }

  _onInputChange = ({name, value}) => {
    const { algoParams } = this.state;
    const updateParams = {
      ...algoParams,
      [name]: value
    }
    if(name === 'CB_PREMIUM') {
      updateParams.FLIP = false;
      updateParams['EXEC_STYLE'] ='INLINE';
      updateParams['Target Vol %'] =20;
    }
    if(name === 'EXEC_STYLE') {
      updateParams['Target Vol %'] = value === 'INLINE'?20:null;
    }
    this.setState({
      algoParams: updateParams
    })
  }

  _findDesc = () => {
    const { algoParams } = this.state;
    const last = _.findLast(this.state.priceConventionOptions, r => algoParams.PX_CONV === r.name);
    return algoParams.flip?last.revertDesc: last.desc;
  }

  _createStrategySetup = p => {
    const { algoParams } = this.state;
    return (
      <>
        <Row gutter={24}>
          <Col span={24} style={{textAlign: 'left'}}>
            <span style={{marginLeft: '10px', fontSize: '15px'}}><b>Strategy Setup</b></span>
          </Col>
          <Col span={10} style={{height: '35px'}}>
            <Form.Item label="Price Convention">
              <Select
                value={algoParams.PX_CONV}
                onChange={value => {
                  this._onInputChange({ name: 'PX_CONV', value })
                }}
              >
                {_createUIOptions(this.state.priceConventionOptions.map(r => r.name))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4} style={{textAlign: 'left', height: '35px'}}>
              <Checkbox style={{marginTop: '2px'}} disabled={algoParams.PX_CONV === 'CB_PREMIUM'} onChange={e => {
                 this._onInputChange({ name: 'FLIP', value: e.target.checked })
                }}>Flip</Checkbox></Col>
          <Col span={10} style={{textAlign: 'right', height: '35px'}}>
            {this._findDesc()}
          </Col>
          <Col span={10} style={{height: '35px'}}>
            <Form.Item label="Exec Style">
              <Select
                value={algoParams.EXEC_STYLE}
                onChange={value => {
                  this._onInputChange({ name: 'EXEC_STYLE', value })
                }}
              >
                {_createUIOptions(this.state.execStyles)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            {algoParams.EXEC_STYLE === 'INLINE' &&
              <InputNumber
                value={algoParams['Target Vol %']}
                placeholder='Target Vol %'
                style={{width: '150px'}}
                formatter={value => `${value}%`}
                onChange={value => {
                  this._onInputChange({ name: 'Target Vol %', value })
              }}/>}
          </Col>
          <Col span={24} style={{height: '35px', paddingLeft: '78px'}}>
              <b>Check every &nbsp;&nbsp;</b>
              <InputNumber
                  value={algoParams.CHECK_SECONDS}
                  onChange={value => {
                    this._onInputChange({ name: 'CHECK_SECONDS', value })
                }}/> <b>&nbsp;&nbsp;seconds for unhedged qty</b>
          </Col>
        </Row>
      </>
    )
  };

  _createLegs = () => {
    const { leg1, leg2, WT_1, WT_2 } = this.state.algoParams;
    return (
      <div style={{width: '100%'}}>
        <div style={{width: '50%', float: 'left'}}>
          <Row gutter={24}>
            <Col span={20} style={{textAlign: 'left'}}>
              <span style={{marginLeft: '10px', fontSize: '15px'}}><b>Leg 1</b></span>
            </Col>
            <Col span={20} style={{height: '35px', marginTop: '10px'}}>
              <Form.Item label="Side">
                <Input value={leg1.side} readOnly/>
              </Form.Item>
            </Col>
            <Col span={20} style={{height: '35px'}}>
              <Form.Item label="Ticker">
                <Input value={leg1.ticker} readOnly/>
              </Form.Item>
            </Col>
            <Col span={20} style={{height: '35px'}}>
              <Form.Item label="Weight" >
                <InputNumber value={WT_1} max={100} min={0} onChange={value => {
                  this._onInputChange({name: 'WT_1', value})
                }} />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div style={{width: '50%', float: 'right'}}>
          <Row gutter={24}>
            <Col span={20} style={{textAlign: 'left'}}>
              <span style={{marginLeft: '10px', fontSize: '15px'}}><b>Leg 2</b></span>
            </Col>
            <Col span={20} style={{height: '35px', marginTop: '10px'}}>
              <Form.Item label="Side">
                <Input value={leg2.side} readOnly/>
              </Form.Item>
            </Col>
            <Col span={20} style={{height: '35px'}}>
              <Form.Item label="Ticker">
                <Input value={leg2.ticker} readOnly/>
              </Form.Item>
            </Col>
            <Col span={20} style={{height: '35px'}}>
              <Form.Item label="Weight">
                <InputNumber value={WT_2} max={100} min={0} onChange={value => {
                  this._onInputChange({name: 'WT_2', value})
                }} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  _createOtherParams = () => {
    const { TARGET_SPREAD, START_TIME, END_TIME } = this.state.algoParams;
    return (
      <>
        <Row gutter={24}>
          <Col span={4} style={{textAlign: 'right', paddingRight: '0px'}}><b>Target Spread:</b></Col>
          <Col span={8} style={{height: '35px'}}>
            <InputNumber value={TARGET_SPREAD} onChange={value => this._onInputChange({name: 'TARGET_SPREAD', value})} />
          </Col>
        </Row>
        <Row gutter={24}>
            <Col span={4} style={{textAlign: 'right', paddingRight: '0px'}}><b>StartTime:</b></Col>
            <Col span={8} style={{height: '35px'}}>
              <TimePicker value={START_TIME} format="HH:mm:ss" onChange={value => this._onInputChange({name: 'START_TIME', value})} />
            </Col>
            <Col span={4} style={{textAlign: 'right'}}><b>EndTime:</b></Col>
            <Col span={8} style={{height: '35px', paddingLeft: '0px'}}>
              <TimePicker value={END_TIME} format="HH:mm:ss" onChange={value => this._onInputChange({name: 'END_TIME', value})} />
            </Col>
          </Row>
      </>
    )
  }

  _onSubmit = () => {
    const { algoParams } = this.state;
    const submitAlgoParams = {
      ...algoParams
    };
    delete submitAlgoParams.leg1;
    delete submitAlgoParams.leg2;
    const timeFlds = ['START_TIME', 'END_TIME'];
    timeFlds.forEach(r => {
      if(submitAlgoParams[r]) submitAlgoParams[r] = submitAlgoParams[r].format('HH:mm:ss')
    })
    this.props.onSubmit(submitAlgoParams);
  }

  render() {
    return (
      <Modal
        visible={true}
        width={900}
        style={{ marginTop: '30px' }}
        title="Pair Algo Params"
        okText="Apply"
        cancelText="Cancel"
        onCancel={this.props.closeDialog}
        onOk={() => {
          this._onSubmit();
        }}
      >
        <Form
          name="algoParamsPairFrom"
          layout="horizontal"
          {...formItemLayout}
        >
          {this._createStrategySetup()}
          <Divider style={{margin: '8px 0'}}/>
          {this._createLegs()}
          <Divider style={{margin: '8px 0'}}/>
          {this._createOtherParams()}
        </Form>
      </Modal>
    );
  }
};

export default AlgoParamsPairDialog;
