import React, { Component } from 'react';
import {
  Drawer,
  Form,
  Input,
  Row,
  Col,
  Typography,
  Select,
  Button,
  Collapse
} from 'antd';
import client from '../../../api/client';
import _ from 'lodash';
import IndustryReview from '../review/IndustryReview';
import { getNextQuarter } from 'common/utils/DateUtils';
import NotesDrawer from './NotesDrawer';
import { getValue, save, delKey } from 'common/utils/IndexedDBUtils';
import LinkPannel from './LinkPannel';

const { Panel } = Collapse;
const { Title } = Typography;
const Option = Select.Option;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class IndustryScoreDetailDrawer extends Component {
  state = {
    scoreList: [1, 2, 3, 4, 5],
    data: { ...this.props.data },
    loadNotesStatus: 'READY',
    submitStatus: 'READY',
    changeFlag: false,
    notesDraw: {
      show: false
    },
    isDraft: false
  };

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  async componentDidMount() {
    const { data } = this.state;

    const cacheData = await this.getCache();
    if (!_.isEmpty(cacheData)) {
      this.setState({
        isDraft: true,
        data: cacheData
      });
    } else {
      if (data.noteIds) {
        this.setState({
          loadNotesStatus: 'LOADING'
        });
        client
          .queryNotes(data.noteIds)
          .then(resp => {
            data.noteList = resp;
            this.setState({
              loadNotesStatus: 'READY'
            });
          })
          .catch(err => {
            console.log(err);
            this.setState({
              loadNotesStatus: 'READY'
            });
          });
      }
    }
    if (!this._isReadOnly()) {
      _.delay(this.autoSave, 1000);
    }
  }

  componentWillUnmount() {
    this.clearIntervals();
  }

  clearIntervals = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  };

  autoSave = () => {
    this.intervalId = setInterval(() => {
      const { data, submitStatus, loadNotesStatus } = this.state;
      if (
        data.id &&
        submitStatus !== 'SUBMITTING' &&
        loadNotesStatus === 'READY'
      ) {
        //save to indexDb
        save(data.id, this._getSubmitData(), 'sector');
      }
      if (!data.id && !_.isEmpty(data.sector) && data.sector !== 'Sector') {
        //auto save to db
        this._submitData(true);
      }
    }, 30000);
  };

  delCache = () => {
    const { data } = this.state;
    if (data.id) {
      delKey(data.id, 'sector');
    }
  };

  getCache = () => {
    const { data } = this.state;
    if (data.id) {
      return getValue(data.id, 'sector');
    }
  };

  _onInputChange = ({ name, value }) => {
    const { data } = this.state;

    const updateData = {
      ...data,
      [name]: value
    };
    let score = updateData.score;
    if (['beta', 'busModel', 'policy'].includes(name)) {
      const { beta, busModel, policy } = updateData;
      const arr = [beta, busModel, policy].filter(r => r);
      score = Math.round((_.sum(arr) / arr.length) * 2) / 2;
    }
    updateData.score = score;
    this.setState({
      data: updateData,
      changeFlag: true
    });
  };

  _isReadOnly = () => {
    const { mode } = this.props;
    return mode === 'DETAIL' ? true : false;
  };

  _createSectorDetailForm = () => {
    const { data } = this.state;
    const readOnly = this._isReadOnly();
    return (
      <Form
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        initialValues={data}
      >
        <Row gutter={24}>
          <Col span={24} style={{ height: '41px' }}>
            <Form.Item name="score" label="Total score">
              <div style={{ width: '10%', float: 'left' }}>
                <Input value={data.score} readOnly={true} />
              </div>
              <div style={{ width: '89%', float: 'right' }}>
                <Input
                  value={data.scoreDesc}
                  style={{ height: '32px' }}
                  readOnly={readOnly}
                  onChange={e => {
                    this._onInputChange({
                      name: 'scoreDesc',
                      value: e.target.value
                    });
                  }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col span={24} style={{ height: '41px' }}>
            <Form.Item name="beta" label="Beta">
              <div style={{ width: '10%', float: 'left' }}>
                <Select
                  value={data.beta}
                  onChange={value => {
                    this._onInputChange({
                      name: 'beta',
                      value
                    });
                  }}
                  disabled={readOnly}
                >
                  {_createUIOptions(this.state.scoreList)}
                </Select>
              </div>
              <div style={{ width: '89%', float: 'right' }}>
                <Input
                  value={data.betaDesc}
                  style={{ height: '32px' }}
                  readOnly={readOnly}
                  onChange={e => {
                    this._onInputChange({
                      name: 'betaDesc',
                      value: e.target.value
                    });
                  }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col span={24} style={{ height: '41px' }}>
            <Form.Item name="busModel" label="Business Model">
              <div style={{ width: '10%', float: 'left' }}>
                <Select
                  value={data.busModel}
                  onChange={value => {
                    this._onInputChange({
                      name: 'busModel',
                      value
                    });
                  }}
                  disabled={readOnly}
                >
                  {_createUIOptions(this.state.scoreList)}
                </Select>
              </div>
              <div style={{ width: '89%', float: 'right' }}>
                <Input
                  value={data.busModelDesc}
                  style={{ height: '32px' }}
                  readOnly={readOnly}
                  onChange={e => {
                    this._onInputChange({
                      name: 'busModelDesc',
                      value: e.target.value
                    });
                  }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col span={24} style={{ height: '41px' }}>
            <Form.Item name="policy" label="Policy">
              <div style={{ width: '10%', float: 'left' }}>
                <Select
                  value={data.policy}
                  onChange={value => {
                    this._onInputChange({
                      name: 'policy',
                      value
                    });
                  }}
                  disabled={readOnly}
                >
                  {_createUIOptions(this.state.scoreList)}
                </Select>
              </div>
              <div style={{ width: '89%', float: 'right' }}>
                <Input
                  value={data.policyDesc}
                  style={{ height: '32px' }}
                  readOnly={readOnly}
                  onChange={e => {
                    this._onInputChange({
                      name: 'policyDesc',
                      value: e.target.value
                    });
                  }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col span={24} style={{ height: '112px' }}>
            <Form.Item name="trend" label="Trend">
              <Input.TextArea
                rows={5}
                readOnly={readOnly}
                onChange={e => {
                  this._onInputChange({
                    name: 'trend',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ height: '75px' }}>
            <Form.Item name="model" label="3.5 sentence">
              <Input.TextArea
                rows={3}
                readOnly={readOnly}
                onChange={e => {
                  this._onInputChange({
                    name: 'model',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ height: '110px' }}>
            <Form.Item name="risk" label="Industry risk">
              <Input.TextArea
                rows={5}
                readOnly={readOnly}
                onChange={e => {
                  this._onInputChange({
                    name: 'risk',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  _onCompanyAdd = async (tickerData, refreshWcRev) => {
    const { data } = this.state;
    const { review } = data;
    if (!data.data) data.data = [];
    data.data.push({
      ticker: tickerData.code,
      name: tickerData.name,
      marketViewDesc: tickerData.marketViewDesc,
      isNew: true
    });

    await this._contactAutoCompleteData(review, [tickerData.code]);

    refreshWcRev(review, data);
  };

  _syncData = async () => {
    const { data } = this.state;
    const { review } = data;
    const tickerList = review.wcRev.map(r => r.ticker);
    await this._contactAutoCompleteData(review, tickerList);
    this.setState({
      data: {
        ...data
      }
    });
  };

  _contactAutoCompleteData = async (review, tickerList) => {
    try {
      const tickers = tickerList.filter(r => r && !r.startsWith('[UnList]'));
      const { data } = await this._getStockIndexList(review.quanter, tickers);
      if (_.isEmpty(data)) return;
      review.wcRev.forEach(r => {
        data
          .filter(d => d.ticker === r.ticker)
          .forEach(e => {
            const key = `${e.year}${e.quarter}`;
            r[key] = e['wcRev'] === 0 ? null : e['wcRev'];
          });
      });
    } catch (err) {
      console.log(err);
    }
  };

  _getStockIndexList = async (date, tickerList) => {
    const stockList = await client.queryStockIndexInfoList({
      date,
      tickerList
    });

    return stockList ? stockList : [];
  };

  _onReviewDataChange = ({ name, value }) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        review: {
          ...data.review,
          [name]: value
        }
      }
    });
  };

  _createReviewPanel = () => {
    const { data } = this.state;
    if (!data.reviewDetail && !data.review) {
      const nextQuanter = getNextQuarter();
      data.review = {
        quanter: nextQuanter.quarterKey,
        otherFinanceIndexName: '其他财务指标(自选)',
        marketShare: [],
        wcRev: [],
        otherFinanceIndex: [],
        comments: []
      };
    } else {
      data.review = data.review ? data.review : JSON.parse(data.reviewDetail);
    }
    return (
      <div>
        <IndustryReview
          {...this.props}
          onReviewDataChange={this._onReviewDataChange}
          data={data}
          readOnly={this._isReadOnly()}
          onCompanyAdd={this._onCompanyAdd}
          syncData={this._syncData}
          onIndustryDataChange={this._onInputChange}
        />
      </div>
    );
  };

  _getSubmitData = () => {
    const { data } = this.state;
    Object.keys(data.review).forEach(r => {
      if (_.isArray(data.review[r])) {
        data.review[r] = data.review[r].filter(r => r);
      }
    });
    return {
      ...data,
      reviewDetail: JSON.stringify(data.review)
    };
  };

  onSumbitBtnClick = () => {
    this._submitData();
  };

  _onDrawClose = () => {
    const { data } = this.state;
    if (data.id && !this._isReadOnly()) {
      this._submitData(false, true);
    } else {
      this._closeDraw();
    }
  };

  _submitData = (unCloseDrawer, unPublish) => {
    const { data } = this.state;
    const { reloadData } = this.props;
    this.setState({
      submitStatus: 'SUBMITTING'
    });
    const submitData = this._getSubmitData();
    const call = !unPublish
      ? client.savePublishIndustryScore
      : client.saveIndustryScore;
    call(submitData)
      .then(resp => {
        const id = resp.data;
        this.setState({
          submitStatus: 'READY',
          data: {
            ...data,
            id
          }
        });
        if (!unCloseDrawer) {
          this._closeDraw({
            saveData: {
              ...data,
              id
            }
          });
        }
        reloadData();
      })
      .catch(err => {
        this.setState({
          submitStatus: 'ERROR'
        });
        console.log(err);
      });
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus, isDraft } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" size="small" type="primary" disabled loading>
          Saving
        </Button>
      ),
      ERROR: (
        <Button key="submit" size="small" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" size="small" type="primary" onClick={handleSubmit}>
          {isDraft ? 'UnSave Version' : 'Save & Publish'}
        </Button>
      )
    }[submitStatus];
  };

  _titleChange = () => {
    const { mode } = this.props;
    if (mode === 'DETAIL') return false;

    return {
      onChange: value => {
        this._onInputChange({ name: 'sector', value });
      }
    };
  };

  _createAttachList = () => {
    const { data } = this.state;
    return (
      <LinkPannel {...this.props} data={data} readOnly={this._isReadOnly()} />
    );
  };

  _createNotesDraw = () => {
    const { data, notesDraw } = this.state;
    const name = <span style={{ color: '#FF9E28' }}>{data.sector}</span>;
    return (
      notesDraw.show && (
        <div>
          <NotesDrawer
            {...this.props}
            data={data}
            name={name}
            readOnly={this._isReadOnly()}
            closeNotesDraw={this._closeNotesDraw}
            saveData={this._submitData}
            submitStatus={this.state.submitStatus}
          />
        </div>
      )
    );
  };

  _openNotesDraw = () => {
    this.setState({
      notesDraw: {
        show: true
      }
    });
  };

  _closeNotesDraw = () => {
    this.setState({
      notesDraw: {
        show: false
      }
    });
  };

  _closeDraw = (data = {}) => {
    const { closeDrawer } = this.props;
    this.clearIntervals();
    this.delCache();
    closeDrawer(data);
  };

  render() {
    const { mode } = this.props;
    const { data, changeFlag, loadNotesStatus } = this.state;
    const readOnly = this._isReadOnly();
    const title = (
      <div>
        <div style={{ width: '50%', height: '25px', float: 'left' }}>
          <div style={{ width: `${mode.length * 16}px`, float: 'left' }}>
            <Title level={4} style={{ color: 'white' }}>
              {mode}
            </Title>
          </div>
          <div style={{ width: '400px', float: 'left' }}>
            <Title
              editable={this._titleChange()}
              style={{ color: '#FF9E28' }}
              level={4}
            >
              {data.sector}
            </Title>
          </div>
        </div>
        <div style={{ width: '50%', height: '25px', float: 'right' }}>
          <div style={{ width: '100%', textAlign: 'right', marginTop: '10px' }}>
            {!readOnly && this._createSubmitBtn(this.onSumbitBtnClick)}
            <Button
              size="small"
              disabled={loadNotesStatus === 'READY' ? false : true}
              onClick={this._openNotesDraw}
              style={{ marginLeft: '10px' }}
            >
              {loadNotesStatus === 'READY' ? 'Notes' : 'Loading Notes'}
            </Button>
            <Button
              size="small"
              onClick={this._onDrawClose}
              style={{ marginLeft: '10px' }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
    return (
      <>
        <style>
          {`
                      .planDetailDrawerClass {
                      }

                      .planDetailDrawerClass  .ant-drawer-content{
                        background-color: #2d3436;
                      }

                      .planDetailDrawerClass  .ant-drawer-header{
                        background-color: #2d3436;
                      }

                      .planDetailDrawerClass .ant-drawer-title {
                        color: white;
                      }

                      .planDetailDrawerClass .ant-select-selector{
                        background-color: #2d3436 !important;
                        border: 1px solid #594e4a !important;
                        color: white !important;
                      }

                      .planDetailDrawerClass .ant-select-arrow{
                        color: white !important;
                      }

                      .planDetailDrawerClass .ant-form-item-label > label{
                        color: #FF9E28;
                        font-size: 12px;
                      }

                      .planDetailDrawerClass .ant-input{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 12px;
                      }

                      .planDetailDrawerClass .ant-input-number-lg input{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 13px;
                        height: 30px;
                      }

                      .planDetailDrawerClass .ant-input-number{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 13px;
                        height: 30px;
                      }

                      .planDetailDrawerClass .ant-collapse-header{
                        border: 0px !important;
                        background-color: #2d3436;
                        color: white !important;
                      }

                      .planDetailDrawerClass .ant-collapse-header .ant-collapse-arrow{
                        color: white !important;
                      }

                      .planDetailDrawerClass .ant-collapse-content-box{
                        border: 0px !important;
                        background-color: #2d3436;
                        color: white !important;
                        padding: 0px;
                      }

                      .planDetailDrawerClass .ant-collapse {
                        background-color: #2d3436;
                        border: 0px solid #d9d9d9 !important;
                        border-bottom: 0;
                        border-radius: 2px;
                      }

                      .planDetailDrawerClass .ant-collapse-item {
                        border-bottom: 0px solid #d9d9d9 !important;
                      }

                      .planDetailDrawerClass .ant-collapse-content {
                        overflow: hidden;
                        border-top: 0px solid #d9d9d9 !important;
                        background-color: #2d3436;
                      }

                    `}
        </style>
        <Drawer
          width={'100%'}
          placement="right"
          title={title}
          onClose={this._onDrawClose}
          maskClosable={!changeFlag && mode === 'DETAIL'}
          closable={false}
          visible={true}
          className="planDetailDrawerClass"
        >
          {this._createSectorDetailForm()}
          <Collapse defaultActiveKey={['1', '2']}>
            <Panel header="Attach List" key="1">
              {this._createAttachList()}
            </Panel>
            <Panel header="Review" key="2">
              {this._createReviewPanel()}
            </Panel>
          </Collapse>
          {this._createNotesDraw()}
        </Drawer>
      </>
    );
  }
}

export default IndustryScoreDetailDrawer;
