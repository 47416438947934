import React, { Component } from 'react';
import { Drawer, Form, Input, Row, Col, Select, Button, Collapse, InputNumber } from 'antd';
import TickerSearchField from 'common/ui/TickerSearchField';
import client from 'features/order/api/client';
import researchClient from '../../../api/client';
import CompanyReview from '../review/CompanyReview';
import _ from 'lodash';
import { getNextQuarter } from 'common/utils/DateUtils';
import TickerUtils from 'common/utils/TickerUtils';
import NotesDrawer from './NotesDrawer';
import { getValue, save, delKey } from 'common/utils/IndexedDBUtils';
import LinkPannel from './LinkPannel';
import ChatGptModal from './ChatGptModal';

const { Panel } = Collapse;
const Option = Select.Option;
const _createUIOptions = codes => {
  return codes.map(c => (
      <Option key={c || 'default'} value={c}>
        {c}
      </Option>
  ));
};
class CompanyScoreDetailDrawer extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      tableConfig:[
        {group: 'Long-term factors', weightKey: 'longWeight', factor: 'Total Score', scoreKey: 'score', scoreOption: 'scoreList', reasonKey: 'longScoreReason', min: 30, required: true},
        {group: '', weightKey: false, factor: 'Investment Direction', scoreKey: 'longInvestmentConclusion', scoreOption: 'conclusionList', reasonKey: 'longInvestDirReason', required: true},
        {group: '', weightKey: false, factor: '3.5 Sentences', scoreKey: 'threeDirScore', scoreOption: 'threeDirList', reasonKey: 'threeDirReason'},
        {group: '', weightKey: false, factor: 'Corporate Governance', scoreKey: 'management', scoreOption: 'scoreList', reasonKey: 'managementDesc'},
        {group: '', weightKey: false, factor: '—Incentives', scoreKey: 'stockBasedCompensationScore', scoreOption: 'scoreList', reasonKey: 'stockBasedCompensation'},
        {group: '', weightKey: false, factor: '—Shareholder structure', scoreKey: 'equityStructureScore', scoreOption: 'scoreList', reasonKey: 'equityStructure'},
        {group: '', weightKey: false, factor: '—Punishment', scoreKey: 'punishmentScore', scoreOption: 'scoreList', reasonKey: 'punishment'},
        {group: '', weightKey: false, factor: '—Outside ownership', scoreKey: 'assetsOfMajorScore', scoreOption: 'scoreList', reasonKey: 'assetsOfMajorShareholders'},
        {group: '', weightKey: false, factor: 'Competitiveness', scoreKey: 'competitionScore', scoreOption: 'scoreList', reasonKey: 'competition'},
        {group: '', weightKey: false, factor: '—Market share Change', scoreKey: 'mkShareChgScore', scoreOption: 'scoreList', reasonKey: 'mkShareChgReason'},
        {group: '', weightKey: false, factor: '—Intensity of competition', scoreKey: 'intensityScore', scoreOption: 'scoreList', reasonKey: 'intensityReason'},

        {group: 'Short-term factors', weightKey: 'shortWeight', factor: 'Total Score', scoreKey: 'shortTotalScore', scoreOption: 'scoreList', reasonKey: 'shortTotalReason', required: true},
        {group: '', weightKey: false, factor: 'Investment Direction', scoreKey: 'shortInvestmentConclusion', scoreOption: 'conclusionList', reasonKey: 'shortInvestmentConclusionReason', required: true},
        {group: '', weightKey: false, render: 'list', renderData: [
            {group: '', weightKey: false, factorKey: 'segment', scoreKey: 'segmentScore', scoreOption: 'scoreList', reasonKey: 'segmentReason'},
            {group: '', weightKey: false, factor: '—Volume', scoreKey: 'volume', scoreOption: 'scoreList', reasonKey: 'volumeSecondDerivative'},
            {group: '', weightKey: false, factor: '—ASP', scoreKey: 'price', scoreOption: 'scoreList', reasonKey: 'priceSecondDerivative'},
            {group: '', weightKey: false, factor: '—Margin', scoreKey: 'margin', scoreOption: 'scoreList', reasonKey: 'marginSecondDerivative'},
          ], datakey: 'companyBusinessList'},
        {group: '', weightKey: false, factor: 'W.C./Revenue', scoreKey: 'workingCapitalDivRevenue', scoreOption: 'scoreList', reasonKey: 'workingCapitalReason'},
        {group: '', weightKey: 'valuationWeight', factor: 'Valuation', scoreKey: 'valuation', scoreOption: 'scoreList', reasonKey: 'valuationReason', required: true},
        {group: '', weightKey: false, factor: '—EV/EBITDA', scoreKey: 'ebitdaScore', scoreOption: 'scoreList', reasonKey: 'ebitdaReason'},
        {group: '', weightKey: false, factor: '—PE', scoreKey: 'peScore', scoreOption: 'scoreList', reasonKey: 'peReason'},
        {group: '', weightKey: false, factor: '—My forecasts vs. Consensus', scoreKey: 'forConScore', scoreOption: 'scoreList', reasonKey: 'forConReason'},
        {group: '', weightKey: false, factor: 'Other financial metrics', scoreKey: 'otherFinScore', scoreOption: false, reasonKey: 'otherFinancialIndex'},
        {group: '', weightKey: false, factor: 'Catalysts', scoreKey: 'catalystsScore', scoreOption: false, reasonKey: 'catalyst'},
        {group: '', weightKey: false, factor: 'Risk', scoreKey: 'riskScore', scoreOption: false, reasonKey: 'risk'},
        {group: '', weightKey: false, factor: 'Key things to follow', scoreKey: 'trackingScore', scoreOption: false, reasonKey: 'tracking'},

        {group: 'Market Sentiments', weightKey: 'mktSentimentsWeight', factor: 'Total Score', scoreKey: 'mktSentimentsScore', scoreOption: 'scoreList', reasonKey: 'mktSentimentsReason', required: true},
        {group: '', weightKey: false, factor: '—Mutual funds\' view', scoreKey: 'publicFundScore', scoreOption: 'scoreList', reasonKey: 'publicFundDesc'},
        {group: '', weightKey: false, factor: '—Hedge funds\' view', scoreKey: 'privateFundScore', scoreOption: 'scoreList', reasonKey: 'privateFundDesc'},
        {group: '', weightKey: false, factor: '—Individual investors\' view', scoreKey: 'privateInvestorScore', scoreOption: 'scoreList', reasonKey: 'privateInvestorDesc'},
        {group: '', weightKey: false, factor: '—Research depth', scoreKey: 'researchDepthScore', scoreOption: 'scoreList', reasonKey: 'researchDepthDesc'},

        {group: 'Chip&flow', weightKey: 'chipFlowWeight', factor: 'Total Score', scoreKey: 'marketView', scoreOption: 'scoreList', reasonKey: 'marketViewDesc', required: true},
        {group: '', weightKey: false, factor: '—Dividends', scoreKey: 'dividendsScore', scoreOption: 'scoreList', reasonKey: 'dividendsDesc'},
        {group: '', weightKey: false, factor: '—Share buybacks', scoreKey: 'shareBuybackScore', scoreOption: 'scoreList', reasonKey: 'shareBuybackDesc'},
        {group: '', weightKey: false, factor: '—Key shareholders action', scoreKey: 'shareholdersScore', scoreOption: 'scoreList', reasonKey: 'shareholdersReason'},

        {group: 'Sum/conclusion', weightKey: 'sumWeight', factor: '', scoreKey: 'sumScore', scoreOption: false, reasonKey: false, readLine: true},
      ],
      scoreList: [1, 2, 3, 4, 5],
      loadNotesStatus: 'READY',
      conclusionList: ['Long', 'Short', 'No action'],
      threeDirList: ['Monopoly',
        'Brand',
        'Network effects-(N^2)/2',
        'Network effects-X*Y*I',
        '2C-Cost performance',
        '2B-lowest cost',
        'Technological Leadership'],
      competitionList: ['Intensifying', 'Weakening', 'No change'],
      marketViewList: ['High', 'Med', 'Low'],
      deltaList: ['Accelerate', 'Decelerate', 'No change'],
      companyBusinessList: [],
      data: this.props.data,
      lastVersionData: {},
      submitStatus: 'READY',
      defaultSingleQData: [
        {
          id: 1,
          type: 'Revenue (million)',
          chineseIndex: '单季营收 (百万)',
          index: 'Revenue (million)',
          firstRow: true
        },
        {
          id: 2,
          type: 'Revenue (million)',
          index: 'YoY',
          disabled: true,
          isPercent: true
        },
        {
          id: 3,
          type: 'Revenue (million)',
          index: 'QoQ',
          disabled: true,
          isPercent: true
        },
        {
          id: 4,
          type: 'GP Margin',
          index: 'GP Margin',
          firstRow: true,
          isPercent: true
        },
        {
          id: 5,
          type: 'GP Margin',
          index: 'YoY(ppt)',
          disabled: true,
          isPpt: true
        },
        {
          id: 6,
          type: 'GP Margin',
          index: 'QoQ(ppt)',
          disabled: true,
          isPpt: true
        },
        {
          id: 7,
          type: 'Net profit',
          index: 'Net profit (million) ',
          firstRow: true,
          isPercent: true
        },
        {
          id: 8,
          type: 'Net profit',
          index: 'YoY',
          disabled: true
        },
        {
          id: 9,
          type: 'Net profit',
          index: 'QoQ',
          disabled: true
        },
        {
          id: 10,
          type: 'Other metrics',
          chineseIndex: '其他指标1',
          index: 'Other metrics 1'
        },
        {
          id: 11,
          type: 'Other metrics',
          chineseIndex: '其他指标2',
          index: 'Other metrics 2'
        },
        {
          id: 12,
          type: 'Other metrics',
          chineseIndex: '其他指标3',
          index: 'Other metrics 3'
        }
      ],
      defaultBottomData: [
        { id: 1, index: 'Sales expense' },
        { id: 2, index: 'Admin expense' },
        { id: 3, index: 'R&D (absolute value)' },
        {
          id: 4,
          type: 'Net interest expense',
          index: 'Net interest expense',
          excludeMarkets: ['CH']
        },
        {
          id: 5,
          type: 'Finance expense',
          index: 'Finance expense',
          markets: ['CH']
        }
      ],
      defaultFutureTrendData: [
        { id: 1, index: 1 },
        { id: 2, index: 2 },
        { id: 3, index: 3 },
        { id: 4, index: 4 }
      ],
      happenList: [
        'Preliminary results',
        'Results',
        'News',
        'Expert',
        'Company visit',
        'Event',
        'Other'
      ],
      notesDraw: {
        show: false,
        name: ''
      },
      isDraft: false,
      showChatModal: false
    };
  }

  _createNotesDraw = () => {
    const { data, notesDraw } = this.state;
    return (
        notesDraw.show && (
            <div>
              <NotesDrawer
                  {...this.props}
                  data={data}
                  name={notesDraw.name}
                  readOnly={this._isReadOnly()}
                  closeNotesDraw={this._closeNotesDraw}
                  saveData={this._submitData}
                  submitStatus={this.state.submitStatus}
              />
            </div>
        )
    );
  };

  _openNotesDraw = () => {
    const { data } = this.state;
    const name = (
        <>
        <span style={{ color: '#FF9E28' }}>
          {data.ticker ? data.ticker : ''}
        </span>
          <span>{data.name ? `(${data.name})` : ''}</span>
        </>
    );
    this.setState({
      notesDraw: {
        show: true,
        name: name
      }
    });
  };

  _closeNotesDraw = () => {
    this.setState({
      notesDraw: {
        show: false
      }
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  async componentDidMount() {
    this._getLastSanp();
    this._getSectorDesc();
    const cacheData = await this.getCache();
    if (!_.isEmpty(cacheData)) {
      this.setState({
        data: cacheData,
        isDraft: true
      });
    } else {
      this.loadNotes();
    }
    if (!this._isReadOnly()) {
      _.delay(this.autoSave, 1000);
    }
  }

  async componentWillUnmount() {
    this.clearIntervals();
  }

  clearIntervals = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  };

  autoSave = () => {
    this.intervalId = setInterval(() => {
      const { data, submitStatus, loadNotesStatus } = this.state;
      if (
          data.id &&
          submitStatus !== 'SUBMITTING' &&
          loadNotesStatus === 'READY'
      ) {
        //save to indexDb
        const cacheData = {
          ...data,
          reviewDetail: JSON.stringify(data.review)
        };
        save(data.id, cacheData, 'companyScore');
      }
      if (
          !data.id &&
          !_.isEmpty(data.ticker) &&
          data.ticker.endsWith('Equity')
      ) {
        //auto save to db
        this._submitData(true);
      }
    }, 30000);
  };

  delCache = () => {
    const { data } = this.state;
    if (data.id) {
      delKey(data.id, 'companyScore');
    }
  };

  getCache = () => {
    const { data } = this.state;
    if (data.id) {
      return getValue(data.id, 'companyScore');
    }
  };

  loadNotes = () => {
    const { data } = this.state;

    if (data.noteIds) {
      this.setState({
        loadNotesStatus: 'LOADING'
      });
      researchClient
          .queryNotes(data.noteIds)
          .then(resp => {
            data.noteList = resp;
            this.setState({
              loadNotesStatus: 'READY'
            });
          })
          .catch(err => {
            console.log(err);
            this.setState({
              loadNotesStatus: 'READY'
            });
          });
    }
  };

  _getSectorDesc = () => {
    const { data } = this.props;
    if(!data.sectorId) return;
    researchClient.getSectorDesc({id: data.sectorId}).then(resp => {
      const fldlist = [{key: 'sector', name: 'Sector'},
        {key: 'score', name: 'Score'},
        {key: 'beta', name: 'Beta'},  {key: 'busModel', name: 'Business Model'}, {key: 'policy', name: 'Policy'}];
      const fldArr = fldlist.map(r => `${r.name}: ${resp[r.key]?resp[r.key]:''}`);
      const sectorDesc = !_.isEmpty(fldArr)?_.join(fldArr, ', '):null;
      this.setState({
        sectorDesc
      })
    }).catch(err => console.log(err));
  }

  _getLastSanp = () => {
    const { sectorData, data } = this.props;
    if (sectorData && sectorData.lastSnapshotId) {
      const lastVersionNum = sectorData.status === 'PUBLISH' ? 2 : 1;
      researchClient
          .getLastSnapshot({ id: sectorData.lastSnapshotId, lastVersionNum })
          .then(resp => {
            let insData = resp.data ? resp.data.data : [];
            insData = insData ? insData : [];
            const lastComData = insData.filter(r => r.id === data.id);
            const lastVersionData = _.isEmpty(lastComData) ? {} : lastComData[0];
            this.setState({
              lastVersionData
            });
          })
          .catch(err => console.log(err));
    }
  };

  _syncData = async () => {
    const { data } = this.state;
    const { review } = data;
    await this._contactAutoCompleteData(review, [data.ticker]);
    this.setState({
      data: {
        ...data
      }
    });
  };

  _contactAutoCompleteData = async (review, tickerList) => {
    try {
      const { data } = await this._getStockIndexList(
          review.quanter,
          tickerList
      );
      if (_.isEmpty(data)) return;
      data.forEach(e => {
        const key = `${e.year}${e.quarter}`;
        this._autoCompleteSingleQuarterData(key, e, review.singleQuarterData);
        this._autoCompleteBottomLineData(key, e, review.bottomLineData);
      });
    } catch (err) {
      console.log(err);
    }
  };

  _autoCompleteSingleQuarterData = (key, data, singleQuarterData) => {
    const map = {
      '单季营收 (百万)': 'revenue',
      'Revenue (million)': 'revenue',
      'GP Margin': 'gpMargin',
      'Net profit (million) ': 'netIncome'
    };
    singleQuarterData.forEach(r => {
      const { index } = r;
      if (map[index]) {
        const value = data[map[index]];
        r[key] = value;
        this._calData(key, r, value, singleQuarterData);
      }
    });
  };

  _autoCompleteBottomLineData = (key, data, bottomLineData) => {
    const map = {
      'sales expense': r => r['salesExpense'],
      'Admin expense': r => r['adminExpense'],
      'R&D (absolute value)': r => Math.abs(r['rdExpense']),
      'Net interest expense': r => r.netInterestExpense,
      'Finance expense': r => r['financeExpense']
    };
    bottomLineData.forEach(r => {
      const { index } = r;
      if (map[index]) {
        if (map[index]) {
          const value = map[index](data);
          r[key] = value === 0 ? null : value;
        }
      }
    });
  };

  _calData = (field, data, value, dataList) => {
    const yoyField = `${_.toInteger(field.substr(0, 4)) - 1}${field
        .substring(4)
        .replace('e', '')}`;
    const yoyFieldBefore = `${_.toInteger(field.substr(0, 4)) +
    1}${field.substring(4).replace('e', '')}`;
    const yoyeFieldBefore = `${_.toInteger(field.substr(0, 4)) +
    1}${field.substring(4).replace('e', '')}e`;
    const year = _.toInteger(field.substr(0, 4));
    const quarter = _.toInteger(field.substr(5, 1));
    const qoqField = `${quarter === 1 ? year - 1 : year}Q${
        quarter === 1 ? 4 : quarter - 1
    }`;
    const qoqFieldBefore = `${quarter === 4 ? year + 1 : year}Q${
        quarter === 4 ? 1 : quarter + 1
    }`;
    const qoqeFieldBefore = `${quarter === 4 ? year + 1 : year}Q${
        quarter === 4 ? 1 : quarter + 1
    }e`;
    const filterList = dataList.filter(
        r => r.type === data.type && r.id !== data.id
    );
    if (filterList) {
      filterList.forEach(r => {
        if (r.index.startsWith('YoY')) {
          const yoyFieldData = data[yoyField];
          r[field] = _.isNaN(value - yoyFieldData)
              ? null
              : this._calPercent(value, yoyFieldData, r.index);
          if (!field.endsWith('e')) {
            r[yoyeFieldBefore] = _.isNaN(data[yoyeFieldBefore] - value)
                ? undefined
                : this._calPercent(data[yoyeFieldBefore], value, r.index);
            r[yoyFieldBefore] = _.isNaN(data[yoyFieldBefore] - value)
                ? undefined
                : this._calPercent(data[yoyFieldBefore], value, r.index);
          }
        } else if (r.index.startsWith('QoQ')) {
          const qoqFieldData = data[qoqField];
          r[field] = _.isNaN(value - qoqFieldData)
              ? undefined
              : this._calPercent(value, qoqFieldData, r.index);
          if (!field.endsWith('e')) {
            r[qoqeFieldBefore] = _.isNaN(data[qoqeFieldBefore] - value)
                ? undefined
                : this._calPercent(data[qoqeFieldBefore], value, r.index);
            r[qoqFieldBefore] = _.isNaN(data[qoqFieldBefore] - value)
                ? undefined
                : this._calPercent(data[qoqFieldBefore], value, r.index);
          }
        }
      });
    }
  };

  _calPercent = (value1, value2, index) => {
    if (index && index.endsWith('(ppt)')) return _.round(value1 - value2, 2);
    if (value1 && value2) return _.round((value1 / value2 - 1) * 100, 1);
    return null;
  };

  _getStockIndexList = async (date, tickerList) => {
    const stockList = await researchClient.queryStockIndexInfoList({
      date,
      tickerList
    });

    return stockList ? stockList : [];
  };

  onInputChange = ({ name, value, segmentIndex }) => {
    const { data } = this.state;
    let updateData = { ...data };
    if(!_.isNil(segmentIndex)) {
      const segementList = updateData.companyBusinessList;
      const updateSegement = segementList.map((r, index) => {
        if(index === segmentIndex) {
          return {
            ...r,
            [name]: value
          }
        }
        return { ...r }
      });
      updateData = {
        ...data,
        companyBusinessList: updateSegement
      };
    } else {
      updateData = {
        ...data,
        [name]: value
      };
    }
    const weightFlds = ['longWeight', 'shortWeight', 'valuationWeight', 'mktSentimentsWeight', 'chipFlowWeight'];
    let sumWeight = 0;
    weightFlds.forEach(e => sumWeight += updateData[e]?updateData[e]:0);

    updateData = {
      ...updateData,
      sumWeight
    };

    this.setState({
      data: updateData
    });
  };

  _getSecurity = ticker => {
    if (!ticker) {
      return;
    }
    this.setState({ isLoadingSecurity: true });
    client
        .getSlimSecurities([{ ticker }])
        .then(([security]) => {
          const { ticker, name } = security;
          this.onInputChange({ name: 'ticker', value: ticker });
          this.onInputChange({ name: 'name', value: name });
          this._queryStockHolding(ticker);
        })
        .catch(_ => {});
  };

  _queryStockHolding = ticker => {
    if (_.isEmpty(ticker) || !ticker.endsWith('CH Equity')) return;
    const tickerPrefix = ticker.replace(' CH Equity', '');
    researchClient
        .queryStockHolding({ ticker: tickerPrefix })
        .then(resp => {
          const data = resp.data ? resp.data : null;
          if (data) {
            this.onInputChange({
              name: 'marketViewDesc',
              value: `Mutual fund holding/circulating market cap=${_.round(
                  data.holdingPct,
                  2
              )}%`
            });
          }
        })
        .catch(_ => {});
  };

  onTickerSelect = ticker => {
    this._getSecurity(ticker);
  };

  _isReadOnly = () => {
    const { mode } = this.props;
    return mode === 'DETAIL' ? true : false;
  };

  _unListTicker = ticker => {
    return ticker && ticker.startsWith('[UnList]');
  };

  onTickerInput = value => {
    const { data } = this.state;
    const codeValue = value
        ? value.startsWith('[UnList]')
            ? value
            : `[UnList]${value}`
        : '';
    const valueStr = value
        ? value.startsWith('[UnList]')
            ? value.replace('[UnList]', '')
            : value
        : '';
    this.setState({
      data: {
        ...data,
        ticker: codeValue,
        name: valueStr
      }
    });
  };

  _createCompanyDetailForm = () => {
    const { data, tableConfig, lastVersionData } = this.state;
    const readOnly = this._isReadOnly();
    const tickerLabel = `Ticker${readOnly ? '' : '(BBG ticker + Press [Tab])'}`;
    return (
        <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={data}
        >
          <Row gutter={24}>
            {!readOnly && (
                <>
                  <Col span={24} style={{ marginBottom: '8px', height: '45px' }}>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 21 }}
                        name="ticker"
                        label={tickerLabel}
                    >
                      <div className={'ui form field'}>
                        <div style={{ width: '35%', float: 'left' }}>
                          {this._unListTicker(data.ticker) ? (
                              <Input
                                  style={{ height: '38px' }}
                                  value={data.ticker}
                                  onChange={e => {
                                    this.onTickerInput(e.target.value);
                                  }}
                              />
                          ) : (
                              <TickerSearchField
                                  name="ticker"
                                  value={data.ticker}
                                  onChange={({ name, value }) => {
                                    this.onInputChange({ name: 'ticker', value });
                                  }}
                                  selectTicker={this.onTickerSelect}
                              />
                          )}
                        </div>
                        <div style={{ width: '64%', float: 'right' }}>
                          <Input
                              style={{ height: '38px' }}
                              value={data.name}
                              readOnly={true}
                          />
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                </>
            )}
          </Row>
          <Row gutter={24} className='dataTable'>
            <Col span={2} className='tableHeader'></Col>
            <Col span={2} className='tableHeader'>Weight</Col>
            <Col span={3} className='tableHeader'>Factors</Col>
            <Col span={3} className='tableHeader'>Score</Col>
            <Col span={9} className='tableHeader'>Reason</Col>
            <Col span={3} className='tableHeader'>Previous Score</Col>
            <Col span={2} className='tableHeader tableLast'>AI Score</Col>
          </Row>
          {
            tableConfig.map((r, index) => this._createItem(r, data, lastVersionData, index === tableConfig.length-1))
          }
        </Form>
    );
  };

  _createItem = (item, data, preData, lastRow) => {
    const render = item.render;
    const renderData = item.renderData;
    const detail = data[item.datakey];
    // const preDetail = preData?preData[item.datakey]:[];
    switch(render){
      case 'list':
        return detail.map((e, eindex) => renderData.map((r) => this._createFlds(r, e, {}, false, eindex)));
      default:
        return this._createFlds(item, data, preData, lastRow);
    }
  }

  _createFlds = (item, data, preData, lastRow, segmentIndex) => {
    const weightValue = item.weightKey?data[item.weightKey]:null;
    const required = item.required;
    const scoreValue = item.scoreKey?data[item.scoreKey]:null;
    const reasonValue = data[item.reasonKey];
    const preScoreValue = preData?preData[item.scoreKey]:null;
    const aiScoreValue = data[item.aiScoreKey];
    const readLine = item.readLine;
    return (
        <Row gutter={24} className={`dataTable ${required?'requiredItem':''}`} >
          <Col span={2} className={`tableItem ${lastRow?'lastRow':''}`}>{item.group}</Col>
          <Col span={2} className={`tableItem ${lastRow?'lastRow':''}`}>
            {this._createItemCompent('num', item.weightKey, weightValue, null, segmentIndex, item.min?item.min:1, readLine)}
          </Col>
          <Col span={3} className={`tableItem ${lastRow?'lastRow':''}`}>
            {item.factor?item.factor:this._createItemCompent('text', item.factorKey, data[item.factorKey], null, segmentIndex, null, readLine)}
          </Col>
          <Col span={3} className={`tableItem ${lastRow?'lastRow':''}`}>{this._createItemCompent('select', item.scoreKey, scoreValue, item.scoreOption, segmentIndex, null, readLine)}</Col>
          <Col span={9} className={`tableItem ${lastRow?'lastRow':''}`}>{this._createItemCompent('text', item.reasonKey, reasonValue, null, segmentIndex, null, readLine)}</Col>
          <Col span={3} className={`tableItem ${lastRow?'lastRow':''}`}>{preScoreValue}</Col>
          <Col span={2} className={`tableItem tableLast ${lastRow?'lastRow':''}`}>{aiScoreValue}</Col>
        </Row>
    )
  }

  _createItemCompent = (type, key, value, option, segmentIndex, min, readLine) => {
    const isReadOnly = readLine || this._isReadOnly();
    if(!_.isNil(segmentIndex) && !value && key === 'segment') {
      value = `Segment${segmentIndex+1}`
    }
    let input = <span className='tableReadItem'>{value}</span>;
    if(!isReadOnly && key) {
      switch (type) {
        case "num":
          input = <InputNumber style={{width: '100%'}} value={value} step="1" min={min} max={100}
                               onChange={v => { this.onInputChange({name: key, value: v, segmentIndex}); }}/>
          break;
        case "select":
          input = this.state[option] && <Select value={value} style={{width: '100%'}} onChange={v => { this.onInputChange({name: key, value: v, segmentIndex}); }}>
            {_createUIOptions(this.state[option])}
          </Select>;
          break;
        default:
          input = <Input style={{width: '100%'}} value={value} onChange={e => { this.onInputChange({name: key, value: e.target.value, segmentIndex}); }}/>
      }
    }
    return input;
  }

  _closeDraw = (data = {}) => {
    const { closeDrawer } = this.props;
    this.clearIntervals();
    this.delCache();
    closeDrawer(data);
  };

  _onDrawClose = () => {
    const { data } = this.state;
    if (data.id && !this._isReadOnly()) {
      this._submitData(false, true);
    } else {
      this._closeDraw();
    }
  };

  _submitData = (unCloseDrawer, unPublish) => {
    const { data } = this.state;
    const { reloadData } = this.props;
    const { companyBusinessList } = data;
    this.setState({
      submitStatus: 'SUBMITTING'
    });
    const submitData = {
      ...data,
      companyBusinessList,
      reviewDetail: JSON.stringify(data.review)
    };
    const call = !unPublish?researchClient.savePublishCompanyScore:researchClient.saveCompanyScore;
    call(submitData)
        .then(resp => {
          const id = resp.data;
          this.setState({
            submitStatus: 'READY',
            data: {
              ...data,
              id
            }
          });
          if (!unCloseDrawer) {
            this._closeDraw({
              saveData: {
                ...submitData,
                id
              }
            });
          }
          reloadData();
        })
        .catch(err => {
          this.setState({
            submitStatus: 'ERROR'
          });
          console.log(err);
        });
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus, isDraft } = this.state;
    return {
      SUBMITTING: (
          <Button key="submit" type="primary" size="small" disabled loading>
            Saving
          </Button>
      ),
      ERROR: (
          <Button key="submit" type="primary" size="small" onClick={handleSubmit}>
            Fail - Retry?
          </Button>
      ),
      READY: (
          <Button key="submit" type="primary" size="small" onClick={handleSubmit}>
            {isDraft ? 'UnSave Version' : 'Save & Publish'}
          </Button>
      )
    }[submitStatus];
  };

  _onReviewDataChange = ({ name, value }) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        review: {
          ...data.review,
          [name]: value
        }
      }
    });
  };

  _createCompanyReview = () => {
    const {
      data,
      defaultSingleQData,
      defaultBottomData,
      defaultFutureTrendData,
      companyBusinessList
    } = this.state;
    if (!data.reviewDetail && !data.review) {
      const nextQuanter = getNextQuarter();
      data.review = {
        quanter: nextQuanter.quarterKey,
        singleQuarterData: defaultSingleQData,
        performanceSplit: [],
        bottomLineData: defaultBottomData,
        futureTrendData: defaultFutureTrendData,
        marketFeedback: [{ id: 1 }],
        investmentConclusion: [{ id: 1 }],
        followUp: [{ id: 1 }]
      };
    } else {
      data.review = data.review ? data.review : JSON.parse(data.reviewDetail);
    }
    return (
        <CompanyReview
            {...this.props}
            companyBusinessList={companyBusinessList}
            onReviewDataChange={this._onReviewDataChange}
            onCompanyScoreDataChange={this.onInputChange}
            calData={this._calData}
            readOnly={this._isReadOnly()}
            syncData={this._syncData}
            getTickerExch={this._getTickerExch}
            defaultTemplate={{
              defaultSingleQData,
              defaultBottomData,
              defaultFutureTrendData
            }}
            data={data}
        />
    );
  };

  _getTickerExch = () => {
    const { ticker } = this.state.data;
    if (!ticker) return '';
    return TickerUtils.parseExch(ticker);
  };

  onSubmitBtnClick = () => {
    this._submitData();
  };

  _createAttachList = () => {
    const { data } = this.state;
    return (
        <LinkPannel {...this.props} data={data} readOnly={this._isReadOnly()} />
    );
  };

  _openChatGptModal = () => {
    this.setState({
      showChatModal: true
    });
  };

  _closeChatModal = () => {
    this.setState({
      showChatModal: false
    });
  };

  _createChatModal = () => {
    const { showChatModal } = this.state;
    const { ticker } = this.state.data;
    if (!ticker || !ticker.endsWith('CH Equity')) return;
    return (
        showChatModal && (
            <ChatGptModal ticker={ticker} closeModal={this._closeChatModal} />
        )
    );
  };

  render() {
    const { data, loadNotesStatus, sectorDesc } = this.state;
    const readOnly = this._isReadOnly();
    const title = (
        <div>
          <div style={{ float: 'left', width: '40%', textAlign: 'left' }}>
          <span style={{ color: '#FF9E28' }}>
            {data.ticker ? data.ticker : ''}
          </span>
            <span>{data.name ? `(${data.name}${sectorDesc ? `, ${sectorDesc}` : ''})` : ''}</span>
            <span></span>
          </div>
          <div style={{ float: 'right', width: '60%', textAlign: 'right' }}>
            {!readOnly && this._createSubmitBtn(this.onSubmitBtnClick)}
            <Button
                size="small"
                disabled={loadNotesStatus === 'READY' ? false : true}
                onClick={this._openNotesDraw}
                style={{ marginLeft: '10px' }}
            >
              {loadNotesStatus === 'READY' ? 'Notes' : 'Loading Notes'}
            </Button>
            <Button
                size="small"
                onClick={this._onDrawClose}
                style={{ marginLeft: '10px' }}
            >
              Cancel
            </Button>
          </div>
        </div>
    );
    return (
        <>
          <style>
            {`
                      .planDetailDrawerClass {
                      }

                      .planDetailDrawerClass  .ant-drawer-content{
                        background-color: #2d3436;
                      }

                      .planDetailDrawerClass  .ant-drawer-header{
                        background-color: #2d3436;
                      }

                      .planDetailDrawerClass .ant-drawer-title {
                        color: white;
                      }

                      .planDetailDrawerClass .ant-form-item-label > label{
                        color: #FF9E28;
                        font-size: 12px;
                      }

                      .planDetailDrawerClass .ant-input{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 12px;
                      }

                      .planDetailDrawerClass .ant-input-number-lg input{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 12px;
                        height: 30px;
                      }

                      .planDetailDrawerClass .dataTable .ant-input{
                        background-color:rgb(200 216 230) !important;
                        color: black;
                        border: 0px;
                        font-size: 12px;
                        height: 25px;
                      }

                      .planDetailDrawerClass .dataTable ant-input-number-lg .ant-input{
                        background-color: rgb(200 216 230) !important;
                        color: black;
                        border: 0px;
                        font-size: 12px;
                      }

                      .planDetailDrawerClass .dataTable .ant-input-number{
                        background-color: rgb(200 216 230)  !important;
                        color: black;
                        border: 0px;
                        font-size: 12px;
                        height: 25px;
                      }

                      .planDetailDrawerClass .dataTable .ant-select-selector{
                        background-color: rgb(200 216 230) !important;
                        border: 0px solid rgb(200 216 230) !important;
                        color: black !important;
                        height: 25px;
                        border-bottom: 0.1px solid rgb(200 216 230) ;
                      }

                      .planDetailDrawerClass .requiredItem ant-input-number-lg .ant-input{
                        background-color: rgb(251 187 187) !important;
                        color: black;
                        border: 0px;
                        font-size: 12px;
                      }

                      .planDetailDrawerClass .requiredItem .ant-input-number{
                        background-color: rgb(251 187 187) !important;
                        color: black;
                        border: 0px;
                        font-size: 12px;
                        height: 25px;
                      }

                      .planDetailDrawerClass .requiredItem .ant-input{
                        background-color:rgb(251 187 187) !important;
                        color: black;
                        border: 0px;
                        font-size: 12px;
                        height: 25px;
                      }

                      .planDetailDrawerClass .requiredItem .ant-select-selector{
                        background-color: rgb(251 187 187) !important;
                        border: 0px solid #594e4a !important;
                        color: black !important;
                        height: 25px;
                        border-bottom: 0.1px solid rgb(20, 7, 92);
                      }

                      .planDetailDrawerClass .dataTable .tableHeader {
                          font-size: 18px;
                          color: white;
                          border-top: 0.1px solid gray;
                          border-left: 0.1px solid gray;
                          height: 26px;
                      }

                      .planDetailDrawerClass .dataTable .tableItem {
                          font-size: 15px;
                          color: #FF9E28;
                          border-top: 0.1px solid gray;
                          border-left: 0.1px solid gray;
                          height: 26px;
                      }

                      .planDetailDrawerClass .dataTable .tableLast {
                          border-right: 0.1px solid gray;
                      }

                      .planDetailDrawerClass .dataTable .lastRow {
                          border-bottom: 0.1px solid gray;
                      }

                      .planDetailDrawerClass .ant-input-number{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 13px;
                        height: 30px;
                      }

                      .planDetailDrawerClass .ant-input-group-addon{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                      }

                      .planDetailDrawerClass .ant-page-header-heading-title{
                        color: white;
                        font-size: 15px;
                      }

                      .planDetailDrawerClass .ant-page-header{
                        padding: 5px;
                      }

                      .planDetailDrawerClass .anticon{
                        color: white;
                      }

                      .planDetailDrawerClass .ant-divider{
                        margin-top: 0px;
                      }

                      .planDetailDrawerClass .ant-divider-plain.ant-divider-with-text{
                        color: white;
                      }

                      .planDetailDrawerClass .ant-col {
                        height: 48px;
                      }

                      .planDetailDrawerClass .ui.form input[type="text"] {
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                      }

                      .planDetailDrawerClass .ui.input {
                        width: 100%
                      }

                      .planDetailDrawerClass i.icon {
                        color: white;
                      }

                      .planDetailDrawerClass .ant-select-selector{
                        background-color: #2d3436 !important;
                        border: 1px solid #594e4a !important;
                        color: white !important;
                      }

                      .planDetailDrawerClass .companyScoreSelect .ant-select-selector{
                        background-color: #2d3436 !important;
                        border: 0px solid #594e4a !important;
                        color: white !important;
                        height: 28px;
                      }

                      .planDetailDrawerClass .companyConclusionSelect .ant-select-selector{
                        background-color: #2d3436 !important;
                        border: 0px solid #594e4a !important;
                        color: white !important;
                        height: 28px;
                        width: 120px;
                      }
                      .planDetailDrawerClass .derivativeCalss {
                        margin-left: 5px;
                        color: #FF9E28;
                        font-size: 12px;
                      }
                      .planDetailDrawerClass .ant-select-arrow{
                        color: gray !important;
                      }

                      .planDetailDrawerClass .ant-input-number{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 12px;
                        width: 100%;
                      }

                      .lastVersionDataClass{
                        color: green;
                        margin-left: 10px;
                      }

                      .planDetailDrawerClass .ant-collapse-header{
                        border: 0px !important;
                        background-color: #2d3436;
                        color: white !important;
                      }

                      .planDetailDrawerClass .ant-collapse-header .ant-collapse-arrow{
                        color: white !important;
                      }

                      .planDetailDrawerClass .ant-collapse-content-box{
                        border: 0px !important;
                        background-color: #2d3436;
                        color: white !important;
                        padding: 0px;
                      }

                      .planDetailDrawerClass .ant-collapse {
                        background-color: #2d3436;
                        border: 0px solid #d9d9d9 !important;
                        border-bottom: 0;
                        border-radius: 2px;
                      }

                      .planDetailDrawerClass .ant-collapse-item {
                        border-bottom: 0px solid #d9d9d9 !important;
                      }

                      .planDetailDrawerClass .ant-collapse-content {
                        overflow: hidden;
                        border-top: 0px solid #d9d9d9 !important;
                        background-color: #2d3436;
                      }
                    `}
          </style>
          <Drawer
              width={'100%'}
              title={title}
              placement="right"
              maskClosable={readOnly}
              onClose={this._onDrawClose}
              closable={false}
              visible={true}
              className="planDetailDrawerClass"
          >
            {this._createCompanyDetailForm()}
            <Collapse defaultActiveKey={['1', '2']}>
              <Panel header="Attach List" key="1">
                {this._createAttachList()}
              </Panel>
              <Panel header="Review" key="2">
                {this._createCompanyReview()}
              </Panel>
            </Collapse>
            {this._createNotesDraw()}
            {this._createChatModal()}
          </Drawer>
        </>
    );
  }
}

export default CompanyScoreDetailDrawer;
