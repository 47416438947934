import {
  fiveDPFormatter,
  moneyFormatter,
  nullFormatter,
  objectFormatter,
  booleanFormatter,
  sizeBooleanFormatter
} from 'common/utils/valueFormatters';
import { sideStyle } from 'common/utils/styleChooser';
import EntityMap from 'entities/EntityMap';

const routeErrorGridColumns = [
  {
    field: 'msg',
    headerName: 'Error Msg',
    enableRowGroup: true,
    cellClass: 'comment',
    width: 450
  },
  {
    field: 'createdOn',
    headerName: 'Created On',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 150
  },
  {
    field: 'status',
    headerName: 'Status',
    enableRowGroup: true,
    cellClass: 'keyword',
    width: 100
  }
];

const routeGridColumns = [
  {
    field: 'refId',
    headerName: 'Ref Id',
    cellClass: 'keyword'
  },
  {
    field: 'externalId',
    headerName: 'EXT Id',
    cellClass: 'keyword'
  },
  {
    field: 'orderRefId',
    headerName: 'Order Ref Id',
    cellClass: 'keyword'
  },
  {
    field: 'fundCode',
    headerName: 'Fund',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter
  },
  {
    field: 'bookCode',
    headerName: 'Book',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword',
    lockPinned: true
  },
  {
    field: 'tradeDate',
    headerName: 'Trade Date',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'lockDate',
    headerName: 'Lock Date',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'side',
    headerName: 'Side',
    cellClass: params =>
      ['BUY', 'COVR', 'COVR_T'].includes(params.value) ? 'long' : 'short'
  },
  {
    field: 'qty',
    headerName: 'Qty',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'filled',
    headerName: 'Filled',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'filledPct',
    headerName: 'Filled %',
    cellRenderer: 'percentBarComponent',
    cellClass: 'number'
  },
  {
    field: 'avgPrice',
    headerName: 'Avg Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: fiveDPFormatter
  },
  {
    field: 'netPrice',
    headerName: 'Net Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: fiveDPFormatter,
    hide: true
  },
  {
    field: 'fxRate',
    headerName: 'Fx Rate',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: fiveDPFormatter
  },
  {
    field: 'orderType',
    headerName: 'OrderType',
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        MKT: '#FF9E28',
        LMT: 'steelblue'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'limitPrice',
    headerName: 'Lmt Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: fiveDPFormatter
  },
  {
    field: 'cfdType',
    headerName: 'CfdType',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'handlingInstruction',
    headerName: 'Handl Instr',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'custodian',
    headerName: 'Custodian',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'broker',
    headerName: 'Broker',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'account',
    headerName: 'Account',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'accountType',
    headerName: 'Acct Type',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'settlementCcy',
    headerName: 'Setl Ccy',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'status',
    headerName: 'Status',
    enableRowGroup: true,
    cellClass: 'keyword'
  },
  {
    field: 'source',
    headerName: 'Source',
    enableRowGroup: true,
    cellClass: 'keyword'
  },
  {
    field: 'venue',
    headerName: 'Venue',
    enableRowGroup: true,
    cellClass: 'keyword'
  },
  {
    field: 'instruction',
    headerName: 'Instruction',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'borrowRate',
    headerName: 'Borrow Rate',
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'loanType',
    headerName: 'PTH/C',
    enableRowGroup: false,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'algoCode',
    headerName: 'Algo',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'algoParams',
    headerName: 'Algo Params',
    enableRowGroup: false,
    cellClass: 'comment',
    valueFormatter: objectFormatter
  },
  {
    field: 'isDirectLine',
    headerName: 'Direct Line',
    enableRowGroup: false,
    cellClass: 'keyword',
    valueFormatter: booleanFormatter
  },
  {
    field: 'errors',
    headerName: 'Errors',
    valueFormatter: sizeBooleanFormatter,
    cellRenderer: 'tableTooltipComponent',
    cellRendererParams: {
      tooltipTitle: 'Error Details',
      tooltipField: 'errors',
      tooltipColumnDefs: routeErrorGridColumns,
      tooltipWidth: '750px',
      tooltipHeight: '390px'
    },
    cellClass: 'keyword'
  },
  {
    field: 'notes',
    headerName: 'Reason',
    enableRowGroup: true,
    cellClass: 'comment',
    valueFormatter: nullFormatter
  },
  {
    field: 'createdOn',
    headerName: 'Created On',
    cellClass: 'non-number',
    valueFormatter: nullFormatter
  },
  {
    field: 'lastModifiedOn',
    headerName: 'Last MOD Time',
    cellClass: 'non-number',
    valueFormatter: nullFormatter
  },
  {
    field: 'algoExecCxlCount',
    headerName: 'Algo Exec Cxl Count',
    cellClass: 'number'
  },
  {
    field: 'qtyUsd',
    headerName: 'Value $',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'subSource',
    headerName: 'Sub Source',
    enableRowGroup: true,
    cellClass: 'comment',
    valueFormatter: nullFormatter
  },
  {
    field: 'netMoney',
    headerName: 'Net Money',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter
  },
  {
    field: 'contractId',
    headerName: 'Notes1',
    cellClass: 'non-number'
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
  }
];

export const routeGridColumnMap = EntityMap.fromArray(
  routeGridColumns,
  'field'
);
