import React, { Component } from 'react';
import { Modal, Button, Spin, Badge, Alert, Tabs, Switch } from 'antd';
import { Message } from 'semantic-ui-react';
import {
  DIALOG_EXECUTE_ROUTES,
  DIALOG_EXECUTE_ROUTES_MODE
} from '../../omsConstants';
import { AgGridReact } from 'ag-grid-react';
import StateSynchronizer from '../../../../common/utils/StateSynchronizer';
import agGridUtils from '../../../../common/ui/agGridUtils';
import {
  executeRouteHoldingGridColumns,
  orderChangesGridColumns
} from './GridColumnMap';
import { locateHoldingGridColumns } from './GridColumnMap';
import client from '../../api/client';
import _ from 'lodash';
import Route from '../../entities/Route';
import hotTableUtils from 'common/ui/hotTableUtils';
import { HotTable } from '@handsontable/react';
import RouteCalculator from 'features/oms/entities/calculators/RouteCalculator';
import RouteValidator, {
  HARD_RULE,
  SOFT_RULE
} from 'features/oms/entities/validators/RouteValidator';
import { routeGridColumns } from './GridColumnMap';
import SplitPane from 'react-split-pane';
import AlgoParamsDialog from './AlgoParamsDialog';
import AlgoParamsPairDialog from './AlgoParamsPairDialog';
import { moneyFormatter, percentFormatter } from 'common/utils/valueFormatters';
import hyperid from 'hyperid';
import TickerUtils from '../../../../common/utils/TickerUtils';
import RouteLocateAllocateDialog from './RouteLocateAllocateDialog';

const uniqidInstance = hyperid();

const holdingSummaryFieldMap = new Map([
  ['Qty Start', e => (e.primaryTickers.size > 1 ? null : 'quantityStart')],
  [
    'Theoretical Qty',
    e => (e.primaryTickers.size > 1 ? null : 'theoreticalQty')
  ],
  [
    'Theoretical Qty For Close',
    e => (e.primaryTickers.size > 1 ? null : 'theoreticalQtyForClose')
  ]
  // ['MV Start', _ => 'deltaNotnlMktValUsdStart'],
  // [
  //   'MV Start Book %',
  //   e => (e.bookCodes.size > 1 ? null : 'deltaNotnlMktValUsdStartBookPct')
  // ],
  // [
  //   'MV Start Fund %',
  //   e => (e.fundCodes.size > 1 ? null : 'deltaNotnlMktValUsdStartFundPct')
  // ]
  // ['Qty', e => (e.primaryTickers.size > 1 ? null : 'quantityEnd')],
  // ['MV', _ => 'deltaNotnlMktValUsd'],
  // [
  //   'MV Book %',
  //   e => (e.bookCodes.size > 1 ? null : 'deltaNotnlMktValUsdBookPct')
  // ],
  // [
  //   'MV Fund %',
  //   e => (e.fundCodes.size > 1 ? null : 'deltaNotnlMktValUsdFundPct')
  // ]
]);

class ExecuteRoutesDialog extends Component {
  venueSvcInfos = [];
  holdingsByTicker = {};
  pbHoldingsByTicker = {};
  extraHoldingsByTicker = {};
  options = {
    brokerMap: {},
    custodianMap: {}
  };

  state = {
    isInitialized: false,
    holdings: [],
    pthHoldings: [],
    pbHoldings: [],
    routes: [],
    selectedRoute: null,
    selectedRoutes: [],

    submitStatus: 'READY',
    calculating: false,
    showAlgoParamsDialog: false,
    showPairAlgoParamsDialog: false,
    showRouteLocateAllocateDialog: false,

    // summaryData: null,

    holdingsGridSettings: agGridUtils.createSettings({
      columnDefs: executeRouteHoldingGridColumns,
      rowGroupPanelShow: 'onlyWhenGrouping',
      groupIncludeTotalFooter: false
    }),
    pbHoldingsGridSettings: agGridUtils.createSettings({
      columnDefs: executeRouteHoldingGridColumns.filter(
        r =>
          ![
            'bookCode',
            'strategy',
            'custodianAccountCode',
            'securityCode',
            'currencyCode',
            'positionTypeCode',
            'loanQtyStart',
            'loanQtyEnd',
            'ctQtyStart',
            'ctQtyEnd',
            'availLoanTxns',
            'availLoanTxnsStr'
          ].includes(r.field)
      ),
      rowGroupPanelShow: 'onlyWhenGrouping',
      groupIncludeTotalFooter: false
    }),
    holdingsGridWrapperStyle: {
      width: '100%',
      height: '390px'
    },
    orderChangesGridSettings: agGridUtils.createSettings({
      columnDefs: orderChangesGridColumns,
      deltaRowDataMode: false,
      getRowNodeId: null,
      rowGroupPanelShow: 'onlyWhenGrouping',
      groupIncludeTotalFooter: false
    }),
    locateHoldingsGridSettings: agGridUtils.createSettings({
      columnDefs: locateHoldingGridColumns,
      deltaRowDataMode: false,
      getRowNodeId: null,
      rowGroupPanelShow: 'onlyWhenGrouping',
      groupIncludeTotalFooter: false
    }),
    routesGridSettings: null,
    routesGridWrapperStyle: {
      width: '100%',
      height: '390px',
      marginTop: '5px',
      border: 'solid 2px grey',
      padding: '2px'
    },
    useOmsSrv: this.props.ui.useOmsSrv
  };

  constructor(props) {
    super(props);

    this.hotTblRef = React.createRef();
  }

  componentDidMount() {
    this._init();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { routes, selectedRoutes } = this.state;
    if (prevState.routes !== routes) {
      const keys = (selectedRoutes || []).map(r => r.key);
      const newSelectedRoutes = routes.filter(r => keys.includes(r.key));
      this._setSelectedRoutes(newSelectedRoutes);
    }
  }

  _toggleMMA = () => {
    const { routes, selectedRoutes } = this.state;
    const validRoutes = selectedRoutes.filter(r =>
      ['BUY', 'SHRT'].includes(r.side)
    );
    const rows = validRoutes.map(r => routes.findIndex(rr => rr.key === r.key));
    const changes = _.zip(validRoutes, rows).map(([route, row]) => [
      row,
      'ticker',
      route.ticker,
      TickerUtils.toggleMMATicker(route.ticker, route.mmaTicker)
    ]);
    this._handleChanges(changes);
  };

  _cloneRoute = () => {
    const { routes, selectedRoute } = this.state;
    const key = `${selectedRoute.key}_${uniqidInstance()}`;

    const { leftQuantity = 0 } = selectedRoute.order || {};
    const aggRoutesQty = _.sumBy(
      routes.filter(r => r.orderRefId === selectedRoute.orderRefId),
      r => r.qty
    );

    const qty = Math.max(leftQuantity - (aggRoutesQty || 0), 0);

    const clonedRoute = {
      ...selectedRoute,
      key,
      qty
    };

    const row = routes.findIndex(r => r.key === selectedRoute.key);
    const updatedRoutes = this._validateRoutes([
      ...routes.slice(0, row + 1),
      clonedRoute,
      ...routes.slice(row + 1)
    ]);

    this.setState({ routes: updatedRoutes });
  };

  _createRoutesGridSettings = (brokerCodes, custodianCodes, reasonCodes) => {
    const optionsMapping = {
      broker: brokerCodes,
      custodian: custodianCodes,
      notes: ['', ...reasonCodes]
    };
    const columns = routeGridColumns.map(c => {
      const values = optionsMapping[c.data];
      return values
        ? {
            ...c,
            source: values
          }
        : c;
    });
    return hotTableUtils.createSettings({
      columns,
      rowHeaders: true,
      contextMenu: {
        items: {
          remove_row: {},
          algo_params: {
            name: 'Update algo params',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this.setState({ showAlgoParamsDialog: true }), 100);
            }
          },
          pair_algo_params: {
            name: 'Update pair algo params',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              this._onPairAlgoParamsClick();
            }
          },
          clone: {
            name: 'Clone row',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._cloneRoute(), 100);
            }
          },
          useMMA: {
            name: 'Toggle MMA',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._toggleMMA(), 100);
            }
          }
        }
      },
      columnSorting: {
        indicator: false,
        headerAction: true
      }
    });
  };

  _createRoutes = (order, routeInfos, i, ctx) => {
    const {
      info: { mode }
    } = this.props;
    if (mode === DIALOG_EXECUTE_ROUTES_MODE.NEW) {
      return routeInfos.map((r, j) => {
        return {
          ...Route.emptyForm(order, r, ctx),
          subSource: r.msg,
          filled: r.avgPrice ? r.qty : null,
          key: `${i}-${j}`
        };
      });
    } else if (mode === DIALOG_EXECUTE_ROUTES_MODE.UPDATE) {
      const { selectedRoutes } = this.props;
      return selectedRoutes
        .filter(r => r.orderRefId === order.refId)
        .map(r => ({
          ...Route.toForm(r, order, ctx),
          limitPrice: order.limitPriceLocal,
          orderType: order.orderType
        }));
    } else if (mode === DIALOG_EXECUTE_ROUTES_MODE.EXECUTE) {
      const { routes: existingRoutes } = this.props;
      return existingRoutes
        .filter(
          r =>
            r.orderRefId === order.refId &&
            r.status === 'PENDING' &&
            r.venue !== 'MANUAL'
        )
        .map(r => Route.toForm(r, order, ctx));
    }

    return [];
  };

  _init = () => {
    const {
      selectedOrders,
      selectedRoutes,
      info: { mode }
    } = this.props;
    if (_.isEmpty(selectedOrders)) return;

    const infos = selectedOrders.map(o => ({
      orderRefId: o.refId,
      ticker: o.ticker,
      side: o.side,
      pmReason: o.pmReason,
      bookCode: o.bookCode,
      excludedRouteRefIds:
        mode === DIALOG_EXECUTE_ROUTES_MODE.UPDATE
          ? selectedRoutes
              .filter(r => r.orderRefId === o.refId)
              .map(r => r.refId)
          : [],
      useOmsSrv: this.state.useOmsSrv
    }));

    Promise.all([
      client.getExecutionOptions(),
      client.calcExecutionContexts(infos)
    ])
      .then(
        ([
          { custodians, brokers, algoMapping, venueSvcInfos, reasons },
          { ctxs, holdingsByTicker, extraHoldingsByTicker, pbHldsByTicker }
        ]) => {
          const brokerMap = _.keyBy(brokers, b => b.brokerCode);
          const custodianMap = _.keyBy(custodians, c => c.custodianCode);

          // Init some datas into this.
          this.venueSvcInfos = venueSvcInfos;
          this.holdingsByTicker = holdingsByTicker;
          this.extraHoldingsByTicker = extraHoldingsByTicker;
          this.pbHoldingsByTicker = pbHldsByTicker || {};
          this.options = {
            custodianMap,
            brokerMap
          };

          const routes = this._validateRoutes(
            _.zip(selectedOrders, ctxs).flatMap(
              ([order, { orderInfo, routeInfos }], i) => {
                return this._createRoutes(
                  { ...order, ...orderInfo },
                  routeInfos,
                  i,
                  { brokerMap, algoMapping }
                );
              }
            )
          );

          const routesGridSettings = this._createRoutesGridSettings(
            _.orderBy(brokers.map(b => b.brokerCode), [0], ['asc']),
            custodians.map(c => c.custodianCode),
            reasons.map(r => r.reasonCode)
          );

          _.delay(() => {
            this.setState({
              isInitialized: true,
              routes,
              routesGridSettings
            });
          }, 150);
        }
      )
      .catch(ex => {
        console.log(ex);
        this.setState({
          isInitialized: true,
          routes: [],
          submitStatus: 'ERROR'
        });
      });
  };

  _validateRoutes = routes => {
    const {
      venueSvcInfos,
      options: { brokerMap, custodianMap },
      holdingsByTicker,
      extraHoldingsByTicker
    } = this;

    const {
      settings: { user }
    } = this.props;

    return routes.map(route => {
      const {
        order: { ticker }
      } = route;
      const holdings = holdingsByTicker[ticker] || [];
      const extraHoldings = extraHoldingsByTicker[ticker] || [];

      const validateConfig = {
        errors: { ruleType: HARD_RULE, user },
        warnings: {
          ruleType: SOFT_RULE,
          venueSvcInfos,
          brokerMap,
          custodianMap,
          user,
          holdings,
          extraHoldings,
          routes
        }
      };

      return Object.entries(validateConfig).reduce((prev, [k, ctx]) => {
        const validationResult = RouteValidator.validate(route, ctx);
        const hasValidationResultKey = _.camelCase(`has_${k}`);
        return {
          ...prev,
          [k]: validationResult,
          [hasValidationResultKey]: !_.isEmpty(validationResult)
        };
      }, route);
    });
  };

  _calcExecutionInfos = (routes, changes, holdingsByRouteKey = {}) => {
    // const { holdingsByTicker } = this.state;
    const inputs = changes
      .map(c => {
        const [row, field] = c;
        // if (oldValue === newValue) return null;

        const { overrideFields, resultFields } = RouteCalculator.calcExecFields(
          field
        );
        if (_.isEmpty(overrideFields)) return null;
        if (_.isEmpty(resultFields)) return null;

        const r = routes[row];
        const { mmaTicker, ticker: orderTicker, securityCcy, refId } = r.order;

        return {
          key: r.key,
          orderRefId: refId,
          fundBook: `${r.fundCode}-${r.bookCode}`,
          side: r.side,
          info: {
            fund: r.fundCode,
            book: r.bookCode,
            side: r.side,
            ticker: resultFields.includes('ticker') ? orderTicker : r.ticker,
            mmaTicker,
            securityCcy,
            ..._.pick(r, overrideFields),
            accountType: _.get(holdingsByRouteKey[r.key], 'accountType')
          },
          resultFields
        };
      })
      .filter(Boolean);

    if (_.isEmpty(inputs)) return;

    const filteredInputs = Object.values(_.groupBy(inputs, i => i.key)).map(
      ig => _.orderBy(ig, [i => i.resultFields.length])[0]
    );
    // const tickers = _.uniq(
    //   filteredInputs.map(i => TickerUtils.replaceC1C2Ticker(i.info.ticker))
    // );
    // const holdings = _.flatMap(tickers, t => holdingsByTicker[t] || []);
    const svcInputs = filteredInputs.map(i => ({
      info: i.info,
      orderRefId: i.orderRefId,
      fundBook: i.fundBook,
      side: i.side,
      reCalcTicker: i.resultFields.includes('ticker'),
      useOmsSrv: this.state.useOmsSrv
    }));

    this.setState({ isCalculating: true });
    client
      .calcExecutionInfos(svcInputs)
      .then(results => {
        const irMap = _.keyBy(
          _.zip(filteredInputs, results).map(([input, result]) => ({
            input,
            result
          })),
          o => o.input.key
        );
        this.setState(prevState => {
          const updatedRoutes = this._validateRoutes(
            prevState.routes.map(r => {
              if (!(r.key in irMap)) return r;
              const { input, result } = irMap[r.key];
              return input.resultFields.reduce((prev, field) => {
                return this._calcRoute(prev, field, _.get(result, field, null));
              }, r);
            })
          );

          return {
            routes: updatedRoutes,
            isCalculating: false
          };
        });
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ isCalculating: false });
      });
  };

  _calcRoute = (route, name, value) => {
    const {
      options: { brokerMap },
      extraHoldingsByTicker,
      holdingsByTicker
    } = this;
    const ctx = { brokerMap, extraHoldingsByTicker, holdingsByTicker };

    const updatedValue =
      ['cfdType', 'loanType', 'notes'].includes(name) && value === ''
        ? null
        : value;
    const result = RouteCalculator.calcByField(
      {
        ...route,
        [name]: updatedValue,
        valueChanges: [name, route[name], updatedValue]
      },
      name,
      ctx
    );
    return {
      ...route,
      [name]: updatedValue,
      ...result
    };
  };

  _handleChanges = (changes, holdingsByRouteKey = {}) => {
    const { routes } = this.state;

    const updatedRoutes = this._validateRoutes(
      changes.reduce((prev, c) => {
        const [row, field, oldValue, newValue] = c;
        if (oldValue === newValue) return prev;

        return prev.map((r, i) => {
          if (i === row) {
            return this._calcRoute(r, field, newValue);
          }
          return r;
        });
      }, routes)
    );

    this.setState({
      routes: updatedRoutes
    });

    // Calc execution infos.
    this._calcExecutionInfos(updatedRoutes, changes, holdingsByRouteKey);
  };

  _beforeCellChange = (changes, source) => {
    // console.log(source, changes);
    // console.log("HotTableRef_beforeCellChange", this.hotTblRef.current);

    const realChanges = changes.filter(
      ([, , oldValue, newValue]) => oldValue !== newValue
    );
    if (!_.isEmpty(realChanges)) this._handleChanges(realChanges);

    return false;
  };

  _beforeAutoFill = (start, end, data) => {
    if (!this.hotTblRef.current) return true;
    if (_.isEmpty(data)) return true;

    const hotTbl = this.hotTblRef.current.hotInstance;
    const rows = _.range(start.row, end.row + 1);
    const fields = _.range(start.col, end.col + 1).map(c =>
      hotTbl.colToProp(c)
    );

    if (['algoParams'].find(f => fields.includes(f))) {
      const changes = _.flatMap(
        rows.map(r => _.zip(fields, data[0]).map(([f, v]) => [r, f, null, v]))
      );
      this._handleChanges(changes);
      // const updatedRoutes = routes.map((r, i) => {
      //   if (i >= start.row && i <= end.row) {
      //     const changes = _.zipObject(fields, data[0]);
      //     return {
      //       ...r,
      //       ...changes
      //     };
      //   }

      //   return r;
      // });

      // this.setState({ routes: updatedRoutes });
      return false;
    }

    return true;
  };

  _afterLoadRoutes = initialLoad => {
    // console.log("HotTableRef_afterLoadRoutes", initialLoad, this.hotTblRef.current);
    if (!this.hotTblRef.current) return;

    const { routes, selectedRoute } = this.state;
    if (selectedRoute) return;
    if (_.isEmpty(routes)) return;

    const hotTbl = this.hotTblRef.current.hotInstance || {};
    hotTbl.selectCell(0, 'broker');

    // const {
    //   options: { brokerMap }
    // } = this.state;
    // const hotTbl = this.hotTblRef.current.hotInstance;
    // const routes = hotTbl.getSourceData() || [];
    // routes.forEach((r, row) => {
    //   const readOnly = Route.isDirectLineReadOnly(r, brokerMap);
    //   hotTbl.setCellMeta(
    //     row,
    //     hotTbl.propToCol('isDirectLine'),
    //     'readOnly',
    //     readOnly
    //   );
    // });
  };

  _onDoubleClicked = evt => {
    if (!evt.data) return;
    const h = evt.data;

    const { routes, selectedRoute } = this.state;
    const routeKey = selectedRoute.key;
    const row = routes.findIndex(r => r.key === routeKey);
    const changes = RouteCalculator.computeChangesFromHolding(
      selectedRoute,
      h,
      row
    );

    this._handleChanges(changes, { [routeKey]: h });
  };

  _beforeRemoveRow = (row, count, rows, source) => {
    let { routes } = this.state;
    routes = this._validateRoutes(routes.filter((_, i) => !rows.includes(i)));

    this.setState({ routes });

    return false;
  };

  _beforeColumnSort = (currSortConfigs, destSortConfigs) => {
    const columnSortPlugin = (
      this.hotTblRef.current.hotInstance || {}
    ).getPlugin('columnSorting');
    columnSortPlugin.setSortConfig(destSortConfigs);

    if (_.isEmpty(destSortConfigs)) return false;
    const firstSortConfig = _.first(destSortConfigs);
    if (!firstSortConfig) return false;

    const { routes } = this.state;
    const { column, sortOrder } = firstSortConfig;
    const field = (routeGridColumns[column] || {}).data;
    const sortedRoutes = _.orderBy(routes, [field], [sortOrder]);

    this.setState({ routes: sortedRoutes });

    return false;
  };

  _onRouteSelectionChanged = (row, col, row2, col2) => {
    const { routes } = this.state;
    // const route = routes[row];
    // if (route === selectedRoute) return;

    const selectedRoutes = _.slice(routes, row, row2 + 1);

    this._setSelectedRoutes(selectedRoutes);
  };

  _setSelectedRoutes = selectedRoutes => {
    const {
      holdingsByTicker,
      extraHoldingsByTicker,
      pbHoldingsByTicker
    } = this;

    if (_.isEmpty(selectedRoutes)) {
      this.setState({
        selectedRoute: null,
        selectedRoutes: [],
        holdings: [],
        pthHoldings: [],
        pbHoldings: []
      });
      return;
    }

    const route = _.first(selectedRoutes);
    const {
      fundCode,
      bookCode,
      custodian,
      order: { ticker }
    } = route;

    // Sort the holdings and if has matched fund/book, would be the first one.
    const _sortInteratee = h => {
      if (h.fundCode === fundCode && h.bookCode === bookCode) {
        return 1;
      } else if (h.bookCode === bookCode) {
        return 2;
      } else if (h.fundCode === fundCode) {
        return 3;
      } else {
        return 4;
      }
    };

    // Sort the holdings and if has matched fund/book, would be the first one.
    const _sortExtraInteratee = h => {
      if (
        h.fundCode === fundCode &&
        h.bookCode === bookCode &&
        h.custodianCode === custodian
      ) {
        return 1;
      } else if (h.fundCode === fundCode && h.bookCode === bookCode) {
        return 2;
      } else if (h.bookCode === bookCode) {
        return 3;
      } else if (h.fundCode === fundCode) {
        return 4;
      } else if (h.custodianCode === custodian) {
        return 5;
      } else {
        return 6;
      }
    };

    const holdings = _.sortBy(holdingsByTicker[ticker] || [], [_sortInteratee]);
    let pthHoldings = _.sortBy(extraHoldingsByTicker[ticker] || [], [
      _sortExtraInteratee
    ]);
    const pbHoldings = pbHoldingsByTicker[ticker] || [];
    if (pthHoldings) {
      pthHoldings = pthHoldings.filter(r => r.positionTypeCode === 'PTH');
      pthHoldings = pthHoldings.map((r, index) => {
        return { key: index, ...r };
      });
    }

    this.setState({
      selectedRoute: route,
      selectedRoutes,
      holdings,
      pthHoldings,
      pbHoldings
    });
  };

  _createRoutesGrid = () => {
    const { routesGridSettings, routesGridWrapperStyle, routes } = this.state;

    const {
      options: { brokerMap }
    } = this;

    const cells = (row, col, field) => {
      if (field === 'isDirectLine') {
        // console.log("HotTableRef_cells", this.hotTblRef.current);
        const r = routes[row] || {};

        const readOnly = Route.isDirectLineReadOnly(r, brokerMap);
        return { readOnly };
      }

      return {};
    };

    return (
      !_.isEmpty(routes) && (
        <div style={routesGridWrapperStyle}>
          <HotTable
            ref={this.hotTblRef}
            data={routes}
            beforeChange={this._beforeCellChange}
            afterLoadData={this._afterLoadRoutes}
            beforeRemoveRow={this._beforeRemoveRow}
            afterSelectionEnd={this._onRouteSelectionChanged}
            beforeColumnSort={this._beforeColumnSort}
            beforeAutofill={this._beforeAutoFill}
            manualColumnResize={true}
            cells={cells}
            {...routesGridSettings}
          ></HotTable>
        </div>
      )
    );
  };

  _onHoldingsGridReady = params => {
    this.holdingsGridApi = params.api;

    const COLUMNS_KEY = 'routes-holding-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.holdingsGridSettings.columnDefs,
      COLUMNS_KEY
    );
  };

  _onPTHHoldingsGridReady = params => {
    this.pthHoldingsGridApi = params.api;

    const COLUMNS_KEY = 'routes-pthholding-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.holdingsGridSettings.columnDefs,
      COLUMNS_KEY
    );
  };

  _onPBHoldingsGridReady = params => {
    this.pbHoldingsGridApi = params.api;

    const COLUMNS_KEY = 'routes-pbholding-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.holdingsGridSettings.columnDefs,
      COLUMNS_KEY
    );
  };

  _createHoldingsGrid = () => {
    const {
      holdingsGridSettings,
      holdingsGridWrapperStyle,
      holdings
    } = this.state;

    return (
      <div
        style={holdingsGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          rowData={holdings}
          {...holdingsGridSettings}
          // events
          onGridReady={this._onHoldingsGridReady}
          onRowDoubleClicked={this._onDoubleClicked}
          onSelectionChanged={this.onHoldingSelectionChanged}
        />
      </div>
    );
  };

  _createPTHHoldingsGrid = () => {
    const {
      holdingsGridSettings,
      holdingsGridWrapperStyle,
      pthHoldings
    } = this.state;

    return (
      <div
        style={holdingsGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          rowData={pthHoldings}
          {...holdingsGridSettings}
          // events
          onGridReady={this._onPTHHoldingsGridReady}
        />
      </div>
    );
  };

  _createPBHoldingsGrid = () => {
    const {
      pbHoldingsGridSettings,
      holdingsGridWrapperStyle,
      pbHoldings
    } = this.state;

    return (
      <div
        style={holdingsGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          rowData={pbHoldings}
          {...pbHoldingsGridSettings}
          // events
          onGridReady={this._onPBHoldingsGridReady}
        />
      </div>
    );
  };

  _onOrderChangesGridReady = params => {
    const COLUMNS_KEY = 'routes-order-changes-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.orderChangesGridSettings.columnDefs,
      COLUMNS_KEY
    );
  };

  _onLocateHoldingsGridReady = params => {
    const COLUMNS_KEY = 'routes-order-locates-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.locateHoldingsGridSettings.columnDefs,
      COLUMNS_KEY
    );
  };

  _createLocateHoldingsGrid = () => {
    const {
      selectedRoute = {},
      locateHoldingsGridSettings,
      holdingsGridWrapperStyle
    } = this.state;

    const { fundCode, bookCode } = selectedRoute;
    const locateHoldings = (
      _.get(selectedRoute, 'order.locateHoldings') || []
    ).filter(lh => lh.fundCode === fundCode && lh.bookCode === bookCode);

    return (
      <div
        style={holdingsGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          rowData={locateHoldings}
          {...locateHoldingsGridSettings}
          // events
          onGridReady={this._onLocateHoldingsGridReady}
        />
      </div>
    );
  };

  _createOrderChangesGrid = () => {
    const {
      orderChangesGridSettings,
      holdingsGridWrapperStyle,
      selectedRoute = {}
    } = this.state;

    const orderChanges = _.get(selectedRoute, 'order.changes') || [];

    return (
      <div
        style={holdingsGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          rowData={orderChanges}
          {...orderChangesGridSettings}
          // events
          onGridReady={this._onOrderChangesGridReady}
        />
      </div>
    );
  };

  _createOrderTabs = () => {
    const { selectedRoute } = this.state;
    const locateHoldings = _.get(selectedRoute, 'order.locateHoldings') || [];
    const tabSettings = [
      {
        key: 'Locate Holdings',
        visible: !_.isEmpty(locateHoldings),
        contentFn: this._createLocateHoldingsGrid
      },
      {
        key: 'Changes',
        visible: true,
        contentFn: this._createOrderChangesGrid
      }
    ];

    return (
      <div>
        <Tabs tabPosition="top" key="RightTabs">
          {tabSettings
            .filter(s => s.visible)
            .map(s => (
              <Tabs.TabPane tab={s.key} key={s.key}>
                {s.contentFn()}
              </Tabs.TabPane>
            ))}
        </Tabs>
      </div>
    );
  };

  _onSubmit = () => {
    const { submitDialogSuccess, info } = this.props;
    const { routes } = this.state;

    if (_.isEmpty(routes)) return;

    const validatedRoutes = this._validateRoutes(routes);
    if (validatedRoutes.some(r => !_.isEmpty(r.errors))) {
      this.setState({
        routes: validatedRoutes
      });
      return;
    }

    let routeModels = routes.map(r => Route.toModel(r)).filter(r => r.qty > 0);

    this.setState({ submitStatus: 'SUBMITTING' });

    client
      .submitRoutes(routeModels)
      .then(results => {
        routeModels = _.zip(routeModels, results).map(([r, { refId }]) => ({
          ...r,
          refId
        }));

        this.setState({ submitStatus: 'READY' });
        submitDialogSuccess(DIALOG_EXECUTE_ROUTES, {
          routes: routeModels,
          ...info
        });
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ submitStatus: 'ERROR' });
      });
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus, routes = [] } = this.state;
    const submitBtn = {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];

    const count = routes.reduce(
      (prev, r) => prev + _.size(r.errors) + _.size(r.warnings),
      0
    );
    return (
      <Badge key="submitBadge" count={count} style={{ marginRight: '10px' }}>
        {submitBtn}
      </Badge>
    );
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_EXECUTE_ROUTES);
  };

  _createRoutesSummaryPanel = () => {
    const { routes, isCalculating } = this.state;
    if (!routes) return <></>;

    const errorCount = routes.filter(r => !_.isEmpty(r.errors)).length;
    const warningCount = routes.filter(r => !_.isEmpty(r.warnings)).length;

    return (
      <div className="summary">
        Totally
        <span className="keyword">{` ${routes.length} `}</span>
        routes,
        <span className="error-keyword">{` ${errorCount} `}</span>
        contain errors,
        <span className="warning-keyword">{` ${warningCount} `}</span>
        contain warnings.
        {isCalculating && <Spin size="small" />}
      </div>
    );
  };

  _createOrderSummaryPanel = () => {
    const { selectedRoute, routes = [] } = this.state;
    // const {
    //   info: { mode }
    // } = this.props;
    if (!selectedRoute) return <></>;

    const {
      fundCode,
      bookCode,
      ticker,
      cfdType,
      settlementCcy,
      side,
      qty,
      broker,
      order,
      orderRefId
    } = selectedRoute;
    const fullTicker = [ticker, cfdType, settlementCcy]
      .filter(Boolean)
      .join(' ');

    const {
      leftQuantity = 0,
      strategy = 'NULL STRATEGY',
      limitPriceLocal = 0
    } = order || {};
    const aggRoutesQty = routes
      .filter(r => r.orderRefId === orderRefId)
      .reduce((prev, r) => {
        return prev + _.toNumber(r.qty);
      }, 0);
    const targetLeftQty = leftQuantity - (aggRoutesQty || 0);
    const sideStyle = ['BUY', 'COVR', 'COVR_T'].includes(side)
      ? 'long'
      : 'short';

    return (
      <div className="summary">
        <span className="comment">{`${fundCode}-${bookCode}-${strategy} `}</span>
        <span className={sideStyle}>{`${side} `}</span>
        <span className="keyword">{`${fullTicker} `}</span>
        with qty
        <span className={sideStyle}>{` ${qty || 0} `}</span>
        and limit price
        <span className="keyword">{` ${limitPriceLocal || 0} `}</span>
        (Order left qty:{' '}
        <span
          className={sideStyle}
        >{` ${targetLeftQty}/${leftQuantity} `}</span>
        ) through
        <span className="comment">{` ${broker || 'NULL BROKER'}`}</span>
        {/* {mode === DIALOG_EXECUTE_ROUTES_MODE.NEW &&
        (<Button
          type='primary'
          style={{marginRight: '10px',marginBottom: '5px',float: 'right'}}
          onClick={() => this._toggleShowLocateAllocation(true)}>Calc Locates</Button>)} */}
      </div>
    );
  };

  onHoldingSelectionChanged = () => {
    const selectedHoldingKeys = this.holdingsGridApi
      .getSelectedRows()
      .map(r => r.key);
    const holdingSummary = this._calcHoldingSummary(selectedHoldingKeys);

    this.setState({ selectedHoldingKeys, holdingSummary });
  };

  _calcHoldingSummary = selectedHoldingKeys => {
    const { holdings } = this.state;

    if (!_.isEmpty(selectedHoldingKeys) && !_.isEmpty(holdings)) {
      return holdings
        .filter(h => selectedHoldingKeys.includes(h.key))
        .reduce((map, e) => {
          return {
            primaryTickers: new Set([
              ...(map.primaryTickers || []),
              e.primaryTicker
            ]),
            bookCodes: new Set([...(map.bookCodes || []), e.bookCode]),
            fundCodes: new Set([...(map.fundCodes || []), e.fundCode]),

            quantityStart: e.quantityStart + (map.quantityStart || 0),
            theoreticalQty: e.theoreticalQty + (map.theoreticalQty || 0),
            theoreticalQtyForClose:
              e.theoreticalQtyForClose + (map.theoreticalQtyForClose || 0)
            // deltaNotnlMktValUsdStart:
            //   e.deltaNotnlMktValUsdStart + (map.deltaNotnlMktValUsdStart || 0),
            // deltaNotnlMktValUsdStartBookPct:
            //   e.deltaNotnlMktValUsdStartBookPct +
            //   (map.deltaNotnlMktValUsdStartBookPct || 0),
            // deltaNotnlMktValUsdStartFundPct:
            //   e.deltaNotnlMktValUsdFundPct +
            //   (map.deltaNotnlMktValUsdFundPct || 0)
          };
        }, {});
    }

    return null;
  };

  _createHoldingSummaryPanel = () => {
    const { holdingSummary } = this.state;

    const summaryFieldString =
      holdingSummary &&
      [...holdingSummaryFieldMap.keys()]
        .map(k => ({ c: k, f: holdingSummaryFieldMap.get(k)(holdingSummary) }))
        .filter(cf => cf.f)
        .map(
          cf =>
            `[${cf.c}]: ${
              cf.c.includes('%')
                ? percentFormatter({ value: holdingSummary[cf.f] })
                : moneyFormatter({ value: holdingSummary[cf.f] })
            }`
        )
        .join(', ');

    const summaryString = `Holding Summary -> ${summaryFieldString}`;

    return (
      <div>
        {holdingSummary && (
          <div
            style={{ marginTop: '5px', textAlign: 'right' }}
            // onDoubleClick={onDoubleClick}
          >
            <Alert message={summaryString} type="info" />
          </div>
        )}
      </div>
    );
  };

  _createErrorsPanel = () => {
    const { selectedRoute } = this.state;
    const { errors = {}, warnings = {} } = selectedRoute || {};

    const errorMsgs = Object.values(errors);
    const warningMsgs = Object.values(warnings);

    return (
      <div style={{ marginTop: '5px' }}>
        {!_.isEmpty(errorMsgs) && (
          <Message error list={errorMsgs} style={{ marginBottom: '3px' }} />
        )}
        {!_.isEmpty(warningMsgs) && (
          <Message warning list={warningMsgs} style={{ marginTop: '3px' }} />
        )}
      </div>
    );
  };

  _onApplyAlgoParams = algoParams => {
    this._toggleShowAlgoParams(false);

    const { selectedRoute, routes } = this.state;
    const updatedRoute = this._calcRoute(
      selectedRoute,
      'algoParams',
      algoParams
    );
    const updatedRoutes = routes.map(r =>
      r.key === updatedRoute.key ? updatedRoute : r
    );

    this.setState({
      routes: updatedRoutes,
      selectedRoute: updatedRoute
    });
  };

  _toggleShowAlgoParams = show => {
    this.setState({ showAlgoParamsDialog: show });
  };

  _onPairAlgoParamsClick = () => {
    const { selectedRoutes } = this.state;
    if(_.isEmpty(selectedRoutes) || selectedRoutes.length !== 2) return;
    const routes = _.orderBy(selectedRoutes, ['refId'], ['asc'])
    const pairAlgoParams = {
      PX_CONV: 'CB_PREMIUM',
      FLIP: false,
      EXEC_STYLE: 'INLINE',
      'Target Vol %': 20,
      CHECK_SECONDS: 5,
      TARGET_SPREAD: 0,
      WT_1: 1,
      WT_2: 1,
      leg1: {
        side: routes[0].side,
        ticker: routes[0].ticker,
      },
      leg2: {
        side: routes[1].side,
        ticker: routes[1].ticker,
      },
    }
    _.delay(() => this.setState({ showPairAlgoParamsDialog: true, pairAlgoParams }), 100);
  }

  _onApplyPairAlgoParams = algoParams => {
    this._toggleShowPairAlgoParams(false);

    const { selectedRoutes, routes } = this.state;
    const basket = `${uniqidInstance()}-2`;
    const updatedSelectedRouteMap = _.keyBy(selectedRoutes.map(r => 
      this._calcRoute(
        {...r, basket, algoCode: 'PAIR'},
        'algoParams',
        algoParams
    )), 'key');
    const updatedRoutes = routes.map(r => updatedSelectedRouteMap[r.key]?updatedSelectedRouteMap[r.key]:r);

    this.setState({
      routes: updatedRoutes,
      selectedRoute: Object.values(updatedSelectedRouteMap)
    });
  };

  _toggleShowPairAlgoParams = show => {
    this.setState({ showPairAlgoParamsDialog: show });
  };

  _onApplyLocateAllocation = data => {
    this._toggleShowLocateAllocation(false);
    if (!data) return;

    const { routes } = this.state;
    routes.forEach(item => {
      const itemDetail = _.findLast(
        data,
        r => r.itemIndexNum === item.itemIndexNum
      );
      if (
        itemDetail &&
        itemDetail.allocateQty !== null &&
        itemDetail.allocateQty !== undefined
      ) {
        item.qty = itemDetail.allocateQty;
      }
    });
    this.setState({
      routes
    });
  };

  _toggleShowLocateAllocation = show => {
    const { routes } = this.state;
    routes.forEach((item, index) => (item['itemIndexNum'] = index));
    this.setState({ showRouteLocateAllocateDialog: show });
  };

  onSwitchChange = () => {
    this.setState(
      {
        useOmsSrv: !this.state.useOmsSrv,
        isInitialized: false
      },
      () => {
        _.delay(this._init, 100);
      }
    );
  };

  _createSwitchSrv = () => {
    const { useOmsSrv } = this.state;
    return (
      <div style={{ textAlign: 'right', float: 'right' }}>
        <Switch
          checked={useOmsSrv}
          checkedChildren="NEW"
          unCheckedChildren="OLD"
          onChange={this.onSwitchChange}
        ></Switch>
      </div>
    );
  };

  render() {
    const {
      isInitialized,
      showAlgoParamsDialog,
      showPairAlgoParamsDialog,
      selectedRoute,
      showRouteLocateAllocateDialog,
      pairAlgoParams
    } = this.state;
    const { algoMapping = {}, algoCode, algoParams = {} } = selectedRoute || {};
    const algoParamInfos = algoMapping[algoCode];

    const {
      info: { mode }
    } = this.props;
    const modalTitle = `${mode} Routes`;

    return (
      <Modal
        width={2000}
        maskClosable={false}
        title={modalTitle}
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._onSubmit),
          <Button
            key="close"
            type="primary"
            onClick={this.closeDialog}
            style={{ marginLeft: '10px' }}
          >
            Close
          </Button>
        ]}
      >
        <Spin tip="Initializing..." spinning={!isInitialized}>
          {/*{this._createSwitchSrv()}*/}
          {this._createRoutesSummaryPanel()}
          {this._createOrderSummaryPanel()}
          {this._createRoutesGrid()}

          <div style={{ width: '100%', height: '420px', marginTop: '5px' }}>
            <style>
              {`
            .ant-tabs-nav .ant-tabs-tab {
              color: black;
              font-weight: 700;
              font-style: italic;
              padding: 2px 10px;
            }

            .ant-tabs-nav .ant-tabs-tab-active {
              color: #1890ff;
              font-weight: 700;
              font-style: italic;
              padding: 2px 10px;
            }
          `}
            </style>
            <SplitPane
              split="vertical"
              defaultSize="65%"
              style={{ position: 'relative' }}
            >
              <Tabs tabPosition="top" key="LeftTabs">
                <Tabs.TabPane tab="Holdings" key="Holdings">
                  {
                    <div>
                      {this._createHoldingsGrid()}
                      {this._createHoldingSummaryPanel()}
                    </div>
                  }
                </Tabs.TabPane>
                <Tabs.TabPane tab="PB Holdings" key="pb">
                  {<div>{this._createPBHoldingsGrid()}</div>}
                </Tabs.TabPane>
                <Tabs.TabPane tab="PTH Holdings" key="pth">
                  {<div>{this._createPTHHoldingsGrid()}</div>}
                </Tabs.TabPane>
              </Tabs>
              {this._createOrderTabs()}
            </SplitPane>
          </div>

          {this._createErrorsPanel()}

          {showAlgoParamsDialog && (
            <AlgoParamsDialog
              centered={true}
              onSubmit={this._onApplyAlgoParams}
              onCancel={() => this._toggleShowAlgoParams(false)}
              algoParamInfos={algoParamInfos}
              algoParams={algoParams}
            />
          )}
          {
            showPairAlgoParamsDialog && (
              <AlgoParamsPairDialog 
                onSubmit={this._onApplyPairAlgoParams}
                closeDialog={() => this._toggleShowPairAlgoParams(false)}
                algoParams={pairAlgoParams}
              />
            )
          }
          {mode === DIALOG_EXECUTE_ROUTES_MODE.NEW &&
            showRouteLocateAllocateDialog && (
              <RouteLocateAllocateDialog
                centered={true}
                onSubmit={this._onApplyLocateAllocation}
                onCancel={() => this._toggleShowLocateAllocation(false)}
                routes={this.state.routes}
              />
            )}
        </Spin>
      </Modal>
    );
  }
}

export default ExecuteRoutesDialog;
