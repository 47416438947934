import moment from 'moment';
import config from 'common/config';

export const ROLE_ADVISOR = 'Advisor';
export const PAPER_TRADE_FUNDS = ['PTF', 'CPTF'];
export const ALPHA_CAPTURE_FUNDS = ['ACF', 'CACF'];
export const EXTERNAL_FUNDS = ALPHA_CAPTURE_FUNDS.concat(PAPER_TRADE_FUNDS);
const ADMIN_ROLES = ['Admin', 'Trader', 'RiskMngr'];
const PM_ROLES = ['PortMngr', 'Supervisor', 'FundMngr', 'Advisor'];
const QUANT_BOOKS = [
  'T34',
  'T34W',
  'T34A',
  'T12',
  'T45',
  'T22',
  'T51',
  'T26',
  'T78'
];
const MACRO_BOOKS = ['T83', 'T85', 'T91', 'T92', 'T98'];
const W_BOOKS = ['W01', 'W02', 'W03', 'W04', 'W05', 'W06'];
const SUPERVISOR_ROLES = ['Admin', 'Supervisor', 'RiskMngr'];
const ON_SHORE_FUNDS = ['CVF', 'SLHL', 'ZJNF', 'DCL', 'PLATCNY'];

export const isOnShoreFund = fund => ON_SHORE_FUNDS.includes(fund);

export const isInternalFund = fund => {
  return !EXTERNAL_FUNDS.includes(fund);
};

export const isQuantBook = book => {
  return (
    QUANT_BOOKS.includes(book) ||
    (book.charAt(0) === 'W' && !W_BOOKS.includes(book))
  );
};

export const isQuantStrategyBook = bookStrategy => {
  return bookStrategy && bookStrategy.toUpperCase() === 'QUANT';
};

export const isMacroBook = book => {
  return MACRO_BOOKS.includes(book);
};

export const isMacroStrategyBook = bookStrategy => {
  return bookStrategy && bookStrategy.toUpperCase() === 'MACRO';
};

export const isWBook = book => {
  return W_BOOKS.includes(book);
};

export const isPaperTradeFund = fund => {
  return PAPER_TRADE_FUNDS.includes(fund);
};

export const isAlphaCaptureFund = fund => {
  return ALPHA_CAPTURE_FUNDS.includes(fund);
};

export const isAdminRole = role => {
  return ADMIN_ROLES.includes(role);
};

export const canPlaceOrder = role => {
  // const roleList = ['Trader', 'RiskMngr']
  // if(config && !config.env.includes('prod')) {
  //   roleList.push('Admin');
  // }
  return ['Trader', 'RiskMngr'].includes(role) || PM_ROLES.includes(role);
};

export const isInternalUser = user => {
  return user && user.firm === 'PINPOINT';
};

export const disabledDate = (date, user = {}) => {
  if (isAdminRole(user.role)) return false;

  const minDate = moment().subtract(13, 'months');
  return date < minDate;
};

export const isSupervisorRole = role => {
  return SUPERVISOR_ROLES.includes(role);
};

export const isPmRole = role => {
  return PM_ROLES.includes(role);
};

export const roleTradeLabel = (user, isSingle) => {
  const { role, officeLocation } = user || {};
  return [ROLE_ADVISOR].includes(role) ||
    ['India', 'Japan'].includes(officeLocation)
    ? `Idea${isSingle ? '' : 's'}`
    : `Trade${isSingle ? '' : 's'}`;
};
